import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import LayoutsHomeAlt from "../Layout/HomeAlt";
import { useForm } from "react-hook-form";
import routes from "../../config/routes";
import AuthContext from "../../store/authContext";
import api from "../../config/api";

const HomeLogin = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData) => {
    setLoading(true);
    await api
      .post("login", formData)
      .then((response) => {
        if (response.data.status) {
          let rawRoles = [];
          if (response.data.data?.role.length != 0) {
            response.data.data?.role.forEach((role) => {
              role.permission.forEach((permission) => {
                rawRoles.push(permission.permission_code);
              });
            });
          }
          authCtx.login({ ...response.data.data, permissions: rawRoles });
          let oldEmail = localStorage.getItem("email");
          if (oldEmail != null) {
            localStorage.removeItem("email");
          }
          navigate(state?.path || routes.Dashboard);
        } else {
          setLoading(false);
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        authCtx.logout();
      });
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      navigate(state?.path || routes.Dashboard);
    }
  }, [authCtx]);

  return (
    <LayoutsHomeAlt>
      <section className="topSec">
        <div className="bannerSec">
          <div className="bannerCaption">
            <h1 className="bannertitle wow fadeInUp" data-wow-delay="0.2s">
              Become an iLusso Buyer. Get Paid.
            </h1>
            <p className="bannerDesc wow fadeInUp" data-wow-delay="0.6s">
              With hundreds of exotic cars bought and sold every month, iLusso
              is the leading authority in the exotic car market. Sign up today
              to become an authorized buyer for our exclusive brand.
            </p>
            <a
              href={routes.SignupThirdParty}
              title="Apply Now"
              target="_blank"
              className="d-inline-block d-md-none btn btn-red head-btn">
              Apply Now
            </a>
          </div>
        </div>
        <div className="vehicleInfo">
          <div className="formInfo">
            <div className="Innerform wow fadeInUp" data-wow-delay="0.4s">
              <h4 className="frmTitle">
                Log In to your iLusso <br /> Buyer’s Network Account
              </h4>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formcol">
                  <input
                    className={`inputformField ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    placeholder="Email"
                    {...register("email", {
                      required: true,
                      pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    })}
                  />
                  {errors.email?.type === "required" && (
                    <span className="formError">Email is required.</span>
                  )}
                  {errors.email?.type === "pattern" && (
                    <span className="formError">Email is invalid.</span>
                  )}
                </div>
                <div className="formcol mb-0">
                  <div className="position-relative">
                    <input
                      type={passwordShown ? "text" : "password"}
                      className={`inputformField ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      placeholder="Password"
                      {...register("password", { required: true })}
                    />
                    <i
                      className={`inputformPasswordToggle ${
                        passwordShown ? "show" : "hide"
                      }`}
                      onClick={togglePassword}></i>
                  </div>
                  {errors.password && (
                    <span className="formError">Password is required.</span>
                  )}
                </div>
                <div className="formcol">
                  <Link className="forgot_pass" to={routes.ForgotPassword}>
                    Forgot Password?
                  </Link>
                </div>
                <div className="formbtn">
                  <button
                    type="submit"
                    className="btn btn-red"
                    disabled={isLoading}>
                    Log In
                  </button>
                  {error != "" && <span className="formError">{error}</span>}
                </div>
              </form>

              <div className="form_account">
                <div className="accountInner">
                  Don’t have an account?{" "}
                  <a
                    href={routes.SignupThirdParty}
                    title="Apply Now"
                    target="_blank">
                    Apply Now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="commonSec darkgrey_bg">
        <div className="row flex-row-reverse align-items-center">
          <div className="col-md-6 col-sm-6 wow fadeInUp" data-wow-delay="0.2s">
            <div className="carImg rightImg">
              <img src="/images/car-img1.jpg" alt="" />
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="ContentCon">
              <div className="secHead wow fadeInUp" data-wow-delay="0.2s">
                <h2 className="SecondTitle">Turn Opportunities into Cash</h2>
              </div>
              <p className="wow fadeInUp" data-wow-delay="0.3s">
                iLussobuyers is your opportunity to join the exotic car world.
                With our unique platform, you can become a buyer of exotic cars
                in minutes.
              </p>
              <p className="wow fadeInUp" data-wow-delay="0.4s">
                Either just starting your career or wanting to leverage your
                current business relationships,{" "}
                <a href="https://ilussobuyers.com/">iLussobuyers.com</a>{" "}
                connects you to the exotic car world. Our site provides all the
                tools necessary to approach anyone with an exotic car and get
                them an offer immediately.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="commonSec lightgrey_bg simpleSec">
        <div className="simpleInner">
          <div className="secHead wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="secTitle">It’s That Simple</h2>
          </div>

          <ul className="steplist wow fadeInUp" data-wow-delay="0.4s">
            <li>
              <div className="stepBox">
                <div className="stepNumber">01</div>
                <div className="stepTitle">Be You - Socialize</div>
              </div>
            </li>
            <li>
              <div className="stepBox">
                <div className="stepNumber">02</div>
                <div className="stepTitle">Send Form</div>
              </div>
            </li>
            <li>
              <div className="stepBox">
                <div className="stepNumber">03</div>
                <div className="stepTitle">Get Paid</div>
              </div>
            </li>
          </ul>

          <p className="wow fadeInUp" data-wow-delay="0.4s">
            Inside the iLussobuyers portal is a link to send anyone who owns an
            exotic car. Once they complete the form, our appraisal team goes to
            work. You can watch the status of the deal as it progresses. It's
            that simple. If the car is purchased, you get paid a 1% commission
            on the sale price.
          </p>
          <a
            href={routes.SignupThirdParty}
            title="Apply Now"
            target="_blank"
            className="btn btn-red wow fadeInUp"
            data-wow-delay="0.4s">
            Apply Now
          </a>
        </div>
      </section>

      <section className="commonSec darkgrey_bg">
        <div className="row align-items-center">
          <div className="col-md-6 col-sm-6 wow fadeInUp" data-wow-delay="0.2s">
            <div className="carImg">
              <img src="/images/car-img2.jpg" alt="" />
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="ContentCon Conleft">
              <div className="secHead wow fadeInUp" data-wow-delay="0.3s">
                <h2 className="SecondTitle">Partner With Us!</h2>
              </div>
              <p className="wow fadeInUp" data-wow-delay="0.4s">
                There are dozens of synergies between our business and many
                others, so let's partner together and see what we can
                accomplish.
              </p>
              <p className="wow fadeInUp" data-wow-delay="0.4s">
                One of the hardest components of buying exotic cars is being
                able to fund it — but not any more! iLussobuyers will back you.
              </p>
              <p className="wow fadeInUp" data-wow-delay="0.4s">
                Finding exotic cars is our biggest challenge — now we want to
                partner with you to help us connect with owners. If you work at
                a Country Club, valet park, attend Cars and Coffee or are in an
                environment around exotic cars, iLussobuyers is the tool you
                need.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="commonSec lightgrey_bg">
        <div className="row flex-row-reverse align-items-center">
          <div className="col-md-6 col-sm-6 wow fadeInUp" data-wow-delay="0.2s">
            <div className="carImg rightImg">
              <img src="/images/car-img3.jpg" alt="" />
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="ContentCon">
              <div className="secHead wow fadeInUp" data-wow-delay="0.2s">
                <h2 className="SecondTitle">Exclusive Buyer Status</h2>
              </div>
              <p className="wow fadeInUp" data-wow-delay="0.3s">
                Become an authorized buyer for the most exclusive exotic car
                dealer in the United States. For over 20 years, we’ve been
                buying and selling exotic, unique, and luxury cars from all over
                the world.
              </p>
              <p className="wow fadeInUp" data-wow-delay="0.4s">
                We facilitate the ownership of literally hundreds of exotic cars
                every month — and we still can’t find enough! Register now to
                become an exclusive buyer and earn 1% commissions on every sale!
              </p>
              <a
                target="_blank"
                href={routes.SignupThirdParty}
                className="btn btn-red wow fadeInUp"
                data-wow-delay="0.4s">
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="commonSec darkgrey_bg perfectSec">
        <div className="row align-items-center">
          <div className="col-md-6 col-sm-6 wow fadeInUp" data-wow-delay="0.2s">
            <div className="carImg">
              <img src="/images/car-img4.jpg" alt="" />
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="perfectCon">
              <div className="secHead wow fadeInUp" data-wow-delay="0.2s">
                <h2 className="SecondTitle">Perfect For</h2>
              </div>
              <ul className="wow fadeInUp" data-wow-delay="0.3s">
                <li>
                  <p>Car Detailing Shops</p>
                  <p>Luxury Service Centers</p>
                  <p>Freight Forwarders for cars</p>
                  <p>Country Clubs</p>
                  <p>Golf CLubs</p>
                  <p>Club Owners</p>
                  <p>High-ticket Realtors</p>
                </li>
                <li>
                  <p>Yacht Brokers</p>
                  <p>Watch Guys</p>
                  <p>Art Brokers</p>
                  <p>FBO - Private Jets</p>
                  <p>Family Wealth Management</p>
                  <p>Celebrity Managers</p>
                  <p>Sports Managers</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="commonSec lightgrey_bg becomeSec">
        <div className="becomeInner">
          <div className="secHead wow fadeInUp" data-wow-delay="0.2s">
            <h2 className="SecondTitle">Become an Exclusive iLusso Buyer</h2>
          </div>
          <p className="wow fadeInUp" data-wow-delay="0.3s">
            The easiest decision you've ever made
          </p>
          <a
            target="_blank"
            href={routes.SignupThirdParty}
            className="btn btn-red wow fadeInUp"
            data-wow-delay="0.4s">
            Apply Now
          </a>
        </div>
      </section>
    </LayoutsHomeAlt>
  );
};

export default HomeLogin;
