import React from "react";
import "./index.scss";
import { CWidgetStatsA } from "@coreui/react";
import { CChartLine } from "@coreui/react-chartjs";
const FinanceActivity = (props) => {
  return (
    <div>
      <h2>Finance Activity</h2>
      <div className="financeActivity">
        <div className="financeActivityBox">
          <CWidgetStatsA
            className="chartWidgetCard mb-4"
            value={
              <>
                <span className="chartWidgetCount">
                  ${props.financeActivity?.totalDealsAmountCompleted}
                </span>
              </>
            }
            title="Total Deals Amount Completed"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels:
                    props.chartFinanceActivity
                      ?.chartTotalDealsAmountCompleted != undefined
                      ? Object.keys(
                          props.chartFinanceActivity
                            ?.chartTotalDealsAmountCompleted
                        )
                      : 0,
                  datasets: [
                    {
                      label: "Total Deals Amount Completed",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data:
                        props.chartFinanceActivity
                          ?.chartTotalDealsAmountCompleted != undefined
                          ? Object.values(
                              props.chartFinanceActivity
                                ?.chartTotalDealsAmountCompleted
                            )
                          : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </div>
        <div className="financeActivityBox">
          <CWidgetStatsA
            className="chartWidgetCard mb-4"
            value={
              <>
                <span className="chartWidgetCount">
                  ${props.financeActivity?.buyersEarnedCommission}
                </span>
              </>
            }
            title="Buyers Earned Commission"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels:
                    props.chartFinanceActivity?.chartBuyersEarnedCommission !=
                    undefined
                      ? Object.keys(
                          props.chartFinanceActivity
                            ?.chartBuyersEarnedCommission
                        )
                      : 0,
                  datasets: [
                    {
                      label: "Buyers Earned Commission",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data:
                        props.chartFinanceActivity
                          ?.chartBuyersEarnedCommission != undefined
                          ? Object.values(
                              props.chartFinanceActivity
                                ?.chartBuyersEarnedCommission
                            )
                          : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </div>
        <div className="financeActivityBox">
          <CWidgetStatsA
            className="chartWidgetCard mb-4"
            value={
              <>
                <span className="chartWidgetCount">
                  ${props.financeActivity?.paymentMade}
                </span>
              </>
            }
            title="Payment Made"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels:
                    props.chartFinanceActivity?.chartPaymentMade != undefined
                      ? Object.keys(
                          props.chartFinanceActivity?.chartPaymentMade
                        )
                      : 0,
                  datasets: [
                    {
                      label: "Payment Made",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data:
                        props.chartFinanceActivity?.chartPaymentMade !=
                        undefined
                          ? Object.values(
                              props.chartFinanceActivity?.chartPaymentMade
                            )
                          : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </div>
        <div className="financeActivityBox">
          <CWidgetStatsA
            className="chartWidgetCard mb-4"
            value={
              <>
                <span className="chartWidgetCount">
                  ${props.financeActivity?.totalAmountOfPayments}
                </span>
              </>
            }
            title="Total Amount Of Payments"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels:
                    props.chartFinanceActivity?.chartTotalAmountOfPayments !=
                    undefined
                      ? Object.keys(
                          props.chartFinanceActivity?.chartTotalAmountOfPayments
                        )
                      : 0,
                  datasets: [
                    {
                      label: "Total Amount Of Payments",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data:
                        props.chartFinanceActivity
                          ?.chartTotalAmountOfPayments != undefined
                          ? Object.values(
                              props.chartFinanceActivity
                                ?.chartTotalAmountOfPayments
                            )
                          : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </div>
        <div className="financeActivityBox">
          <CWidgetStatsA
            className="chartWidgetCard mb-4"
            value={
              <>
                <span className="chartWidgetCount">
                  ${props.financeActivity?.earnedCommissionBalance}
                </span>
              </>
            }
            title="Earned Commission Balance"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels:
                    props.chartFinanceActivity?.chartEarnedCommissionBalance !=
                    undefined
                      ? Object.keys(
                          props.chartFinanceActivity
                            ?.chartEarnedCommissionBalance
                        )
                      : 0,
                  datasets: [
                    {
                      label: "Earned Commission Balance",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data:
                        props.chartFinanceActivity
                          ?.chartEarnedCommissionBalance != undefined
                          ? Object.values(
                              props.chartFinanceActivity
                                ?.chartEarnedCommissionBalance
                            )
                          : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default FinanceActivity;
