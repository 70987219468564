export const formatPhoneNumber = (phone) => {
  const base = String(phone || "").replace(/[^0-9]/g, "");
  let formatted = "";

  const len = base.length;
  if (len > 0) {
    formatted = `${base.substr(0, 3)}${len >= 3 ? "-" : ""}`;
    if (len > 3) {
      formatted += `${base.substr(3, 3)}`;
    }

    if (len > 6) {
      formatted += `-${base.substr(6)}`;
    }
  }

  return {
    base,
    formatted,
  };
};

export const formatCurrency = (amount) => {
  const formatAmount = amount.toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });

  return `$${formatAmount}`;
};
