import React, { useContext, useEffect, useState } from "react";
import DashboardStat from "./Stat";
import DashboardChartWidget from "./ChartWidget";
import DealActivity from "./DealActivity";
import BuyerActivity from "./BuyerActivity";
import DashboardTopBuyers from "./TopBuyers";
import LayoutsLoggedIn from "../Layout/LoggedIn";
import api from "../../config/api";
import AuthContext from "../../store/authContext";
import RefreshContext from "../../store/refreshContext";
import AppLoading from "../Common/AppLoading";
import { CCol, CRow, CWidgetStatsA } from "@coreui/react";
import { CChartLine } from "@coreui/react-chartjs";
import SendOffer from "./SendOffer";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);

  useEffect(() => {
    fetchDashboardCount();
  }, [refreshCtx.refresh]);

  async function fetchDashboardCount(formData = {}) {
    await api
      .post(`dashboard`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setDashboardData(response.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  return (
    <>
      {isLoading ? (
        <AppLoading />
      ) : (
        <LayoutsLoggedIn>
          <SendOffer page="dashboard" />
          <DashboardStat data={dashboardData} />
          <DashboardChartWidget />
          {authCtx.userData.user_type == 0 && (
            <>
              <DealActivity />
              <div>
                <h5>Buyers Activity</h5>
                {dashboardData?.buyer && (
                  <BuyerActivity data={dashboardData.buyer} />
                )}
                <DashboardTopBuyers />
              </div>
            </>
          )}
          {authCtx.userData.user_type == 1 && (
            <div>
              <CRow className="justify-content-between align-items-center my-3">
                <CCol lg xs={12}>
                  <h5 className="mb-0">Finance Activity</h5>
                </CCol>
              </CRow>
              <div className="financeActivity">
                <div className="financeActivityBox">
                  <CWidgetStatsA
                    className="chartWidgetCard mb-4"
                    value={
                      <>
                        <span
                          className="chartWidgetCount"
                          style={{
                            fontSize: "17px",
                            fontWeight: "normal",
                            display: "block",
                          }}>
                          $
                          {
                            dashboardData?.financeActivity
                              ?.buyersEarnedCommission
                          }
                        </span>
                      </>
                    }
                    title="Total Earned Commission"
                    chart={
                      <CChartLine
                        className="mt-3"
                        style={{ height: "70px" }}
                        data={{
                          labels:
                            dashboardData?.chartFinanceActivity
                              ?.chartBuyersEarnedCommission != undefined
                              ? Object.keys(
                                  dashboardData?.chartFinanceActivity
                                    .chartBuyersEarnedCommission
                                )
                              : 0,
                          datasets: [
                            {
                              label: "Total Earned Commission",
                              backgroundColor: "transparent",
                              borderColor: "rgba(255,255,255,.55)",
                              data: dashboardData?.chartFinanceActivity
                                ?.chartBuyersEarnedCommission
                                ? Object.values(
                                    dashboardData?.chartFinanceActivity
                                      .chartBuyersEarnedCommission
                                  )
                                : 0,
                            },
                          ],
                        }}
                        options={{
                          plugins: {
                            legend: {
                              display: false,
                            },
                          },
                          maintainAspectRatio: false,
                          scales: {
                            x: {
                              display: false,
                            },
                            y: {
                              display: false,
                            },
                          },
                          elements: {
                            line: {
                              borderWidth: 2,
                              tension: 0.4,
                            },
                            point: {
                              radius: 0,
                              hitRadius: 10,
                              hoverRadius: 4,
                            },
                          },
                        }}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </LayoutsLoggedIn>
      )}
    </>
  );
};

export default Dashboard;
