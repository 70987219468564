import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

import { useForm } from "react-hook-form";
import _ from "lodash";

import "./signup.scss";
import LayoutsSignup from "../Layout/Signup";
import TermsCondition from "./TermsCondition";
import routes from "../../config/routes";
import api from "../../config/api";
import AppLoading from "../Common/AppLoading";
import { formatPhoneNumber } from "../../utils/formats";
import { message } from "antd";

const states = _.map(
  {
    AL: "Alabama",
    AK: "Alaska",
    AS: "American Samoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "Marshall Islands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PW: "Palau",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  },
  (label, value) => ({ label, value })
);

const SignupTeam = React.memo(() => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const [isAccept, setIsAccept] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);

  const mobile_no = watch("mobile_no");
  useEffect(() => {
    if (mobile_no != "" && mobile_no != undefined) {
      setValue("mobile_no", formatPhoneNumber(mobile_no).formatted);
    }
  }, [mobile_no]);

  const onSubmit = async (formData) => {
    setLoading(true);
    formData.parent_id = id;
    await api
      .post("signupteam", formData)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          message.success("Thanks! you, for register with us.");
          localStorage.setItem(
            "email",
            JSON.stringify(response.data.data.encryptEmail)
          );
          reset();
          setCheckboxChecked();
        } else {
          setError(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const onCancel = useCallback(() => {
    if (!isAccept) {
      setCheckboxChecked(false);
      setIsAccept(false);
    } else {
      setCheckboxChecked(true);
    }
    setVisible(false);
  }, []);

  const openPopup = useCallback(({ target }) => {
    if (target.checked) {
      setVisible(true);
      setCheckboxChecked(true);
    } else {
      setCheckboxChecked(false);
      setIsAccept(false);
      setVisible(false);
    }
  }, []);

  const onAccept = useCallback(() => {
    setIsAccept(true);
    setVisible(false);
    setCheckboxChecked(true);
  }, [setIsAccept]);

  return (
    <>
      {isLoading ? (
        <AppLoading />
      ) : (
        <LayoutsSignup>
          <div className="Innerform">
            <a href={routes.Home} className="emaillogo">
              <img src="/images/logo.png" alt="" />
            </a>
            <h4 className="frmTitle">
              Register your iLusso <br /> Buyer’s Network Account
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formcol">
              <input
                className={`inputformField ${errors.name ? "is-invalid" : ""}`}
                placeholder="Full Name"
                {...register("name", { required: true })}
              />
              {errors.name?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formcol">
              <input
                className={`inputformField ${errors.email ? "is-invalid" : ""}`}
                placeholder="Email"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
                autoComplete="off"
              />
              {errors.email?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
              {errors.email?.type === "pattern" && (
                <span className="formError">Enter Valid Email</span>
              )}
            </div>
            <div className="formcol">
              <input
                type={passwordShown ? "text" : "password"}
                className={`inputformField ${
                  errors.password ? "is-invalid" : ""
                }`}
                placeholder="Password"
                {...register("password", { required: true })}
                autoComplete="off"
              />
              <i
                className={`inputformPasswordToggle ${
                  passwordShown ? "show" : "hide"
                }`}
                onClick={togglePassword}></i>
              {errors.password && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formcol">
              <input
                type={passwordShown ? "text" : "password"}
                className={`inputformField ${
                  errors.confirmation_password ? "is-invalid" : ""
                }`}
                placeholder="Confirm Password"
                {...register("confirmation_password", { required: true })}
              />
              <i
                className={`inputformPasswordToggle ${
                  passwordShown ? "show" : "hide"
                }`}
                onClick={togglePassword}></i>
              {errors.confirmPassword && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formcol">
              <input
                type="text"
                className={`inputformField ${
                  errors.mobile_no ? "is-invalid" : ""
                }`}
                placeholder="Phone No"
                {...register("mobile_no", { required: true })}
                maxLength={12}
              />
              {errors.mobile_no && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formcol">
              <select
                className={`inputformSelect custom_drop ${
                  errors.state ? "is-invalid" : ""
                }`}
                {...register("state", { required: true })}>
                <option selected disabled>
                  State
                </option>
                {_.map(states, (state) => (
                  <option key={state.value} value={state.label}>
                    {state.label}
                  </option>
                ))}
              </select>
              {errors.state && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formcol">
              <label className="customCheckbox">
                <input
                  type="checkbox"
                  checked={checkboxChecked}
                  onChange={openPopup}
                />
                <span className="lable-text">
                  By signing up, you confirm that you’ve read and accepted our{" "}
                  <a href="#">Terms of Service</a>
                </span>
              </label>
            </div>
            <div className="formbtn">
              <button
                type="submit"
                className="btn btn-red"
                disabled={!isAccept}>
                Apply Now
              </button>
              {error != "" && <span className="formError">{error}</span>}
            </div>
            <div className="form_account">
              <div className="accountInner">
                Already have an account?{" "}
                <Link to="/" title="Log In">
                  Log In
                </Link>
              </div>
            </div>
          </form>
          <TermsCondition
            visible={isVisible}
            onCancel={onCancel}
            onAccept={onAccept}></TermsCondition>
        </LayoutsSignup>
      )}
    </>
  );
});

export default SignupTeam;
