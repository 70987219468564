import React from "react";

const SvgSms = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M20.41,22.07H20c-.28-.11-.58-.2-.85-.33l-2.85-1.42a.42.42,0,0,0-.31,0,9.66,9.66,0,0,1-5.37.89,9.32,9.32,0,0,1-6.47-3.59,9.12,9.12,0,0,1-2-6.87A9.22,9.22,0,0,1,3.88,6a9.81,9.81,0,0,1,6.84-4,9.4,9.4,0,0,1,5,.66,9.7,9.7,0,0,1,5.06,12.5.77.77,0,0,0,0,.56c.4,1.36.8,2.71,1.16,4.08a1.62,1.62,0,0,1-.64,1.86A5.2,5.2,0,0,1,20.41,22.07Zm-.62-2.34a1.88,1.88,0,0,0-.05-.22c-.32-1-.62-2.08-1-3.11a2.68,2.68,0,0,1,.08-1.95A7.72,7.72,0,0,0,19.28,10a7.63,7.63,0,0,0-9.23-5.88A7.73,7.73,0,0,0,4.3,13.41a7.57,7.57,0,0,0,6.45,5.73A8.32,8.32,0,0,0,15,18.5a2.58,2.58,0,0,1,2.4,0c.45.24.91.46,1.37.69Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M15,11.44a1.43,1.43,0,1,1,1.42,1.45A1.42,1.42,0,0,1,15,11.44Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M13.38,11.46a1.43,1.43,0,1,1-2.85,0,1.43,1.43,0,1,1,2.85,0Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M7.47,10A1.43,1.43,0,1,1,6,11.45,1.43,1.43,0,0,1,7.47,10Z"
    />
  </svg>
);

export default SvgSms;
