import React, { useEffect, useState } from 'react';

import { Button } from 'antd';

import CommonDrawerSectionHeader from '../../Common/Drawer/SectionHeader';

const OfferDetailsEditHeader = React.memo(
  ({ isEditing, onSaveClick, setEditing, title }) =>{

    return(
        <CommonDrawerSectionHeader title={title}>
          {isEditing ? (
            <>
              <Button
                className="button-secondary"
                onClick={() => setEditing(false)}
              >
                Cancel
              </Button>
              <Button className="button-alt margin-left-0_8" onClick={onSaveClick}>
                Save
              </Button>
            </>
          ) : (
            <Button className="button-secondary" onClick={() => setEditing(true)}>
              Edit
            </Button>
          )}
        </CommonDrawerSectionHeader>
      );
  }
);

export default OfferDetailsEditHeader;
