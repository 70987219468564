import React from "react";
import _ from "lodash";
import { Drawer, message } from "antd";
import CommonDrawerSection from "../../Common/Drawer/Section";
import AuthContext from "../../../store/authContext";
import { useContext } from "react";
import api from "../../../config/api";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useEffect } from "react";

const EditBuyer = React.memo(({ buyerId, onClose }) => {
  const [isLoading, setLoading] = useState(true);
  const [companies, setCompanies] = useState({});
  const [roles, setRoles] = useState({});

  const authCtx = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await api
      .post(
        `company`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setCompanies(response.data.data?.company);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });

    await api
      .post(
        `role`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setRoles(response.data.data?.company);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const onSubmit = async (formValues) => {
    setLoading(true);
    formValues.user_id = buyerId;
    await api
      .post(`users/assign-company`, formValues, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          onClose();
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  return (
    <>
      <Drawer
        destroyOnClose
        mask={false}
        onClose={onClose}
        placement="right"
        title="Assign Company"
        visible={!!buyerId}
        width={500}
      >
        <CommonDrawerSection>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formbtn">
              <div className="formcol">
                <label className="input-label">Select Company</label>
                <select
                  className={`input-select ${
                    errors.company ? "is-invalid" : ""
                  }`}
                  {...register("company_id")}
                >
                  <option>Select Company</option>
                  {_.map(companies, (company) => (
                    <option key={company.id} value={company.id}>
                      {company.title}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <span className="formError">
                    {errors.company_id?.message}
                  </span>
                )}
              </div>
              <div className="formcol">
                <label className="input-label">Select Role</label>
                <select
                  className={`input-select ${
                    errors.role_id ? "is-invalid" : ""
                  }`}
                  {...register("role_id")}
                >
                  <option>Select Role</option>
                  {_.map(roles, (role) => (
                    <option key={role.id} value={role.id}>
                      {role.role_name}
                    </option>
                  ))}
                </select>
                {errors.state && (
                  <span className="formError">{errors.role_id?.message}</span>
                )}
              </div>
              <button type="submit" className="input-btn">
                Assign Company
              </button>
            </div>
          </form>
        </CommonDrawerSection>
      </Drawer>
    </>
  );
});

export default EditBuyer;
