import React, { useContext, useEffect, useState } from "react";
import "./index.scss";
import { CChartLine } from "@coreui/react-chartjs";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import { CCol, CRow, CWidgetStatsA } from "@coreui/react";
import RefreshContext from "../../../store/refreshContext";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";
import moment from "moment";
import calenderIcon from "../../../images/calender.svg";

const DealActivity = () => {
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const refreshCtx = useContext(RefreshContext);
  useEffect(() => {
    fetchDashboardCount();
  }, [refreshCtx.refresh]);

  const authCtx = useContext(AuthContext);
  async function fetchDashboardCount(formData = {}) {
    await api
      .post(`dashboard`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setDashboardData(response.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const chartFinanceActivityOnChange = (e) => {
    fetchDashboardCount({ duration_finance_activity: e.target.value });
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const onFinanceChartRangeChange = (value) => {
    setSelectedDate(value);
    if (value != null) {
      fetchDashboardCount({
        duration_finance_activity: "custom_date_finance",
        start_date_finance: moment(value[0]).format("YYYY-MM-DD"),
        end_date_finance: moment(value[1]).format("YYYY-MM-DD"),
      });
    } else {
      fetchDashboardCount();
    }
  };

  return (
    <div>
      <CRow className="justify-content-between align-items-center my-3">
        <CCol lg xs={12}>
          <h5 className="mb-0">Finance Activity</h5>
        </CCol>
        <CCol xs={8} lg="auto">
          <DateRangePicker
            onChange={(e) => onFinanceChartRangeChange(e)}
            value={selectedDate}
            format="M-d-y"
            yearPlaceholder="YYYY"
            monthPlaceholder="MM"
            dayPlaceholder="DD"
            calendarIcon={<img src={calenderIcon} style={{ height: "17px" }} />}
          />
        </CCol>
        <CCol xs={5} lg="auto">
          <select
            className="input-select"
            onChange={(e) => chartFinanceActivityOnChange(e)}
          >
            <option value="7">Last 7 Days</option>
            <option value="30">Last 30 Days</option>
            <option value="75">Last 75 Days</option>
            <option value="365">Last 1 Year</option>
          </select>
        </CCol>
      </CRow>
      <div className="financeActivity">
        <div className="financeActivityBox">
          <CWidgetStatsA
            className="chartWidgetCard mb-4"
            value={
              <>
                <span
                  className="chartWidgetCount"
                  style={{
                    fontSize: "17px",
                    fontWeight: "normal",
                    display: "block",
                  }}
                >
                  ${dashboardData?.financeActivity?.totalDealsAmountCompleted}
                </span>
              </>
            }
            title="Total Deals Amount Completed"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels:
                    dashboardData?.chartFinanceActivity
                      ?.chartTotalDealsAmountCompleted != undefined
                      ? Object.keys(
                          dashboardData?.chartFinanceActivity
                            .chartTotalDealsAmountCompleted
                        )
                      : 0,
                  datasets: [
                    {
                      label: "Total Deals Amount Completed",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data: dashboardData?.chartFinanceActivity
                        ?.chartTotalDealsAmountCompleted
                        ? Object.values(
                            dashboardData?.chartFinanceActivity
                              .chartTotalDealsAmountCompleted
                          )
                        : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </div>
        <div className="financeActivityBox">
          <CWidgetStatsA
            className="chartWidgetCard mb-4"
            value={
              <>
                <span
                  className="chartWidgetCount"
                  style={{
                    fontSize: "17px",
                    fontWeight: "normal",
                    display: "block",
                  }}
                >
                  ${dashboardData?.financeActivity?.buyersEarnedCommission}
                </span>
              </>
            }
            title="Buyers Earned Commission"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels: dashboardData?.chartFinanceActivity
                    ?.chartBuyersEarnedCommission
                    ? Object.keys(
                        dashboardData?.chartFinanceActivity
                          .chartBuyersEarnedCommission
                      )
                    : 0,
                  datasets: [
                    {
                      label: "Buyers Earned Commission",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data: dashboardData?.chartFinanceActivity
                        ?.chartBuyersEarnedCommission
                        ? Object.values(
                            dashboardData?.chartFinanceActivity
                              .chartBuyersEarnedCommission
                          )
                        : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </div>
        <div className="financeActivityBox">
          <CWidgetStatsA
            className="chartWidgetCard mb-4"
            value={
              <>
                <span
                  className="chartWidgetCount"
                  style={{
                    fontSize: "17px",
                    fontWeight: "normal",
                    display: "block",
                  }}
                >
                  ${dashboardData?.financeActivity?.paymentMade}
                </span>
              </>
            }
            title="Payment Made"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels:
                    dashboardData?.chartFinanceActivity?.chartPaymentMade !=
                    undefined
                      ? Object.keys(
                          dashboardData?.chartFinanceActivity?.chartPaymentMade
                        )
                      : 0,
                  datasets: [
                    {
                      label: "Payment Made",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data:
                        dashboardData?.chartFinanceActivity?.chartPaymentMade !=
                        undefined
                          ? Object.values(
                              dashboardData?.chartFinanceActivity
                                .chartPaymentMade
                            )
                          : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </div>
        <div className="financeActivityBox">
          <CWidgetStatsA
            className="chartWidgetCard mb-4"
            value={
              <>
                <span
                  className="chartWidgetCount"
                  style={{
                    fontSize: "17px",
                    fontWeight: "normal",
                    display: "block",
                  }}
                >
                  ${dashboardData?.financeActivity?.totalAmountOfPayments}
                </span>
              </>
            }
            title="Total Amount Of Payments"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels:
                    dashboardData?.chartFinanceActivity
                      ?.chartTotalAmountOfPayments != undefined
                      ? Object.keys(
                          dashboardData?.chartFinanceActivity
                            ?.chartTotalAmountOfPayments
                        )
                      : 0,
                  datasets: [
                    {
                      label: "Total Amount Of Payments",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data:
                        dashboardData?.chartFinanceActivity
                          ?.chartTotalAmountOfPayments != undefined
                          ? Object.values(
                              dashboardData?.chartFinanceActivity
                                ?.chartTotalAmountOfPayments
                            )
                          : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </div>
        <div className="financeActivityBox">
          <CWidgetStatsA
            className="chartWidgetCard mb-4"
            value={
              <>
                <span
                  className="chartWidgetCount"
                  style={{
                    fontSize: "17px",
                    fontWeight: "normal",
                    display: "block",
                  }}
                >
                  ${dashboardData?.financeActivity?.earnedCommissionBalance}
                </span>
              </>
            }
            title="Earned Commission Balance"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels:
                    dashboardData?.chartFinanceActivity
                      ?.chartEarnedCommissionBalance != undefined
                      ? Object.keys(
                          dashboardData?.chartFinanceActivity
                            ?.chartEarnedCommissionBalance
                        )
                      : 0,
                  datasets: [
                    {
                      label: "Earned Commission Balance",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data: dashboardData?.chartFinanceActivity
                        ?.chartEarnedCommissionBalance
                        ? Object.values(
                            dashboardData?.chartFinanceActivity
                              ?.chartEarnedCommissionBalance
                          )
                        : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default DealActivity;
