import React, { useCallback, useContext, useState } from "react";
import { Button, Col, Modal, Row, Tooltip } from "antd";
import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import { saveAsPng } from "save-html-as-image";
import _ from "lodash";
import SvgClose from "../../../images/close";
import SvgPlusCircle from "../../../images/plusCircle";
import AuthContext from "../../../store/authContext";

const CopyJoinLink = React.memo(() => {
  const [isVisible, setVisible] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const authCtx = useContext(AuthContext);

  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const onJoinClick = useCallback(() => {
    setVisible(true);
  }, []);

  const downloadQrPng = () => {
    const qrNode = document.getElementById("qrNode");
    saveAsPng(qrNode, { filename: "iLusso-buyers-share-qr" });
  };

  return (
    <>
      <Button
        block
        className="button-primary button-large-text button-dark-blue"
        htmltype="button"
        onClick={onJoinClick}
        type="primary">
        <SvgPlusCircle /> Copy Signup Link
      </Button>
      <Modal
        destroyOnClose
        closeIcon={<SvgClose />}
        footer={null}
        onCancel={onCancel}
        visible={isVisible}
        width={390}>
        <>
          <h3 className="ds-title">Copy Signup Link</h3>
          <Row className="color-base" justify="center">
            <Col className="text-center padding-bottom-1_5">
              Click on below button to copy an iLusso team signup form short
              link.
            </Col>
          </Row>
          <CopyToClipboard
            text={authCtx?.userData?.signupTeamURL}
            onCopy={() => setIsCopied(true)}>
            <div className="formCopyLinkWrap">
              <span>{authCtx?.userData?.signupTeamURL}</span>
              <button type="button">
                {isCopied ? <CheckOutlined /> : <CopyOutlined />}
              </button>
            </div>
          </CopyToClipboard>
          <div className="text-center">
            <div id="qrNode">
              <QRCode value={authCtx?.userData?.signupTeamURL} />
            </div>
          </div>
          <div className="formbtn">
            <button
              type="button"
              className="input-btn"
              onClick={() => downloadQrPng()}>
              Download QR Code
            </button>
          </div>
        </>
      </Modal>
    </>
  );
});

export default CopyJoinLink;
