import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Dropdown, Menu, message, Modal, Row, Spin } from "antd";
import SvgDropdown from "../../images/downdrop";
import "./UserMenu.scss";
import DashboardSendLink from "../Dashboard/SendLead";
import SendOffer from "../Dashboard/SendOffer";
import routes from "../../config/routes";
import AuthContext from "../../store/authContext";
import { BellOutlined, CloseSquareOutlined } from "@ant-design/icons";
import api from "../../config/api";
import moment from "moment";
import RefreshContext from "../../store/refreshContext";
import { useForm } from "react-hook-form";
import SvgClose from "../../images/close";
import SvgSuccess from "../../images/success";
import _ from "lodash";

const CommonUserMenu = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);

  const [showNotification, setShowNotification] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const notificationDropdownWrap = useRef(null);

  const [isLoading, setLoading] = useState(true);

  const [templates, setTemplates] = useState([]);
  const [replyId, setReplyId] = useState("");

  const [successText, setSuccessText] = useState();
  const [isVisible, setVisible] = useState(false);

  const logoutHandler = () => {
    authCtx.logout();
    navigate(routes.Home);
  };

  const notificationToggle = () => {
    setShowNotification(!showNotification);
  };

  useEffect(() => {
    getNotifications();
    const intervalCall = setInterval(() => {
      getNotifications();
    }, 300000);
    return () => {
      clearInterval(intervalCall);
    };
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        notificationDropdownWrap.current &&
        !notificationDropdownWrap.current.contains(event.target)
      ) {
        setShowNotification(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [notificationDropdownWrap, showNotification]);

  const getNotifications = async () => {
    setLoading(true);
    await api
      .post(
        `smstemplate/getnotificationlist`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setNotifications([]);
        if (response.data.status) {
          let notificationArray = [];
          for (const key in response.data.data?.sms_messages) {
            notificationArray.push(response.data.data?.sms_messages[key]);
          }
          setNotifications(notificationArray);
          setLoading(false);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const markReadHandler = (notification_id, buyer_id) => {
    api
      .post(
        `smstemplate/updatenotification?notification_id=${notification_id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setShowNotification(false);
          setSuccessText(null);
          setVisible(true);
          getTemplates();
          setReplyId(buyer_id);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onCancel = useCallback(() => {
    setVisible(false);
    reset();
  }, []);

  const getTemplates = async () => {
    await api
      .post(
        `smstemplate`,
        { user_id: authCtx.userData?.id, per_page: 100, is_active: 1 },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          let templatesArray = [];
          if (response.data.data.lead.length > 0) {
            response.data.data.lead.map((element) => {
              templatesArray.push({
                value: element.id,
                label: element.name,
              });
            });
            setTemplates(templatesArray);
          } else {
            setTemplates([]);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const getTemplateContent = async (id) => {
    await api
      .post(
        `smstemplate/get-smstemplate`,
        {
          p_id: id,
          user_id: authCtx.userData?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx?.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setValue("content", response.data.data?.content);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    await api
      .post(
        `smstemplate/sendsmsbuyers`,
        {
          buyer_id: [replyId],
          content: formData.content,
          send_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          onCancel();
          refreshCtx.onRefresh();
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const clearAllNotification = async () => {
    await api
      .post(
        `smstemplate/clearallnotification`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  return (
    <>
      <Modal
        destroyOnClose
        closeIcon={<SvgClose />}
        footer={null}
        onCancel={onCancel}
        visible={isVisible}
        width={390}>
        {successText ? (
          <>
            <Row justify="center">
              <SvgSuccess />
            </Row>
            <h3 className="ds-title">SMS Sended Successfully!</h3>
            <p className="text-center">
              A team invite has been sent to {successText}
            </p>
            <Row justify="center">
              <Button
                block
                className="button-primary"
                htmltype="submit"
                loading={isLoading}
                onClick={onCancel}>
                Close
              </Button>
            </Row>
          </>
        ) : (
          <>
            <h3 className="ds-title">Send SMS</h3>
            <Row className="color-base" justify="center">
              <Col className="text-center padding-bottom-1_5">
                Please select sms template or select custom template option to
                edit default.
              </Col>
            </Row>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="formcol">
                <label className="input-label">Template</label>
                <select
                  className="input-select"
                  onChange={(e) => getTemplateContent(e.target.value)}>
                  <option>Select Template</option>
                  {_.map(templates, (template) => (
                    <option key={template.value} value={template.value}>
                      {template.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formcol">
                <label className="input-label">Content</label>
                <textarea
                  className="input-text"
                  placeholder="Enter content here"
                  {...register("content", { required: true })}></textarea>
                {errors.content?.type === "required" && (
                  <span className="formError">This field is required</span>
                )}
              </div>
              <div className="formbtn">
                <button type="submit" className="input-btn">
                  Send Now
                </button>
              </div>
            </form>
          </>
        )}
      </Modal>
      {authCtx.userData?.user_type == 0 && (
        <div className="notificationboxMain" ref={notificationDropdownWrap}>
          <button
            type="button"
            className="notificationboxBtn"
            onClick={() => notificationToggle()}>
            {notifications && notifications.length !== 0 && (
              <span className="dot"></span>
            )}
            <BellOutlined />
          </button>
          <div className={`notificationbox ${showNotification ? "show" : ""}`}>
            <button
              type="button"
              className="notification-close"
              onClick={() => notificationToggle()}>
              <CloseSquareOutlined />
            </button>
            <h5 className="notifiTitle">Notifications</h5>
            <div className="notifibox">
              {isLoading ? (
                <Spin />
              ) : (
                <ul>
                  {notifications && notifications.length != 0 ? (
                    notifications.map((data, key) => (
                      <li
                        key={key}
                        className={`${data.is_read != 0 ? "d-none" : ""}`}>
                        <p>{data?.body}</p>
                        <div className="d-flex align-items-center justify-content-between">
                          <span>
                            {moment(data?.date_sent).fromNow(true)} ago
                          </span>
                          <div className="text-end">
                            <i className="d-block">-{data?.buyername}</i>
                            <button
                              type="button"
                              onClick={() =>
                                markReadHandler(
                                  data?.notification_id,
                                  data?.buyerid
                                )
                              }>
                              Reply
                            </button>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <li>No unread notifications found.</li>
                  )}
                </ul>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <Link className="viewmore" to={routes.Mailbox}>
                View all
              </Link>
              <button
                type="button"
                className="btn-sm"
                onClick={() => clearAllNotification()}>
                Clear All
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="d-lg-flex d-none">
        <DashboardSendLink />
        <SendOffer />
      </div>
      <Dropdown
        className="common-user-menu"
        overlay={
          <Menu selectable={false}>
            <Menu.Item key="profile">
              <Link to={routes.Profile}>Profile</Link>
            </Menu.Item>
            <Menu.Item key="logoutHandler">
              <Link to="" onClick={() => logoutHandler()}>
                Logout
              </Link>
            </Menu.Item>
          </Menu>
        }
        placement="bottomRight"
        trigger={["click"]}>
        <a onClick={(e) => e.preventDefault()}>
          <span>{authCtx.userData?.name}</span>
          <SvgDropdown />
        </a>
      </Dropdown>
    </>
  );
};

export default CommonUserMenu;
