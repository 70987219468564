import React, { useContext, useEffect, useState } from "react";

import { Drawer, message, Spin } from "antd";
import { useForm } from "react-hook-form";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";
import RefreshContext from "../../../store/refreshContext";
import CommonDrawerSection from "../../Common/Drawer/Section";

const EditTemplate = React.memo(({ templateId, onClose }) => {
  const [isLoading, setLoading] = useState(true);
  const [companyDetail, setCompanyDetail] = useState({});

  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (templateId != undefined) {
      api
        .post(
          `smstemplate/get-smstemplate`,
          {
            p_id: templateId,
            user_id: authCtx.userData?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${authCtx?.userData?.token}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            setValue("name", response.data.data?.name);
            setValue("content", response.data.data?.content);
            setValue("status", response.data.data?.status);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [templateId]);

  const onSubmit = async (formValues) => {
    setLoading(true);
    await api
      .post(
        `smstemplate/update-smstemplate`,
        {
          ...formValues,
          smstemplate_id: templateId,
          user_id: authCtx.userData?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          reset();
          onClose();
          refreshCtx.onRefresh();
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  return (
    <Drawer
      className="template-detail"
      destroyOnClose
      mask={false}
      onClose={onClose}
      placement="right"
      title="Template Details"
      width={500}
      visible={!!templateId}
    >
      <CommonDrawerSection>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formcol">
            <label className="input-label">Name</label>
            <input
              className={`input-text ${errors.title ? "is-invalid" : ""}`}
              placeholder="Name"
              {...register("name", {
                required: true,
              })}
            />
            {errors.title?.type === "required" && (
              <span className="formError">This field is required</span>
            )}
          </div>
          <div className="formcol">
            <label className="input-label">Status</label>
            <select
              className={`input-select ${errors.status ? "is-invalid" : ""}`}
              {...register("status", {
                required: true,
              })}
            >
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
            {errors.status?.type === "required" && (
              <span className="formError">This field is required</span>
            )}
          </div>
          <div className="formcol">
            <label className="input-label">Content</label>
            <textarea
              className="input-text"
              placeholder="Enter content here"
              {...register("content", { required: true })}
            ></textarea>
            {errors.content?.type === "required" && (
              <span className="formError">This field is required</span>
            )}
          </div>
          <div className="formbtn">
            <button type="submit" className="input-btn">
              Update Template
            </button>
          </div>
        </form>
      </CommonDrawerSection>
    </Drawer>
  );
});

export default EditTemplate;
