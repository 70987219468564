import React, { useCallback, useState } from "react";

const RefreshContext = React.createContext({
  refresh: "",
  onRefresh: () => {},
});

export const RefreshContextProvider = (props) => {
  const [refresh, setRefresh] = useState(0);

  const onRefreshHandler = useCallback(() => {
    setRefresh((refresh) => refresh + 1);
  }, [refresh]);

  const contextValue = {
    refresh: refresh,
    onRefresh: onRefreshHandler,
  };

  return (
    <RefreshContext.Provider value={contextValue}>
      {props.children}
    </RefreshContext.Provider>
  );
};

export default RefreshContext;
