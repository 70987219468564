import React from 'react';

const SvgLogo = props => (
  <svg width={140} height={20} viewBox="0 0 140 20" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.1566 20H0L6.66684 5.79987L5.18094 5.03223V4.57672H17.3977L10.1566 20Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4258 20L21.0927 5.79987L19.6067 5.03223V4.57672H31.8235L25.1567 18.7769H133.959L133.385 20H14.4258Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.4124 4.57672H50.2557L45.1015 15.555H40.7567L45.9109 4.57672H33.694V5.03223L35.1799 5.79988L31.1568 14.3693C30.534 15.6958 31.1414 16.7782 32.51 16.7782H52.1817C53.5504 16.7782 55.1908 15.6989 55.8135 14.3722L60.4124 4.57673V4.57672Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.0689 3.12633H7.91211L9.37992 0H19.5366L18.0689 3.12633Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.8635 12.244H61.76C60.2004 12.244 60.0081 10.9158 60.5387 9.78569L61.7224 7.26469C62.7457 5.08408 64.6448 4.57672 66.9377 4.57672H87.6203L87.0461 5.79988H72.5668L70.97 9.20106H83.0739C84.6332 9.20106 84.8255 10.5291 84.2952 11.6593L83.1532 14.0902C82.13 16.2708 80.2306 16.7782 77.9377 16.7782H57.2559L57.8301 15.555H72.3093L73.8642 12.2439L73.8635 12.244Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M99.7803 12.244H87.6764C86.1164 12.244 85.9241 10.9158 86.4551 9.78569L87.639 7.26469C88.6622 5.08408 90.5616 4.57672 92.8545 4.57672H113.536L112.962 5.79988H98.4828L96.8861 9.20106H108.99C110.549 9.20106 110.742 10.5291 110.211 11.6593L109.07 14.0902C108.046 16.2708 106.147 16.7782 103.854 16.7782H83.1719L83.7461 15.555H98.2254L99.7803 12.2439V12.244Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.763 4.57674H137.533C139.23 4.57674 140.002 5.9204 139.231 7.56075L136.313 13.7771C135.543 15.4176 133.535 16.7782 131.838 16.7782H113.099C111.402 16.7782 110.593 15.4088 111.363 13.7681L114.278 7.55919C115.048 5.91856 117.066 4.57672 118.763 4.57672V4.57674ZM125.26 5.7999H129.902L125.322 15.5551H120.68L125.26 5.7999Z"
      fill="white"
    />
  </svg>
);

export default SvgLogo;
