import React from "react";

const SvgPayment = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M1,5.22a.91.91,0,0,1,.48-.63,1.08,1.08,0,0,1,.43-.1H6.65a.44.44,0,1,1,0,.88H1.89V7.1h4.7a1,1,0,0,1,.17,0,.43.43,0,0,1,.36.44A.42.42,0,0,1,6.74,8H1.89v5.24H14.12v-3a1.09,1.09,0,0,1,0-.18.43.43,0,0,1,.86.06v3a.89.89,0,0,1-1,1H2.09c-.65,0-.86-.14-1.09-.74Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M15,4.71a10.41,10.41,0,0,1-.1,1.93A4.73,4.73,0,0,1,13.2,9.52a10.89,10.89,0,0,1-1.44,1,.47.47,0,0,1-.51,0A8.6,8.6,0,0,1,9.24,9,4.64,4.64,0,0,1,8.08,6.51,23.57,23.57,0,0,1,8,3.6a.46.46,0,0,1,.34-.43C9.23,2.79,10.13,2.42,11,2a1,1,0,0,1,1,0c.88.41,1.77.78,2.67,1.15a.52.52,0,0,1,.35.55ZM14.12,5c0-.42,0-.71,0-1A.18.18,0,0,0,14,3.86l-2.45-1a.25.25,0,0,0-.17,0L9,3.86A.16.16,0,0,0,8.88,4c0,.76,0,1.52.06,2.28a3.47,3.47,0,0,0,.75,1.91,6.28,6.28,0,0,0,1.75,1.45.15.15,0,0,0,.14,0c.38-.26.76-.51,1.12-.8A3.74,3.74,0,0,0,14,6.63,7.41,7.41,0,0,0,14.12,5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M4.06,9.73H4.9a.44.44,0,1,1,0,.87H3.24a.44.44,0,1,1,0-.87Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M11.1,6.36,12.42,4.7a.47.47,0,0,1,.47-.21.44.44,0,0,1,.24.72c-.27.35-.55.69-.83,1l-.85,1.07a.45.45,0,0,1-.8,0L9.84,6.06a.44.44,0,0,1,.24-.69.43.43,0,0,1,.48.2l.45.67Z"
    />
  </svg>
);

export default SvgPayment;
