import React from "react";
import { CRow, CCol, CWidgetStatsA } from "@coreui/react";
import { CChartLine } from "@coreui/react-chartjs";

const DealActivity = (props) => {
  return (
    <CRow>
      <CCol sm={6} lg={3}>
        <CWidgetStatsA
          className="chartWidgetCard bg-primary-gradient mb-4"
          color="primary-gradient"
          value={
            <>
              <span className="chartWidgetCount">
                {props.leadActivity?.leadFormsSentCount}
              </span>
            </>
          }
          title="Lead Forms Sent"
          chart={
            <CChartLine
              className="mt-3"
              style={{ height: "70px" }}
              data={{
                labels:
                  props.chartDealActivity?.chartLeadFormsSentCount != undefined
                    ? Object.keys(
                        props.chartDealActivity?.chartLeadFormsSentCount
                      )
                    : 0,
                datasets: [
                  {
                    label: "Lead Forms Sent",
                    backgroundColor: "transparent",
                    borderColor: "rgba(255,255,255,.55)",
                    data:
                      props.chartDealActivity?.chartLeadFormsSentCount !=
                      undefined
                        ? Object.values(
                            props.chartDealActivity?.chartLeadFormsSentCount
                          )
                        : 0,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
                elements: {
                  line: {
                    borderWidth: 2,
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                  },
                },
              }}
            />
          }
        />
      </CCol>
      <CCol sm={6} lg={3}>
        <CWidgetStatsA
          className="chartWidgetCard bg-warning-gradient mb-4"
          color="warning-gradient"
          value={
            <>
              <span className="chartWidgetCount">
                {props.leadActivity?.leadFormsReceivedCount}
              </span>
            </>
          }
          title="Lead Forms Received"
          chart={
            <CChartLine
              className="mt-3"
              style={{ height: "70px" }}
              data={{
                labels:
                  props.chartDealActivity?.chartLeadFormsReceivedCount !=
                  undefined
                    ? Object.keys(
                        props.chartDealActivity?.chartLeadFormsReceivedCount
                      )
                    : 0,
                datasets: [
                  {
                    label: "Lead Forms Recieved",
                    backgroundColor: "transparent",
                    borderColor: "rgba(255,255,255,.55)",
                    data:
                      props.chartDealActivity?.chartLeadFormsReceivedCount !=
                      undefined
                        ? Object.values(
                            props.chartDealActivity?.chartLeadFormsReceivedCount
                          )
                        : 0,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
                elements: {
                  line: {
                    borderWidth: 2,
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                  },
                },
              }}
            />
          }
        />
      </CCol>
      <CCol sm={6} lg={3}>
        <CWidgetStatsA
          className="chartWidgetCard bg-danger-gradient mb-4"
          color="danger-gradient"
          value={
            <>
              <span className="chartWidgetCount">
                {props.leadActivity?.rejectedDeals}
              </span>
            </>
          }
          title="Rejected Deals"
          chart={
            <CChartLine
              className="mt-3"
              style={{ height: "70px" }}
              data={{
                labels:
                  props.chartDealActivity?.chartRejectedDeals != undefined
                    ? Object.keys(props.chartDealActivity?.chartRejectedDeals)
                    : 0,
                datasets: [
                  {
                    label: "Rejected Deals",
                    backgroundColor: "transparent",
                    borderColor: "rgba(255,255,255,.55)",
                    data:
                      props.chartDealActivity?.chartRejectedDeals != undefined
                        ? Object.values(
                            props.chartDealActivity?.chartRejectedDeals
                          )
                        : 0,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
                elements: {
                  line: {
                    borderWidth: 2,
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                  },
                },
              }}
            />
          }
        />
      </CCol>
      <CCol sm={6} lg={3}>
        <CWidgetStatsA
          className="chartWidgetCard bg-success-gradient mb-4"
          color="success-gradient"
          value={
            <>
              <span className="chartWidgetCount">
                {props.leadActivity?.completedDeals}
              </span>
            </>
          }
          title="Completed Deals"
          chart={
            <CChartLine
              className="mt-3"
              style={{ height: "70px" }}
              data={{
                labels:
                  props.chartDealActivity?.chartCompletedDeals != undefined
                    ? Object.keys(props.chartDealActivity?.chartCompletedDeals)
                    : 0,
                datasets: [
                  {
                    label: "Completed Deals",
                    backgroundColor: "transparent",
                    borderColor: "rgba(255,255,255,.55)",
                    data:
                      props.chartDealActivity?.chartCompletedDeals != undefined
                        ? Object.values(
                            props.chartDealActivity?.chartCompletedDeals
                          )
                        : 0,
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                maintainAspectRatio: false,
                scales: {
                  x: {
                    display: false,
                  },
                  y: {
                    display: false,
                  },
                },
                elements: {
                  line: {
                    borderWidth: 2,
                    tension: 0.4,
                  },
                  point: {
                    radius: 0,
                    hitRadius: 10,
                    hoverRadius: 4,
                  },
                },
              }}
            />
          }
        />
      </CCol>
    </CRow>
  );
};
export default DealActivity;
