import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Button, Col, Modal, Radio, Row, Tooltip } from "antd";
import { CheckOutlined, CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import QRCode from "react-qr-code";
import { saveAsPng } from "save-html-as-image";
import _ from "lodash";
import SvgClose from "../../../images/close";
import SvgPlusCircle from "../../../images/plusCircle";
import SvgSuccess from "../../../images/success";
import DashboardSendLeadEmail from "./Email";
import DashboardSendLeadText from "./Text";
import AuthContext from "../../../store/authContext";
import RefreshContext from "../../../store/refreshContext";
import { Link } from "react-router-dom";
const LINK_TYPE_TEXT = "text";
const LINK_TYPE_EMAIL = "email";

const DashboardSendLink = React.memo(() => {
  const [linkType, setLinkType] = useState(LINK_TYPE_TEXT);
  const [isLoading, setLoading] = useState(false);
  const [successText, setSuccessText] = useState();
  const [isVisible, setVisible] = useState(false);
  const [profile, setProfile] = useState();
  const [isCopied, setIsCopied] = useState(false);

  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);

  const onCancel = useCallback(() => {
    setLoading(false);
    setVisible(false);
  }, []);

  const onSendTeamClick = useCallback(() => {
    setSuccessText(null);
    setVisible(true);
  }, []);

  const onLinkTypeChange = useCallback((e) => {
    setLinkType(e.target.value);
  }, []);

  const onFinish = useCallback(
    (data) => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
      setSuccessText("Appraisal form send successfully.");
      refreshCtx.onRefresh();
    },
    [linkType]
  );

  const isButtonDisabled = false;
  let sendButton = (
    <Button
      block
      className="button-primary button-large-text button-dark-blue AddTeamMemberBtn"
      disabled={isButtonDisabled}
      htmltype="button"
      onClick={onSendTeamClick}
      type="primary"
    >
      <SvgPlusCircle /> Send Link
    </Button>
  );

  if (isButtonDisabled) {
    sendButton = (
      <Tooltip title="Please update your profile before sending a sub-affiliate form">
        {sendButton}
      </Tooltip>
    );
  }

  const downloadQrPng = () => {
    const qrNode = document.getElementById("qrNode");
    saveAsPng(qrNode, { filename: "iLusso-buyers-share-qr" });
  };

  return (
    <>
      {(authCtx.userData?.user_type == 0 ||
        authCtx.userData?.allowAll == 1 ||
        authCtx.userData?.permissions.includes(
          "my_deals_send_appraisal_form"
        )) &&
        sendButton}
      <Modal
        destroyOnClose
        closeIcon={<SvgClose />}
        footer={null}
        onCancel={onCancel}
        visible={isVisible}
        width={390}
      >
        {successText ? (
          <>
            <Row justify="center">
              <SvgSuccess />
            </Row>
            <h3 className="ds-title">Lead Form Sent</h3>
            <p className="text-center">
              A lead form has been sent to {successText}
            </p>
            <Row justify="center">
              <Button
                block
                className="button-primary"
                htmltype="submit"
                loading={isLoading}
                onClick={onCancel}
                type="primary"
              >
                Close
              </Button>
            </Row>
          </>
        ) : (
          <>
            <h3 className="ds-title">Send Link</h3>
            <Row className="color-base" justify="center">
              <Col className="text-center padding-bottom-1_5">
                Click on below button to copy an iLusso vehicle information form
                short link.
              </Col>
            </Row>
            <CopyToClipboard
              text={`https://ilusso.com/inquiry/${authCtx?.userData?.id}`}
              onCopy={() => setIsCopied(true)}
            >
              <div className="formCopyLinkWrap">
                <span>{`https://ilusso.com/inquiry/${authCtx?.userData?.id}`}</span>
                <button type="button">
                  {isCopied ? <CheckOutlined /> : <CopyOutlined />}
                </button>
              </div>
            </CopyToClipboard>
            <div className="text-center">
              <div id="qrNode">
                <QRCode
                  value={`https://ilusso.com/inquiry/${authCtx?.userData?.id}`}
                />
              </div>
            </div>
            <div className="formbtn">
              <button
                type="button"
                className="input-btn"
                onClick={() => downloadQrPng()}
              >
                Download QR Code
              </button>
            </div>
            {/* <Row className="padding-bottom-1_5" justify="center">
              <Radio.Group
                buttonStyle="solid"
                onChange={onLinkTypeChange}
                value={linkType}
              >
                <Radio.Button value={LINK_TYPE_TEXT}>Text</Radio.Button>
                <Radio.Button value={LINK_TYPE_EMAIL}>Email</Radio.Button>
              </Radio.Group>
            </Row>

            {linkType === LINK_TYPE_EMAIL && (
              <DashboardSendLeadEmail
                isLoading={isLoading}
                onFinish={onFinish}
              />
            )}
            {linkType === LINK_TYPE_TEXT && (
              <DashboardSendLeadText
                isLoading={isLoading}
                onFinish={onFinish}
              />
            )} */}
          </>
        )}
      </Modal>
    </>
  );
});

export default DashboardSendLink;
