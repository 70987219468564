import React, { useContext, useEffect, useState } from "react";
import { Spin } from "antd";
import _ from "lodash";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";
import moment from "moment";

import LayoutsLoggedIn from "../Layout/LoggedIn";
import AuthContext from "../../store/authContext";
import api from "../../config/api";
import RefreshContext from "../../store/refreshContext";

const SmsHistoryBuyer = React.memo(() => {
  const [responseData, setResponseData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 1,
    current: 1,
  });

  const refreshCtx = useContext(RefreshContext);
  useEffect(() => {
    fetchSmsHistory({}, 1);
  }, [refreshCtx.refresh]);
  const authCtx = useContext(AuthContext);

  async function fetchSmsHistory(formData, page) {
    if (page > pagination.current) {
      formData.next_page_uri = responseData?.next_page_uri;
    } else {
      formData.previous_page_uri = responseData?.previous_page_uri;
    }
    api
      .post(
        `smstemplate/getsmsbuyersList?buyer_id=${authCtx.userData?.id}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponseData(response.data.data);
          setPagination({
            total_records: response.data.data.total_records,
            total_pages: response.data.data.total_pages,
            current: response.data.data.page,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  const onPaginationNextFetch = (value) => {
    fetchSmsHistory({}, value + 1);
  };

  const onPaginationPreviousFetch = (value) => {
    fetchSmsHistory({}, value - 1);
  };

  return (
    <LayoutsLoggedIn>
      <div className="d-flex justify-content-between align-items-center mt-5">
        <h2 className="mb-0">SMS History</h2>
      </div>
      <div className="common-table-wrapper">
        <div className="ant-table">
          <CTable className="table-layout">
            <CTableHead className="ant-table-thead">
              <CTableRow>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  From
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  To
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Body
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Status
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Date
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="ant-table-tbody">
              {isLoading && (
                <>
                  <CTableRow className="text-center">
                    <CTableDataCell className="ant-table-cell" colSpan={5}>
                      {<Spin />}
                    </CTableDataCell>
                  </CTableRow>
                </>
              )}
              {responseData?.sms_messages &&
              responseData?.sms_messages.length != 0 &&
              !isLoading ? (
                responseData?.sms_messages.map((item, key) => (
                  <CTableRow key={key}>
                    <CTableDataCell className="ant-table-cell">
                      {item.from}
                    </CTableDataCell>
                    <CTableDataCell className="ant-table-cell">
                      {item.to}
                    </CTableDataCell>
                    <CTableDataCell className="ant-table-cell">
                      {item.body}
                    </CTableDataCell>
                    <CTableDataCell className="ant-table-cell text-capitalize">
                      {item.status}
                    </CTableDataCell>
                    <CTableDataCell className="ant-table-cell">
                      {moment(item.created_at).utc().format("M/D/YY")}
                    </CTableDataCell>
                  </CTableRow>
                ))
              ) : (
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={6}>
                    No Data Found.
                  </CTableDataCell>
                </CTableRow>
              )}
            </CTableBody>
          </CTable>
        </div>
        {pagination.total_records != undefined && (
          <>
            <ul className="ant-pagination ant-table-pagination ant-table-pagination-right">
              <li className="ant-pagination-total-text">
                {pagination.total_records} items
              </li>
              <ul>
                <li className="ant-pagination-prev">
                  <a
                    className=""
                    tabIndex="0"
                    role="button"
                    aria-disabled="false"
                    aria-label="Previous page"
                    rel="prev"
                    onClick={() =>
                      onPaginationPreviousFetch(pagination.current)
                    }>
                    &lt;
                  </a>
                </li>
                <li className="ant-pagination-item ant-pagination-item-active">
                  <a
                    rel="canonical"
                    role="button"
                    tabIndex="-1"
                    aria-current="page">
                    {pagination.current + 1}
                  </a>
                </li>
                {pagination.total_pages !== pagination.current && (
                  <li className="ant-pagination-next">
                    <a
                      className=""
                      tabIndex="0"
                      role="button"
                      aria-disabled="false"
                      aria-label="Next page"
                      rel="next"
                      onClick={() => onPaginationNextFetch(pagination.current)}>
                      &gt;
                    </a>
                  </li>
                )}
              </ul>
            </ul>
          </>
        )}
      </div>
    </LayoutsLoggedIn>
  );
});

export default SmsHistoryBuyer;
