import React from "react";
import { CRow, CCol } from "@coreui/react";
import _ from "lodash";
import CommonDrawerSection from "../../Common/Drawer/Section";
import CommonDrawerSectionHeader from "../../Common/Drawer/SectionHeader";
import CommonReferralUser from "../../Common/ReferralUser";
import CommonDrawerInfo from "../../Common/Drawer/Info";

const OffersDetailsBuyer = React.memo(({ data }) => {
  return (
    <CommonDrawerSection>
      <CommonDrawerSectionHeader title="Buyer" />
      <CRow>
        <CCol sm={12} lg={12}>
          <CommonReferralUser buyerData={data} />
        </CCol>
        <CCol sm={12} lg={12}>
          <CommonDrawerInfo title="Email">
            <a href={`mailto:${data.userEmail}`}>{data.userEmail}</a>
          </CommonDrawerInfo>
          <CommonDrawerInfo title="Status:">
            <span
              className={`user-status-${
                data.userStatus == 1 ? "active" : "suspended"
              }`}
            >
              {data.userStatus == 1 ? "Active" : "Suspended"}
            </span>
          </CommonDrawerInfo>
        </CCol>
        <CCol sm={12} lg={12}>
          <CommonDrawerInfo title="Phone Number:">
            {data.userMobileNo}
          </CommonDrawerInfo>
        </CCol>
      </CRow>
    </CommonDrawerSection>
  );
});

export default OffersDetailsBuyer;
