import React, { useContext, useEffect, useState } from "react";

import { Col, message, Row, Switch } from "antd";
import { CRow, CCol } from "@coreui/react";
import "./index.scss";
import { useParams } from "react-router";
import CommonDrawerInfo from "../../Common/Drawer/Info";
import CommonDrawerSectionHeader from "../../Common/Drawer/SectionHeader";
import DealActivity from "./DealActivity";
import PaymentHistory from "./PaymentHistory";
import PaymentTable from "./PaymentTable";
import FilesTable from "./FilesTable";
import LeadStat from "./LeadStat";
import FinanceActivity from "./FinanceActivity";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import LayoutsLoggedIn from "../../Layout/LoggedIn";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";
import routes from "../../../config/routes";
import RefreshContext from "../../../store/refreshContext";
import Team from "./Team";
import { formatPhoneNumber } from "../../../utils/formats";
import LeadHistory from "./LeadHistory";
import SmsHistory from "./SmsHistory";
import { useForm } from "react-hook-form";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const BuyerDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);
  useEffect(() => {
    if (authCtx.userData?.user_type != 0) {
      navigate(routes.Dashboard);
    }
  }, [authCtx]);

  useEffect(() => {
    if (id) {
      fetchBuyerDetail();
    }
  }, [id, refreshCtx.refresh]);

  const [isLoading, setLoading] = useState(true);
  const [buyerDetail, setBuyerDetail] = useState({});
  const [isAffiliate, setIsAffiliate] = useState(false);
  async function fetchBuyerDetail() {
    setLoading(true);
    api
      .post(
        `buyers/get-buyer`,
        { user_id: id },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setBuyerDetail(response.data.data);
          if (response.data.data?.is_affiliate == 1) {
            setIsAffiliate(true);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  const { register, getValues } = useForm();
  const onSaveClick = async () => {
    let formData = getValues();
    formData.user_id = id;
    await api
      .post(`buyers/addnote`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          message.success(response.data?.message);
        } else {
          message.error(response.data?.message);
        }
      })
      .catch((error) => {
        message.error(
          "Sorry we are not able to save data at this time. Please try again later."
        );
        setLoading(false);
      });
  };

  const affiliateToggle = async (is_affiliate) => {
    await api
      .post(
        `buyers/update-buyer-affliate`,
        {
          user_id: id,
          is_affiliate: is_affiliate ? "1" : "0",
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          message.success(response.data?.message);
        } else {
          message.error(response.data?.message);
        }
      })
      .catch((error) => {
        message.error(
          "Sorry we are not able to save data at this time. Please try again later."
        );
        setLoading(false);
      });
    setIsAffiliate(!isAffiliate);
  };

  return (
    <LayoutsLoggedIn>
      <div className="buyersLastLogin">
        <div className="row align-items-center">
          <div className="col-lg">
            <div className="d-flex align-items-center flex-wrap">
              <h2>Buyer - {buyerDetail?.name}</h2>
              <span className="title">
                Last Login&nbsp;
                {buyerDetail?.last_login}
              </span>
            </div>
          </div>
          <div className="col-lg-auto">
            <div className="my-lg-5 my-5">
              Buyer Status:
              <span
                className={`ms-3 leadStatusSelectBox offer-status-${
                  buyerDetail?.status == 1 ? "2" : "3"
                }`}>
                {buyerDetail?.status == 1 ? "Active" : "Suspended"}
              </span>
            </div>
          </div>
          <div className="col-lg-auto">
            <div className="d-flex align-items-center">
              <span className="title ms-0">Allow to Affiliate Program</span>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={() => affiliateToggle(!isAffiliate)}
                checked={isAffiliate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="offers-details-data-group buyersData">
        <CRow>
          <CCol sm={6} lg={3}>
            <CommonDrawerInfo title="Full Name:">
              {buyerDetail?.name}
            </CommonDrawerInfo>
          </CCol>
          <CCol sm={6} lg={3}>
            <CommonDrawerInfo title="Email Address:">
              {buyerDetail?.email}
            </CommonDrawerInfo>
          </CCol>
          <CCol sm={6} lg={3}>
            <CommonDrawerInfo title="Referral Id:">
              {buyerDetail?.referral_id}
            </CommonDrawerInfo>
          </CCol>
          <CCol sm={6} lg={3}>
            <CommonDrawerInfo title="Phone Number:">
              {formatPhoneNumber(buyerDetail?.mobile_no).formatted}
            </CommonDrawerInfo>
          </CCol>
          <CCol sm={6} lg={3}>
            <CommonDrawerInfo title="Address:">
              {buyerDetail?.address}
            </CommonDrawerInfo>
          </CCol>
          <CCol sm={6} lg={3}>
            <CommonDrawerInfo title="Address 2:">
              {buyerDetail?.address2}
            </CommonDrawerInfo>
          </CCol>
          <CCol sm={6} lg={2}>
            <CommonDrawerInfo title="City:">
              {buyerDetail?.city}
            </CommonDrawerInfo>
          </CCol>
          <CCol sm={6} lg={2}>
            <CommonDrawerInfo title="State:">
              {buyerDetail?.state}
            </CommonDrawerInfo>
          </CCol>
          <CCol sm={6} lg={2}>
            <CommonDrawerInfo title="Zipcode:">
              {buyerDetail?.zip_code}
            </CommonDrawerInfo>
          </CCol>
        </CRow>
      </div>

      <LeadStat leadActivity={buyerDetail?.leadActivity}></LeadStat>
      {buyerDetail?.chartDealActivity != undefined && (
        <DealActivity
          leadActivity={buyerDetail?.leadActivity}
          chartDealActivity={buyerDetail?.chartDealActivity}></DealActivity>
      )}
      {buyerDetail?.chartFinanceActivity != undefined && (
        <FinanceActivity
          financeActivity={buyerDetail?.financeActivity}
          chartFinanceActivity={
            buyerDetail?.chartFinanceActivity
          }></FinanceActivity>
      )}
      <LeadHistory id={id} />
      <div className="my-4">
        <div className="row">
          <div className="col-lg-5">
            <h2>Buyer Note</h2>
            <div className="formcol">
              <textarea
                rows={3}
                className="input-text"
                placeholder="Note"
                defaultValue={buyerDetail?.note}
                {...register("note")}></textarea>
            </div>
            <div className="formbtn">
              <button
                type="submit"
                className="input-btn w-auto"
                onClick={() => onSaveClick()}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      {buyerDetail?.myTeam != undefined && (
        <Team
          data={buyerDetail?.myTeam}
          id={id}
          hashId={buyerDetail?.hashId}
          buyerName={buyerDetail?.name}></Team>
      )}
      <div className="offers-details-data-group">
        <Row gutter={24}>
          <Col span={24}>
            <SmsHistory id={id} />
          </Col>
        </Row>
      </div>
      <div className="offers-details-data-group">
        <Row gutter={24}>
          <Col span={24}>
            <PaymentHistory id={id} />
            <PaymentTable id={id} />
          </Col>
        </Row>
      </div>
      <div className="offers-details-data-group">
        <Row gutter={24}>
          <Col span={24}>
            <FilesTable data={buyerDetail?.w9FileHistory} />
          </Col>
        </Row>
      </div>
    </LayoutsLoggedIn>
  );
};

export default BuyerDetail;
