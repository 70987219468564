import React from "react";

const SvgTemplate = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M20.27,21.9H3.73a.75.75,0,0,1-.44-.78q0-9.15,0-18.3c0-.51.21-.72.72-.72H20c.54,0,.74.2.74.74q0,9.14,0,18.28A.75.75,0,0,1,20.27,21.9ZM4.47,5.63V20.72H19.53V5.63Zm0-2.36V4.45H19.53V3.27Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M12,10.3H6.31a.6.6,0,0,1-.69-.68V7.45a.59.59,0,0,1,.66-.67H17.72a.59.59,0,0,1,.66.65V9.64a.59.59,0,0,1-.67.66ZM17.21,8H6.8V9.13H17.21Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M5.62,15.51V12.13a.59.59,0,0,1,.68-.67h5.6a.6.6,0,0,1,.68.67v6.78a.6.6,0,0,1-.67.67H6.29a.6.6,0,0,1-.67-.67Zm1.17-2.88V18.4h4.62V12.63Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M16.06,14.94H14.4a.59.59,0,0,1-.66-.65V12.1a.58.58,0,0,1,.65-.64h3.34a.59.59,0,0,1,.65.64v2.2a.59.59,0,0,1-.64.64Zm-1.14-2.32v1.15h2.29V12.62Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M16.06,16.1h1.68a.58.58,0,0,1,.64.63V19a.58.58,0,0,1-.63.63H14.37a.58.58,0,0,1-.63-.63V16.71a.57.57,0,0,1,.62-.61Zm1.15,1.17H14.92v1.14h2.29Z"
    />
  </svg>
);

export default SvgTemplate;
