import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, message, Modal, Row, Spin, Tag } from "antd";
import _ from "lodash";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";
import moment from "moment";

import AuthContext from "../../store/authContext";
import api from "../../config/api";
import RefreshContext from "../../store/refreshContext";
import { useForm } from "react-hook-form";
import SvgClose from "../../images/close";
import SvgSuccess from "../../images/success";
import LayoutsLoggedIn from "../Layout/LoggedIn";

const Mailbox = React.memo(() => {
  const [responseData, setResponseData] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(true);
  const [replyId, setReplyId] = useState("");

  const [successText, setSuccessText] = useState();
  const [isVisible, setVisible] = useState(false);

  const refreshCtx = useContext(RefreshContext);
  useEffect(() => {
    fetchSmsHistory({}, 1);
  }, [refreshCtx.refresh]);
  const authCtx = useContext(AuthContext);

  async function fetchSmsHistory(formData, page) {
    api
      .post(`smstemplate/getsmsownerList`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          let rawData = [];
          for (const key in response.data.data?.sms_messages) {
            rawData.push(response.data.data?.sms_messages[key]);
          }
          setResponseData(rawData);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onCancel = useCallback(() => {
    setVisible(false);
    reset();
  }, []);

  const onSendSmsClick = useCallback((id) => {
    setSuccessText(null);
    setVisible(true);
    getTemplates();
    setReplyId(id);
  }, []);

  const getTemplates = async () => {
    await api
      .post(
        `smstemplate`,
        { user_id: authCtx.userData?.id, per_page: 100, is_active: 1 },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          let templatesArray = [];
          if (response.data.data.lead.length > 0) {
            response.data.data.lead.map((element) => {
              templatesArray.push({
                value: element.id,
                label: element.name,
              });
            });
            setTemplates(templatesArray);
          } else {
            setTemplates([]);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const getTemplateContent = async (id) => {
    await api
      .post(
        `smstemplate/get-smstemplate`,
        {
          p_id: id,
          user_id: authCtx.userData?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx?.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setValue("content", response.data.data?.content);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    await api
      .post(
        `smstemplate/sendsmsbuyers`,
        {
          buyer_id: [replyId],
          content: formData.content,
          send_time: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          onCancel();
          refreshCtx.onRefresh();
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  return (
    <LayoutsLoggedIn>
      <div>
        <h2>Mail Box</h2>
      </div>
      <Modal
        destroyOnClose
        closeIcon={<SvgClose />}
        footer={null}
        onCancel={onCancel}
        visible={isVisible}
        width={390}
      >
        {successText ? (
          <>
            <Row justify="center">
              <SvgSuccess />
            </Row>
            <h3 className="ds-title">SMS Sended Successfully!</h3>
            <p className="text-center">
              A team invite has been sent to {successText}
            </p>
            <Row justify="center">
              <Button
                block
                className="button-primary"
                htmltype="submit"
                loading={isLoading}
                onClick={onCancel}
              >
                Close
              </Button>
            </Row>
          </>
        ) : (
          <>
            <h3 className="ds-title">Send SMS</h3>
            <Row className="color-base" justify="center">
              <Col className="text-center padding-bottom-1_5">
                Please select sms template or select custom template option to
                edit default.
              </Col>
            </Row>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="formcol">
                <label className="input-label">Template</label>
                <select
                  className="input-select"
                  onChange={(e) => getTemplateContent(e.target.value)}
                >
                  <option>Select Template</option>
                  {_.map(templates, (template) => (
                    <option key={template.value} value={template.value}>
                      {template.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formcol">
                <label className="input-label">Content</label>
                <textarea
                  className="input-text"
                  placeholder="Enter content here"
                  {...register("content", { required: true })}
                ></textarea>
                {errors.content?.type === "required" && (
                  <span className="formError">This field is required</span>
                )}
              </div>
              <div className="formbtn">
                <button type="submit" className="input-btn">
                  Send Now
                </button>
              </div>
            </form>
          </>
        )}
      </Modal>
      <div className="common-table-wrapper">
        <div className="ant-table">
          <CTable className="table-layout">
            <CTableHead className="ant-table-thead">
              <CTableRow>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Name
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Phone
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Message
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Date
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Action
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="ant-table-tbody">
              {isLoading && (
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={12}>
                    <Spin />
                  </CTableDataCell>
                </CTableRow>
              )}
              {!isLoading && responseData.length == 0 && (
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={7}>
                    No Data Found.
                  </CTableDataCell>
                </CTableRow>
              )}
              {responseData.map((item, key) => (
                <CTableRow key={key}>
                  <CTableDataCell className="ant-table-cell">
                    {item?.buyername}
                  </CTableDataCell>
                  <CTableDataCell className="ant-table-cell">
                    {item?.buyerphone}
                  </CTableDataCell>
                  <CTableDataCell className="ant-table-cell">
                    {item?.body}
                  </CTableDataCell>
                  <CTableDataCell className="ant-table-cell">
                    {moment(item?.date_sent).utc().format("M/D/YY")}
                  </CTableDataCell>
                  <CTableDataCell className="ant-table-cell">
                    <button
                      className="ant-btn ant-btn-primary"
                      onClick={() => onSendSmsClick(item?.buyerid)}
                    >
                      Reply
                    </button>
                  </CTableDataCell>
                </CTableRow>
              ))}
            </CTableBody>
          </CTable>
        </div>
      </div>
    </LayoutsLoggedIn>
  );
});

export default Mailbox;
