import React from 'react';

const SvgSuccess = props => (
  <svg width={80} height={80} viewBox="0 0 80 80" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
      fill="#3DD598"
    />
    <path
      d="M30.1075 37.8353L36.9208 44.5609L49.686 29.0907C50.7405 27.8128 52.6313 27.6316 53.9093 28.6861C55.1872 29.7406 55.3684 31.6314 54.3139 32.9094L39.4614 50.9093C38.3398 52.2685 36.2939 52.3729 35.0399 51.135L25.8925 42.1053C24.7133 40.9413 24.701 39.0419 25.865 37.8628C27.0289 36.6836 28.9284 36.6713 30.1075 37.8353Z"
      fill="#FAFAFB"
    />
  </svg>
);

export default SvgSuccess;
