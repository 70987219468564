import React from 'react';

const CommonDrawerInfo = React.memo(({ children, title }) => (
  <>
    <div className="commonDrawerGroup">
      <div className="common-drawer-info-title">{title}</div>
      <div className="common-drawer-info-value">{children}</div>
    </div>
  </>
));

export default CommonDrawerInfo;
