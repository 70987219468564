import React from "react";

const SvgPages = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M19.46,7.11c-.47-.46-.94-.93-1.4-1.4L16.89,4.55l-2.3-2.28A1,1,0,0,0,14,2C11.13,2,8.91,2,6.82,2a2.62,2.62,0,0,0-1.08.24A2.48,2.48,0,0,0,4.23,4.66v9c0,1.89,0,3.78,0,5.67a3.2,3.2,0,0,0,.14,1A2.55,2.55,0,0,0,6.91,22H17.09a2.56,2.56,0,0,0,2.67-2.67v-4.6c0-2.31,0-4.61,0-6.91A.94.94,0,0,0,19.46,7.11ZM15.92,7a1.14,1.14,0,0,1-1.18-1.19c0-.41,0-.83,0-1.24V4.47L17.25,7Zm2.41,12.3a1.14,1.14,0,0,1-.87,1.23,1.69,1.69,0,0,1-.38,0H6.93a1.16,1.16,0,0,1-1.27-1.26V4.68A1.16,1.16,0,0,1,6.94,3.41H13.3v2.4a2.51,2.51,0,0,0,.35,1.34,2.54,2.54,0,0,0,2.27,1.29h2.41Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M17.25,17.83a.71.71,0,0,1-.62.65h-9a1.31,1.31,0,0,1-.34,0,.71.71,0,0,1-.54-.73.72.72,0,0,1,.64-.66h9.07a.83.83,0,0,1,.61.24A.72.72,0,0,1,17.25,17.83Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M17.25,14.26c0,.21-.09.71-.89.71h-9a.72.72,0,0,1-.64-.7.71.71,0,0,1,.63-.71h9a.94.94,0,0,1,.7.24A.66.66,0,0,1,17.25,14.26Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M13.22,10.75a.73.73,0,0,1-.77.71H7.52a.8.8,0,0,1-.63-.3.69.69,0,0,1-.11-.61A.74.74,0,0,1,7.5,10h4.95a.83.83,0,0,1,.58.24A.65.65,0,0,1,13.22,10.75Z"
    />
  </svg>
);

export default SvgPages;
