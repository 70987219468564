import React from "react";
import "../../styles/HomeNewAlt.css";
import "../../styles/responsive.css";

import { Layout } from "antd";

import { Link } from "react-router-dom";
import routes from "../../config/routes";

const { Content, Footer, Header } = Layout;

const LayoutsHomeAlt = ({ children }) => {
  return (
    <div>
      <div className="wrapper">
        <header className="headerMain">
          <div className="container-fluid">
            <div className="row align-items-center justify-content-between">
              <div className="col-auto">
                <a className="siteLogo" href={routes.Home} title="ilusso">
                  <img src="/images/logo.png" alt="ilusso" />
                </a>
              </div>
              <div className="col-auto">
                <a
                  className="d-none d-md-inline-block btn btn-red head-btn"
                  href={routes.SignupThirdParty}
                  target="_blank"
                  title="Apply Now"
                >
                  Apply Now
                </a>
              </div>
            </div>
          </div>
        </header>
        <Content>{children}</Content>
        <Footer>Copyright &copy; iLusso. All Rights Reserved.</Footer>
      </div>
    </div>
  );
};

export default LayoutsHomeAlt;
