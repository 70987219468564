import React from "react";
import _ from "lodash";
import moment from "moment";

const LeadHistory = React.memo(({ data }) => {
  return (
    <div>
      <h5 className="boxTitle">Lead History</h5>
      <div className="leadHistoryWrap">
        {data.leadHistory &&
          data.leadHistory.map((index) => (
            <div key={index.id} className="leadHistoryRow">
              <span className="leadHistoryStatus">{index.description}</span>
              <span className="leadHistoryTime">
                {moment(index.created_at).utc().format("DD/MM/YYYY hh:mm A")}
                &nbsp; CST
              </span>
            </div>
          ))}
      </div>
    </div>
  );
});

export default LeadHistory;
