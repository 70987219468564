import React, { useCallback, useContext, useState } from "react";

import { Button, Modal, Radio, Row, Tooltip } from "antd";
import _ from "lodash";
import SvgClose from "../../../images/close";
import SvgPlusCircle from "../../../images/plusCircle";
import SvgSuccess from "../../../images/success";

import TeamAddMemberEmail from "../AddMember/Email";
import TeamAddMemberText from "../AddMember/Text";
import RefreshContext from "../../../store/refreshContext";
import AuthContext from "../../../store/authContext";

const LINK_TYPE_TEXT = "text";
const LINK_TYPE_EMAIL = "email";

const TeamSendLink = React.memo(() => {
  const [linkType, setLinkType] = useState(LINK_TYPE_TEXT);
  const [isLoading, setLoading] = useState(false);
  const [successText, setSuccessText] = useState();
  const [isVisible, setVisible] = useState(false);
  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);

  const onCancel = useCallback(() => {
    setLoading(false);
    setVisible(false);
  }, []);

  const onSendTeamClick = useCallback(() => {
    setSuccessText(null);
    setVisible(true);
  }, []);

  const onLinkTypeChange = useCallback((e) => {
    setLinkType(e.target.value);
  }, []);

  const onFinish = useCallback(
    (buyerEmail) => {
      setLoading(false);
      refreshCtx.onRefresh();
      setSuccessText(buyerEmail);
    },
    [linkType]
  );

  const isButtonDisabled = false;
  let sendButton = (
    <Button
      block
      className="button-primary button-large-text AddTeamMemberBtn"
      disabled={isButtonDisabled}
      htmltype="button"
      onClick={onSendTeamClick}
      type="primary"
    >
      <SvgPlusCircle /> Add Team Member
    </Button>
  );

  if (isButtonDisabled) {
    sendButton = (
      <Tooltip title="Please update your profile before sending a sub-affiliate form">
        {sendButton}
      </Tooltip>
    );
  }

  return (
    <>
      {(authCtx.userData?.user_type == 0 ||
        authCtx.userData?.allowAll == 1 ||
        authCtx.userData?.permissions.includes("my_teams_add")) &&
        sendButton}
      <Modal
        destroyOnClose
        closeIcon={<SvgClose />}
        footer={null}
        onCancel={onCancel}
        visible={isVisible}
        width={390}
      >
        {successText ? (
          <>
            <Row justify="center">
              <SvgSuccess />
            </Row>
            <h3 className="ds-title">Add Team Member</h3>
            <p className="text-center">
              A team invite has been sent to {successText}
            </p>
            <Row justify="center">
              <Button
                block
                className="button-primary"
                htmltype="submit"
                loading={isLoading}
                onClick={onCancel}
              >
                Close
              </Button>
            </Row>
          </>
        ) : (
          <>
            <h3 className="ds-title">Add Team Member</h3>
            <Row className="padding-bottom-1_5" justify="center">
              <Radio.Group
                buttonStyle="solid"
                onChange={onLinkTypeChange}
                value={linkType}
              >
                <Radio.Button value={LINK_TYPE_TEXT}>Text</Radio.Button>
                <Radio.Button value={LINK_TYPE_EMAIL}>Email</Radio.Button>
              </Radio.Group>
            </Row>

            {linkType === LINK_TYPE_EMAIL && (
              <TeamAddMemberEmail isLoading={isLoading} onFinish={onFinish} />
            )}
            {linkType === LINK_TYPE_TEXT && (
              <TeamAddMemberText isLoading={isLoading} onFinish={onFinish} />
            )}
          </>
        )}
      </Modal>
    </>
  );
});

export default TeamSendLink;
