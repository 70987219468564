import React, { useContext, useEffect, useState } from "react";
import { Spin } from "antd";
import _ from "lodash";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";

import ReactPaginate from "react-paginate";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";
import moment from "moment";

const LeadHistory = React.memo(({ id }) => {
  const [isLoading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("");
  const [perPage, setPerPage] = useState("10");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 1,
    current: 1,
  });

  const authCtx = useContext(AuthContext);
  useEffect(() => {
    let filterData = {
      per_page: perPage,
    };
    fetchDeals(filterData, 1);
  }, [perPage]);

  async function fetchDeals(formData, page) {
    formData.user_id = id;
    await api
      .post(`deals?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setOffers([]);
        if (response.data.status) {
          setOffers(response.data.data.lead);
          setPagination(response.data.data.paginate);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  const getList = (data) => {
    let content = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      content.push(
        <CTableRow key={item.id}>
          <CTableDataCell className="ant-table-cell">
            {item.leadName}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.vehicle_year}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.vehicle_make}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.vehicle_model}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.vehicle_trim}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.offer_comment || "\u2014"}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.status == 0 && (
              <span className="leadStatusSelectBox offer-status-0">New</span>
            )}
            {item.status == 1 && (
              <span className="leadStatusSelectBox offer-status-1">
                Working
              </span>
            )}
            {item.status == 2 && (
              <span className="leadStatusSelectBox offer-status-2">
                Approved
              </span>
            )}
            {item.status == 3 && (
              <span className="leadStatusSelectBox offer-status-3">
                Rejected
              </span>
            )}
            {item.status == 4 && (
              <span className="leadStatusSelectBox offer-status-4">
                Completed
              </span>
            )}
            {item.status == 5 && (
              <span className="leadStatusSelectBox offer-status-5">
                Consignment
              </span>
            )}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {moment(item.updated_at).utc().format("M/D/YY")}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            ${Number(item.commission != null ? item.commission : 0)}
            /$
            {Number(
              item.parent_commission != null ? item.parent_commission : 0
            )}
          </CTableDataCell>
        </CTableRow>
      );
    }
    return content;
  };

  function sort(field, currentField, sort, page) {
    let filterData = {
      per_page: perPage,
    };
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
          per_page: perPage,
        };
        fetchDeals(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
          per_page: perPage,
        };
        fetchDeals(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
        per_page: perPage,
      };
      fetchDeals(filterData, page);
    }
  }

  const perPageRecordHandler = (e) => {
    setPerPage(e.target.value);
  };

  const onPaginationFetch = (e) => {
    let filterData = {
      per_page: perPage,
    };
    fetchDeals(filterData, e.selected + 1);
  };

  return (
    <>
      <h2>Leads History</h2>
      <div className="common-table-wrapper">
        <div className="ant-table">
          <CTable className="table-layout">
            <CTableHead className="ant-table-thead">
              <CTableRow>
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col">
                  Owner Name
                  <span
                    onClick={(e) =>
                      sort("leadName", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "leadName" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="7%"
                  className="ant-table-cell"
                  scope="col">
                  Year
                  <span
                    onClick={(e) =>
                      sort(
                        "vehicle_year",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "vehicle_year" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col">
                  Make
                  <span
                    onClick={(e) =>
                      sort(
                        "vehicle_make",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "vehicle_make" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col">
                  Model
                  <span
                    onClick={(e) =>
                      sort(
                        "vehicle_model",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "vehicle_model" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col">
                  Trim
                  <span
                    onClick={(e) =>
                      sort(
                        "vehicle_trim",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "vehicle_trim" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="20%"
                  className="ant-table-cell"
                  scope="col">
                  Deal Comment
                  <span
                    onClick={(e) =>
                      sort(
                        "offer_comment",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "offer_comment" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col">
                  Status
                  <span
                    onClick={(e) =>
                      sort("status", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "status" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col">
                  Date
                  <span
                    onClick={(e) =>
                      sort(
                        "created_at",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "created_at" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col">
                  Commission
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="ant-table-tbody">
              {isLoading && (
                <>
                  <CTableRow className="text-center">
                    <CTableDataCell className="ant-table-cell" colSpan={12}>
                      {<Spin />}
                    </CTableDataCell>
                  </CTableRow>
                </>
              )}
              {!isLoading && offers.length == 0 && (
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={12}>
                    No Data Found.
                  </CTableDataCell>
                </CTableRow>
              )}
              {getList(offers)}
            </CTableBody>
          </CTable>
        </div>
        {pagination.total_records != undefined && (
          <>
            <ul className="ant-pagination ant-table-pagination ant-table-pagination-right">
              <li className="ant-pagination-total-text">
                {pagination.total_records} items
              </li>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={onPaginationFetch}
                forcePage={parseInt(pagination?.current - 1)}
                previousClassName="ant-pagination-prev"
                nextClassName="ant-pagination-next"
                pageRangeDisplayed={5}
                pageCount={pagination.total_pages}
                pageClassName="ant-pagination-item"
                previousLabel="<"
                activeClassName="ant-pagination-item-active"
                disabledClassName="ant-pagination-item ant-pagination-disabled"
                renderOnZeroPageCount={null}
              />
              <select className="input-select" onChange={perPageRecordHandler}>
                <option value="10">10 / Page</option>
                <option value="20">20 / Page</option>
                <option value="50">50 / Page</option>
                <option value="100">100 / Page</option>
              </select>
            </ul>
          </>
        )}
      </div>
    </>
  );
});

export default LeadHistory;
