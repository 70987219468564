import React, { useContext, useEffect, useState } from "react";
import { Spin } from "antd";
import _ from "lodash";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";

import ReactPaginate from "react-paginate";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";
import RefreshContext from "../../../store/refreshContext";

const PaymentTable = React.memo(({ id }) => {
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(true);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("");
  const [perPage, setPerPage] = useState("10");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 1,
    current: 1,
  });

  const refreshCtx = useContext(RefreshContext);
  useEffect(() => {
    fetchPaymentHistory({ per_page: perPage }, 1);
  }, [refreshCtx.refresh, perPage]);
  const authCtx = useContext(AuthContext);
  async function fetchPaymentHistory(formData, page) {
    formData.user_id = id;
    api
      .post(`payments?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setPaymentHistory(response.data.data.lead);
          setPagination(response.data.data.paginate);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  const getList = (data) => {
    let content = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      content.push(
        <CTableRow key={item.id}>
          <CTableDataCell className="ant-table-cell">
            {item.payment_date}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.payment_type}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.payout}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.payment_id}
          </CTableDataCell>
        </CTableRow>
      );
    }
    return content;
  };

  function sort(field, currentField, sort, page) {
    setLoading(true);
    setPaymentHistory([]);
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        fetchPaymentHistory(
          {
            field_name: field,
            order_by: "asc",
            per_page: perPage,
          },
          page
        );
      } else {
        setSortType("desc");
        fetchPaymentHistory(
          {
            field_name: field,
            order_by: "desc",
            per_page: perPage,
          },
          page
        );
      }
    } else {
      setSortField(field);
      setSortType("desc");
      fetchPaymentHistory(
        {
          field_name: field,
          order_by: "desc",
          per_page: perPage,
        },
        page
      );
    }
  }

  const perPageRecordHandler = (e) => {
    setPerPage(e.target.value);
  };

  const onPaginationFetch = (e) => {
    fetchPaymentHistory(
      { field_name: sortField, order_by: sortType, per_page: perPage },
      e.selected + 1
    );
  };

  return (
    <div className="common-table-wrapper">
      <div className="ant-table">
        <CTable className="table-layout">
          <CTableHead className="ant-table-thead">
            <CTableRow>
              <CTableHeaderCell className="ant-table-cell" scope="col">
                Payment Date
                <span
                  onClick={(e) =>
                    sort(
                      "payment_date",
                      sortField,
                      sortType,
                      pagination.current
                    )
                  }
                  className={`sortIcon ${
                    sortField == "payment_date" ? sortType : ""
                  }`}
                ></span>
              </CTableHeaderCell>
              <CTableHeaderCell className="ant-table-cell" scope="col">
                Payment Type
                <span
                  onClick={(e) =>
                    sort(
                      "payment_type",
                      sortField,
                      sortType,
                      pagination.current
                    )
                  }
                  className={`sortIcon ${
                    sortField == "payment_type" ? sortType : ""
                  }`}
                ></span>
              </CTableHeaderCell>
              <CTableHeaderCell className="ant-table-cell" scope="col">
                Total Payment
                <span
                  onClick={(e) =>
                    sort("payout", sortField, sortType, pagination.current)
                  }
                  className={`sortIcon ${
                    sortField == "payout" ? sortType : ""
                  }`}
                ></span>
              </CTableHeaderCell>
              <CTableHeaderCell className="ant-table-cell" scope="col">
                Payment Track ID
                <span
                  onClick={(e) =>
                    sort("payment_id", sortField, sortType, pagination.current)
                  }
                  className={`sortIcon ${
                    sortField == "payment_id" ? sortType : ""
                  }`}
                ></span>
              </CTableHeaderCell>
            </CTableRow>
          </CTableHead>
          <CTableBody className="ant-table-tbody">
            {isLoading && (
              <>
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={5}>
                    {<Spin />}
                  </CTableDataCell>
                </CTableRow>
              </>
            )}
            {!isLoading && paymentHistory.length == 0 && (
              <CTableRow className="text-center">
                <CTableDataCell className="ant-table-cell" colSpan={4}>
                  No Data Found.
                </CTableDataCell>
              </CTableRow>
            )}
            {getList(paymentHistory)}
          </CTableBody>
        </CTable>
      </div>
      {pagination.total_records != undefined && (
        <>
          <ul className="ant-pagination ant-table-pagination ant-table-pagination-right">
            <li className="ant-pagination-total-text">
              {pagination.total_records} items
            </li>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={onPaginationFetch}
              previousClassName="ant-pagination-prev"
              nextClassName="ant-pagination-next"
              pageRangeDisplayed={10}
              pageCount={pagination.total_pages}
              pageClassName="ant-pagination-item"
              previousLabel="<"
              activeClassName="ant-pagination-item-active"
              disabledClassName="ant-pagination-item ant-pagination-disabled"
              renderOnZeroPageCount={null}
            />
            <select className="input-select" onChange={perPageRecordHandler}>
              <option value="10">10 / Page</option>
              <option value="20">20 / Page</option>
              <option value="50">50 / Page</option>
              <option value="100">100 / Page</option>
            </select>
          </ul>
        </>
      )}
    </div>
  );
});

export default PaymentTable;
