import React from 'react';

import '../../styles/HomeNewAlt.css';
import '../../styles/responsive.css';

import { Layout } from 'antd';

import './Home.scss';

const { Content } = Layout;

const LayoutsHome = ({ children }) => (
  <Layout id="home" style={{ minHeight: '100vh' }}>
    <Content>{children}</Content>
  </Layout>
);

export default LayoutsHome;
