import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Col, message, Modal, Row, Spin, Tag } from "antd";
import _ from "lodash";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";
import moment from "moment";

import ReactPaginate from "react-paginate";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";
import RefreshContext from "../../../store/refreshContext";
import { useForm } from "react-hook-form";
import SvgClose from "../../../images/close";
import SvgSuccess from "../../../images/success";

const SmsHistory = React.memo(({ id }) => {
  const [responseData, setResponseData] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [error, setError] = useState();
  const [isLoading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 1,
    current: 1,
  });

  const [successText, setSuccessText] = useState();
  const [isVisible, setVisible] = useState(false);

  const refreshCtx = useContext(RefreshContext);
  useEffect(() => {
    fetchSmsHistory({}, 1);
  }, [refreshCtx.refresh]);
  const authCtx = useContext(AuthContext);

  async function fetchSmsHistory(formData, page) {
    if (page > pagination.current) {
      formData.next_page_uri = responseData?.next_page_uri;
    } else {
      formData.previous_page_uri = responseData?.previous_page_uri;
    }
    api
      .post(`smstemplate/getsmsbuyersList?buyer_id=${id}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setResponseData(response.data.data);
          setPagination({
            total_records: response.data.data.total_records,
            total_pages: response.data.data.total_pages,
            current: response.data.data.page,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  const onPaginationNextFetch = (value) => {
    fetchSmsHistory({}, value + 1);
  };

  const onPaginationPreviousFetch = (value) => {
    fetchSmsHistory({}, value - 1);
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const onCancel = useCallback(() => {
    setVisible(false);
    reset();
  }, []);

  const onSendSmsClick = useCallback(() => {
    setSuccessText(null);
    setVisible(true);
    getTemplates();
  }, []);

  const getTemplates = async () => {
    await api
      .post(
        `smstemplate`,
        { user_id: authCtx.userData?.id, per_page: 100, is_active: 1 },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          let templatesArray = [];
          if (response.data.data.lead.length > 0) {
            response.data.data.lead.map((element) => {
              templatesArray.push({
                value: element.id,
                label: element.name,
              });
            });
            setTemplates(templatesArray);
          } else {
            setTemplates([]);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const getTemplateContent = async (id) => {
    await api
      .post(
        `smstemplate/get-smstemplate`,
        {
          p_id: id,
          user_id: authCtx.userData?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx?.userData?.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setValue("content", response.data.data?.content);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    await api
      .post(
        `smstemplate/sendsmsbuyers`,
        {
          buyer_id: [id],
          content: formData.content,
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          onCancel();
          refreshCtx.onRefresh();
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-5">
        <h2 className="mb-0">SMS History</h2>
        <button
          className="ant-btn ant-btn-primary ms-auto"
          onClick={onSendSmsClick}
        >
          Reply
        </button>
      </div>
      <Modal
        destroyOnClose
        closeIcon={<SvgClose />}
        footer={null}
        onCancel={onCancel}
        visible={isVisible}
        width={390}
      >
        {successText ? (
          <>
            <Row justify="center">
              <SvgSuccess />
            </Row>
            <h3 className="ds-title">SMS Sended Successfully!</h3>
            <p className="text-center">
              A team invite has been sent to {successText}
            </p>
            <Row justify="center">
              <Button
                block
                className="button-primary"
                htmltype="submit"
                loading={isLoading}
                onClick={onCancel}
              >
                Close
              </Button>
            </Row>
          </>
        ) : (
          <>
            <h3 className="ds-title">Send SMS</h3>
            <Row className="color-base" justify="center">
              <Col className="text-center padding-bottom-1_5">
                Please select sms template or select custom template option to
                edit default.
              </Col>
            </Row>

            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="formcol">
                <label className="input-label">Template</label>
                <select
                  className="input-select"
                  onChange={(e) => getTemplateContent(e.target.value)}
                >
                  <option>Select Template</option>
                  {_.map(templates, (template) => (
                    <option key={template.value} value={template.value}>
                      {template.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="formcol">
                <label className="input-label">Content</label>
                <textarea
                  className="input-text"
                  placeholder="Enter content here"
                  {...register("content", { required: true })}
                ></textarea>
                {errors.content?.type === "required" && (
                  <span className="formError">This field is required</span>
                )}
              </div>
              <div className="formbtn">
                <button type="submit" className="input-btn">
                  Send Now
                </button>
              </div>
            </form>
          </>
        )}
      </Modal>
      <div className="common-table-wrapper">
        <div className="ant-table">
          <CTable className="table-layout">
            <CTableHead className="ant-table-thead">
              <CTableRow>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  From
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  To
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Body
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Status
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Date
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="ant-table-tbody">
              {isLoading && (
                <>
                  <CTableRow className="text-center">
                    <CTableDataCell className="ant-table-cell" colSpan={5}>
                      {<Spin />}
                    </CTableDataCell>
                  </CTableRow>
                </>
              )}
              {responseData?.sms_messages &&
              responseData?.sms_messages.length != 0 ? (
                responseData?.sms_messages.map((item, key) => (
                  <CTableRow key={key}>
                    <CTableDataCell className="ant-table-cell">
                      {item.from}
                    </CTableDataCell>
                    <CTableDataCell className="ant-table-cell">
                      {item.to}
                    </CTableDataCell>
                    <CTableDataCell className="ant-table-cell">
                      {item.body}
                    </CTableDataCell>
                    <CTableDataCell className="ant-table-cell text-capitalize">
                      {item.status}
                    </CTableDataCell>
                    <CTableDataCell className="ant-table-cell">
                      {moment(item.created_at).utc().format("M/D/YY")}
                    </CTableDataCell>
                  </CTableRow>
                ))
              ) : (
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={6}>
                    No Data Found.
                  </CTableDataCell>
                </CTableRow>
              )}
            </CTableBody>
          </CTable>
        </div>
        {pagination.total_records != undefined && (
          <>
            <ul className="ant-pagination ant-table-pagination ant-table-pagination-right">
              <li className="ant-pagination-total-text">
                {pagination.total_records} items
              </li>
              <ul>
                <li className="ant-pagination-prev">
                  <a
                    className=""
                    tabIndex="0"
                    role="button"
                    aria-disabled="false"
                    aria-label="Previous page"
                    rel="prev"
                    onClick={() =>
                      onPaginationPreviousFetch(pagination.current)
                    }
                  >
                    &lt;
                  </a>
                </li>
                <li className="ant-pagination-item ant-pagination-item-active">
                  <a
                    rel="canonical"
                    role="button"
                    tabIndex="-1"
                    aria-current="page"
                  >
                    {pagination.current + 1}
                  </a>
                </li>
                {pagination.total_pages !== pagination.current && (
                  <li className="ant-pagination-next">
                    <a
                      className=""
                      tabIndex="0"
                      role="button"
                      aria-disabled="false"
                      aria-label="Next page"
                      rel="next"
                      onClick={() => onPaginationNextFetch(pagination.current)}
                    >
                      &gt;
                    </a>
                  </li>
                )}
              </ul>
            </ul>
          </>
        )}
      </div>
    </>
  );
});

export default SmsHistory;
