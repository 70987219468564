import React, { useEffect, useState, useContext, useRef } from "react";
import { CRow, CCol } from "@coreui/react";
import _ from "lodash";
import CommonDrawerSection from "../../Common/Drawer/Section";
import CommonDrawerInfo from "../../Common/Drawer/Info";
import OfferDetailsEditHeader from "./EditHeader";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { message } from "antd";
import api from "../../../config/api";
import AuthContext from "../../../store/authContext";

const years = _.map(
  new Array(new Date().getFullYear() + 1 - 1995),
  (_value, idx) => ({
    label: new Date().getFullYear() + 1 - idx,
    value: new Date().getFullYear() + 1 - idx,
  })
);

const states = _.map(
  {
    AL: "Alabama",
    AK: "Alaska",
    AS: "American Samoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "Marshall Islands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PW: "Palau",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  },
  (label, value) => ({ label, value })
);

const OffersDetailsVehicle = React.memo(({ data, onUpdateLead }) => {
  const [isEditing, setEditing] = useState(false);
  const [isLoading, setLoading] = useState();
  const [isVisible, setVisible] = useState();
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [trims, setTrims] = useState([]);
  const [fieldLoading, setfieldLoading] = useState(false);
  const [autoFill, setAutoFill] = useState(true);
  const [autoFillMake, setAutoFillMake] = useState(true);
  const [listedForSale, setListedForSale] = useState(false);
  const countRef = useRef();
  const makeRef = useRef();
  const modelRef = useRef();
  const trimRef = useRef();

  const validationSchema = Yup.object().shape({
    state: Yup.string().required("This field is required"),
    vehicleVin: Yup.string()
      .required("This field is required")
      .min(17, "Please Enter Minimum 17 Characters.")
      .max(17, "Please Enter Maximum 17 Characters."),
    vehicleYear: Yup.string().required("This field is required"),
    vehicleMake: Yup.string().required("This field is required"),
    vehicleModel: Yup.string().required("This field is required"),
    vehicleTrim: Yup.string().required("This field is required"),
    vehicleMileage: Yup.string().required("This field is required"),
    vehicleExteriorColor: Yup.string().required("This field is required"),
    vehicleInteriorColor: Yup.string().required("This field is required"),
    listed_for_sale: Yup.boolean(),
    listed_url: Yup.string().when("listed_for_sale", {
      is: true,
      then: Yup.string().required("This field is required"),
    }),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    register,
    watch,
    trigger,
    setValue,
    getValues,
    formState: { errors },
  } = useForm(formOptions);

  const authCtx = useContext(AuthContext);

  const onSaveClick = async () => {
    setLoading(true);
    const isValidated = await trigger();
    if (isValidated) {
      let formData = getValues();
      let vehicleData = {
        deal_id: data.id,
        vehicle_year: formData.vehicleYear,
        vehicle_make: countRef.current
          ? _.toString(formData.vehicleMake)
          : _.toString(makeRef.current),
        vehicle_model: countRef.current
          ? _.toString(formData.vehicleModel)
          : _.toString(modelRef.current),
        vehicle_trim: countRef.current
          ? _.toString(formData.vehicleTrim)
          : _.toString(trimRef.current),
        vehicle_mileage: formData.vehicleMileage,
        vehicle_vin: formData.vehicleVin,
        vehicle_exterior_color: formData.vehicleExteriorColor,
        vehicle_interior_color: formData.vehicleInteriorColor,
        state: formData.state,
        listed_for_sale: formData.listed_for_sale,
        listed_url: formData.listed_url,
      };

      await api
        .post(`deals/update-deal`, vehicleData, {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        })
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            setEditing(false);
            message.success("Changes saved successfully!");
            onUpdateLead();
          }
        })
        .catch((error) => {
          message.error(
            "Sorry we are not able to save data at this time. Please try again later."
          );
          setLoading(false);
        });
    }
  };

  const formData = watch();

  useEffect(() => {
    countRef.current = autoFill;
  }, [autoFill]);

  useEffect(() => {
    if (formData.vehicleVin) {
      if (formData.vehicleVin.length == 17) {
        setfieldLoading(true);
        api
          .get(`deals/chrome-data?vin=${formData.vehicleVin}`, {
            headers: {
              Authorization: `Bearer ${authCtx.userData.token}`,
            },
          })
          .then((response) => {
            setfieldLoading(false);
            if (response.data.status) {
              if (response.data.data.year != "0") {
                if (response.data.data.year) {
                  setAutoFill(true);
                  setValue("vehicleYear", response.data.data.year);
                }
                if (response.data.data.model != "") {
                  setValue("vehicleModel", response.data.data.model);
                }
                if (response.data.data.trim != "") {
                  setValue("vehicleTrim", response.data.data.trim);
                }
                if (response.data.data.make != "") {
                  setAutoFillMake(true);
                  setValue("vehicleMake", response.data.data.make);
                } else {
                  setAutoFill(false);
                }
              } else {
                setAutoFill(false);
                setAutoFillMake(false);
              }
            } else {
              setAutoFill(false);
              setAutoFillMake(false);
            }
          })
          .catch((error) => {
            setfieldLoading(false);
          });
      } else {
        setAutoFill(false);
        setAutoFillMake(false);
      }
    }
  }, [formData.vehicleVin]);

  const yearChange = async (value, key) => {
    setfieldLoading(true);
    setValue("vehicleYear", value);
    await api
      .get(`deals/chrome-data?getMake=true&modelYear=${value}`, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setfieldLoading(false);
        setModels([]);
        setTrims([]);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let makeArray = [];
            Object.entries(response.data.data).forEach((element) => {
              makeArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setMakes(makeArray);
          } else {
            setMakes([]);
          }
        } else {
          setMakes([]);
        }
      })
      .catch((error) => {
        setfieldLoading(false);
      });
  };

  const makeChange = async (value, key) => {
    setfieldLoading(true);
    makeRef.current = key;
    await api
      .get(
        `deals/chrome-data?getModel=true&modelYear=${formData.vehicleYear}&modelMake=${value}`,
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setfieldLoading(false);
        setTrims([]);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let modelArray = [];
            Object.entries(response.data.data).forEach((element) => {
              modelArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setModels(modelArray);
          } else {
            setModels([]);
          }
        } else {
          setModels([]);
        }
      })
      .catch((error) => {
        setfieldLoading(false);
        setModels([]);
      });
  };

  const modelChange = async (value, key) => {
    setfieldLoading(true);
    modelRef.current = key;
    await api
      .get(`deals/chrome-data?getTrim=true&modelId=${value}`, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setfieldLoading(false);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let trimArray = [];
            Object.entries(response.data.data).forEach((element) => {
              trimArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setTrims(trimArray);
          } else {
            setTrims([]);
          }
        } else {
          setTrims([]);
        }
      })
      .catch((error) => {
        setfieldLoading(false);
        setTrims([]);
      });
  };

  const trimChange = (value, key) => {
    trimRef.current = key;
  };

  useEffect(() => {
    if (
      formData.listed_for_sale != 0 &&
      formData.listed_for_sale != undefined
    ) {
      setListedForSale(true);
    } else {
      setListedForSale(false);
    }
  }, [formData.listed_for_sale]);

  if (!data) {
    return null;
  }

  return (
    <CommonDrawerSection>
      <OfferDetailsEditHeader
        isEditing={isEditing}
        onSaveClick={onSaveClick}
        setEditing={setEditing}
        title="Vehicle"
      />
      {!isEditing && (
        <CRow>
          <CCol sm={6} lg={6}>
            <CommonDrawerInfo title="Owner Name:">
              {data.leadName}
            </CommonDrawerInfo>
            <CommonDrawerInfo title="Year:">
              {data.vehicle_year}
            </CommonDrawerInfo>
            <CommonDrawerInfo title="Make:">
              {data.vehicle_make}
            </CommonDrawerInfo>
            <CommonDrawerInfo title="Model:">
              {data.vehicle_model}
            </CommonDrawerInfo>
            <CommonDrawerInfo title="Trim:">
              {data.vehicle_trim}
            </CommonDrawerInfo>
            <CommonDrawerInfo title="Mileage:">
              {data.vehicle_mileage}
            </CommonDrawerInfo>
          </CCol>
          <CCol sm={6} lg={6}>
            <CommonDrawerInfo title="State:">{data.state}</CommonDrawerInfo>
            <CommonDrawerInfo title="Exterior Color:">
              {data.vehicle_exterior_color}
            </CommonDrawerInfo>
            <CommonDrawerInfo title="Interior Color:">
              {data.vehicle_interior_color}
            </CommonDrawerInfo>
            <CommonDrawerInfo title="VIN:">
              {data.vehicle_vin || "\u2014"}
            </CommonDrawerInfo>
            <CommonDrawerInfo title="Listed For Sale:">
              {data.listed_for_sale == 1 ? "Yes" : "No"}
            </CommonDrawerInfo>
            {data?.listed_for_sale == 1 ? (
              <CommonDrawerInfo title="Listed Source:">
                {data.listed_url}
              </CommonDrawerInfo>
            ) : (
              ""
            )}
          </CCol>
          <CCol sm={12} lg={12}>
            <CommonDrawerInfo title="Note:">{data.note}</CommonDrawerInfo>
          </CCol>
        </CRow>
      )}
      {isEditing && (
        <form>
          <div className="row">
            <div className="formcol">
              <label className="input-label">
                Is this vehicle listed for sale anywhere?
              </label>
              <select
                className={`input-select ${
                  errors.listed_for_sale ? "is-invalid" : ""
                }`}
                {...register("listed_for_sale")}
                defaultValue={data?.listed_for_sale}
              >
                <option value="0">No</option>
                <option value="1">Yes</option>
              </select>
            </div>
            {listedForSale == true && (
              <div className="formcol">
                <label className="input-label">Listed Source</label>
                <input
                  className={`input-text ${
                    errors.listed_url ? "is-invalid" : ""
                  }`}
                  placeholder="Listed Source"
                  {...register("listed_url")}
                  defaultValue={data?.listed_url}
                />
                {errors.listed_url && (
                  <span className="formError">
                    {errors.listed_url?.message}
                  </span>
                )}
              </div>
            )}
            <div className="formcol">
              <label className="input-label">VIN</label>
              <input
                className={`input-text ${
                  errors.vehicleVin ? "is-invalid" : ""
                }`}
                placeholder="VIN"
                {...register("vehicleVin")}
                maxLength={17}
                defaultValue={data.vehicle_vin}
              />
              {errors.vehicleVin && (
                <span className="formError">{errors.vehicleVin?.message}</span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">Year</label>
              <select
                className={`input-select ${
                  errors.vehicleYear ? "is-invalid" : ""
                }`}
                {...register("vehicleYear")}
                disabled={autoFill}
                defaultValue={data.vehicle_year}
                onChange={(e) =>
                  yearChange(
                    e.target.value,
                    e.target.options[e.target.selectedIndex].text
                  )
                }
              >
                <option value="" selected={!autoFill}>
                  Select Year
                </option>
                {_.map(years, (option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {errors.vehicleYear && (
                <span className="formError">{errors.vehicleYear?.message}</span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">State</label>
              <select
                className={`input-select ${errors.state ? "is-invalid" : ""}`}
                {...register("state")}
                defaultValue={data.state}
              >
                <option>State vehicle is located in</option>
                {_.map(states, (state) => (
                  <option key={state.value} value={state.label}>
                    {state.label}
                  </option>
                ))}
              </select>
              {errors.state && (
                <span className="formError">{errors.state?.message}</span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">Make</label>
              {!autoFillMake && (
                <select
                  className={`input-select ${
                    errors.vehicleMake ? "is-invalid" : ""
                  }`}
                  {...register("vehicleMake")}
                  onChange={(e) =>
                    makeChange(
                      e.target.value,
                      e.target.options[e.target.selectedIndex].text
                    )
                  }
                  defaultValue={data.vehicle_make}
                >
                  <option value="" selected={!autoFill}>
                    Select Make
                  </option>
                  {_.map(makes, (option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
              {autoFillMake && (
                <input
                  className={`input-text ${
                    errors.vehicleMake ? "is-invalid" : ""
                  }`}
                  placeholder="Make"
                  {...register("vehicleMake")}
                  disabled={autoFillMake}
                  defaultValue={data.vehicle_make}
                />
              )}
              {errors.vehicleMake && (
                <span className="formError">{errors.vehicleMake?.message}</span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">Model</label>
              {!autoFillMake && (
                <select
                  className={`input-select ${
                    errors.vehicleModel ? "is-invalid" : ""
                  }`}
                  {...register("vehicleModel")}
                  onChange={(e) =>
                    modelChange(
                      e.target.value,
                      e.target.options[e.target.selectedIndex].text
                    )
                  }
                  defaultValue={data.vehicle_model}
                >
                  <option value="" selected={!autoFill}>
                    Select Model
                  </option>
                  {_.map(models, (option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
              {autoFillMake && (
                <input
                  className={`input-text ${
                    errors.vehicleModel ? "is-invalid" : ""
                  }`}
                  placeholder="Make"
                  {...register("vehicleModel")}
                  defaultValue={data.vehicle_model}
                />
              )}
              {errors.vehicleModel && (
                <span className="formError">
                  {errors.vehicleModel?.message}
                </span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">Trim</label>
              {!autoFillMake && (
                <select
                  className={`input-select ${
                    errors.vehicleTrim ? "is-invalid" : ""
                  }`}
                  {...register("vehicleTrim")}
                  onChange={(e) =>
                    trimChange(
                      e.target.value,
                      e.target.options[e.target.selectedIndex].text
                    )
                  }
                  defaultValue={data.vehicle_trim}
                >
                  <option value="" selected={!autoFill}>
                    Select Trim
                  </option>
                  {_.map(trims, (option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}
              {autoFillMake && (
                <input
                  className={`input-text ${
                    errors.vehicleTrim ? "is-invalid" : ""
                  }`}
                  placeholder="Make"
                  {...register("vehicleTrim")}
                  defaultValue={data.vehicle_trim}
                />
              )}
              {errors.vehicleTrim && (
                <span className="formError">{errors.vehicleTrim?.message}</span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">Mileage</label>
              <input
                className={`input-text ${
                  errors.vehicleMileage ? "is-invalid" : ""
                }`}
                placeholder="Mileage"
                {...register("vehicleMileage")}
                defaultValue={data.vehicle_mileage}
              />
              {errors.vehicleMileage && (
                <span className="formError">
                  {errors.vehicleMileage?.message}
                </span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">Exterior Color</label>
              <input
                className={`input-text ${
                  errors.vehicleExteriorColor ? "is-invalid" : ""
                }`}
                placeholder="Exterior Color"
                {...register("vehicleExteriorColor")}
                defaultValue={data.vehicle_exterior_color}
              />
              {errors.vehicleExteriorColor && (
                <span className="formError">
                  {errors.vehicleExteriorColor?.message}
                </span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">Interior Color</label>
              <input
                className={`input-text ${
                  errors.vehicleInteriorColor ? "is-invalid" : ""
                }`}
                placeholder="Interior Color"
                {...register("vehicleInteriorColor")}
                defaultValue={data.vehicle_interior_color}
              />
              {errors.vehicleInteriorColor && (
                <span className="formError">
                  {errors.vehicleInteriorColor?.message}
                </span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">Note</label>
              <textarea
                className={`input-text ${errors.note ? "is-invalid" : ""}`}
                placeholder="Note"
                {...register("note")}
                defaultValue={data.note}
              ></textarea>
              {errors.note && (
                <span className="formError">{errors.note?.message}</span>
              )}
            </div>
          </div>
        </form>
      )}
    </CommonDrawerSection>
  );
});

export default OffersDetailsVehicle;
