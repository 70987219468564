import React from "react";
import { Col, Row } from "antd";

import OffersTable from "../MyLeads/Table";

import "./index.scss";
import LayoutsLoggedIn from "../Layout/LoggedIn";

const MyLeads = () => {
  return (
    <>
      <LayoutsLoggedIn>
        <Row justify="space-between offers-page">
          <Col>
            <h2>Lead Form Sent</h2>
          </Col>
        </Row>
        <OffersTable />
      </LayoutsLoggedIn>
    </>
  );
};

export default MyLeads;
