import React from "react";
import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
const BuyerActivity = (props) => {
  return (
    <CRow>
      <CCol xs>
        <CCard className="leadActivityCard">
          <CCardBody style={{ backgroundColor: "#1C1C24" }}>
            <CRow>
              <CCol xs={12} md={6} xl={6}>
                <CRow>
                  <CCol sm={6}>
                    <div className="leadActivityBox border-start border-start-4 border-start-info py-1 px-3 border-primary">
                      <div className="text-medium-emphasis small">
                        Buyer Joined
                      </div>
                      <div className="fs-5 fw-semibold">
                        {props.data?.buyerJoin}
                      </div>
                    </div>
                  </CCol>
                  <CCol sm={6}>
                    <div className="leadActivityBox border-start border-start-4 border-start-success py-1 px-3 border-success">
                      <div className="text-medium-emphasis small">
                        Active Buyers
                      </div>
                      <div className="fs-5 fw-semibold">
                        {props.data?.activeBuyers}
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
              <CCol xs={12} md={6} xl={6}>
                <CRow>
                  <CCol sm={6}>
                    <div className="leadActivityBox border-start border-start-4 border-start-danger py-1 px-3">
                      <div className="text-medium-emphasis small">
                        Non Active Buyers
                      </div>
                      <div className="fs-5 fw-semibold">
                        {props.data?.nonActiveBuyers}
                      </div>
                    </div>
                  </CCol>
                  <CCol sm={6}>
                    <div className="leadActivityBox border-start border-start-4 border-start-warning py-1 px-3">
                      <div className="text-medium-emphasis small">
                        Total Buyers
                      </div>
                      <div className="fs-5 fw-semibold">
                        {props.data?.totalBuyers}
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};
export default BuyerActivity;
