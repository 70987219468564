import React from "react";
import { Link } from "react-router-dom";
import routes from "../../config/routes";
import LayoutsHomeAlt from "../Layout/HomeAlt";

const NotFound = () => {
  return (
    <LayoutsHomeAlt>
      <div className="notfound-wrapper bannerSec">
        <div className="notfound-inner">
          <h2>404</h2>
          <span>OOPS! PAGE NOT FOUND</span>
          <p>
            The page you are looking for was moved, removed, renamed or might
            never existed.
          </p>
          <Link
            to={routes.Dashboard}
            className="d-inline-block btn btn-red head-btn"
          >
            Back to Dashboard
          </Link>
        </div>
      </div>
    </LayoutsHomeAlt>
  );
};

export default NotFound;
