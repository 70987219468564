import React from 'react';

const SvgDashboard = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 12H6C3.79086 12 2 13.7909 2 16V18C2 20.2091 3.79086 22 6 22H7C9.20914 22 11 20.2091 11 18V16C11 13.7909 9.20914 12 7 12ZM4 16C4 14.8954 4.89543 14 6 14H7C8.10457 14 9 14.8954 9 16V18C9 19.1046 8.10457 20 7 20H6C4.89543 20 4 19.1046 4 18V16Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 5.5C9 3.567 7.433 2 5.5 2C3.567 2 2 3.567 2 5.5V6.5C2 8.433 3.567 10 5.5 10C7.433 10 9 8.433 9 6.5V5.5ZM4 5.5C4 4.67157 4.67157 4 5.5 4C6.32843 4 7 4.67157 7 5.5V6.5C7 7.32843 6.32843 8 5.5 8C4.67157 8 4 7.32843 4 6.5V5.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 11H17C14.7909 11 13 12.7909 13 15V18C13 20.2091 14.7909 22 17 22H18C20.2091 22 22 20.2091 22 18V15C22 12.7909 20.2091 11 18 11ZM15 15C15 13.8954 15.8954 13 17 13H18C19.1046 13 20 13.8954 20 15V18C20 19.1046 19.1046 20 18 20H17C15.8954 20 15 19.1046 15 18V15Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.5 2H14.5C12.567 2 11 3.567 11 5.5C11 7.433 12.567 9 14.5 9H18.5C20.433 9 22 7.433 22 5.5C22 3.567 20.433 2 18.5 2ZM13 5.5C13 4.67157 13.6716 4 14.5 4H18.5C19.3284 4 20 4.67157 20 5.5C20 6.32843 19.3284 7 18.5 7H14.5C13.6716 7 13 6.32843 13 5.5Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgDashboard;
