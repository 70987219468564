import React from "react";

const SvgRole = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M14.45,12.46a2.52,2.52,0,0,1,1.62,1.86,7.58,7.58,0,0,1,.07,1.17c0,.22,0,.44,0,.66a.64.64,0,0,1-.64.63.63.63,0,0,1-.63-.63c0-.42,0-.83,0-1.24a1.28,1.28,0,0,0-1.32-1.32H10.45a1.28,1.28,0,0,0-1.32,1.34c0,.39,0,.78,0,1.17a.65.65,0,0,1-.63.68.64.64,0,0,1-.65-.68,13.6,13.6,0,0,1,0-1.57,2.45,2.45,0,0,1,1.56-2l.09,0a3.23,3.23,0,0,1-.62-2.9A3.15,3.15,0,0,1,10.15,7.8a3.2,3.2,0,0,1,4.3,4.66Zm-4.37-2.05A1.92,1.92,0,1,0,12,8.49,1.92,1.92,0,0,0,10.08,10.41Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M18.85,13.39a.68.68,0,0,1-.65.18.63.63,0,0,1-.31-1c.43-.47.89-.92,1.35-1.36a.6.6,0,0,1,.83,0c.45.43.88.87,1.31,1.31a.63.63,0,0,1-.9.89,3,3,0,0,1-.26-.3c-.06.31-.11.55-.16.79a8.24,8.24,0,0,1-6.85,6.29,8.11,8.11,0,0,1-7-2.29A.64.64,0,1,1,7.06,17a7,7,0,0,0,11.78-3.45A.49.49,0,0,0,18.85,13.39Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M5.22,10.21a.65.65,0,0,1,1,.42.63.63,0,0,1-.17.54c-.43.44-.85.87-1.28,1.29a.63.63,0,0,1-.91,0L2.65,11.2a.63.63,0,1,1,.89-.9l.28.28c0-.14.06-.24.08-.35A8.29,8.29,0,0,1,17.79,6.07a.66.66,0,0,1,.22.68.62.62,0,0,1-.47.45A.68.68,0,0,1,16.89,7a6.87,6.87,0,0,0-2.75-1.66,7,7,0,0,0-8.9,4.79A.84.84,0,0,1,5.22,10.21Z"
    />
  </svg>
);

export default SvgRole;
