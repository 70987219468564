import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import moment from "moment";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";

import ReactPaginate from "react-paginate";
import api from "../../config/api";
import AuthContext from "../../store/authContext";
import RefreshContext from "../../store/refreshContext";
import { Spin } from "antd";
import { formatPhoneNumber } from "../../utils/formats";
import LeadNote from "./Note";

const OffersTable = React.memo(() => {
  const [error, setError] = useState();
  const [offers, setOffers] = useState([]);
  const [leadData, setLeadData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("");
  const [perPage, setPerPage] = useState("10");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 1,
    current: 1,
  });
  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);

  useEffect(() => {
    fetchLeads({ per_page: perPage }, 1);
  }, [refreshCtx.refresh, perPage]);

  async function fetchLeads(formData, page) {
    await api
      .post(`leads?page=${page}`, formData, {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setOffers([]);
        if (response.data.status) {
          setOffers(response.data.data.lead);
          setPagination(response.data.data.paginate);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  const getList = (data) => {
    let content = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      content.push(
        <CTableRow key={item.id}>
          <CTableDataCell className="ant-table-cell">
            {item.name}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.email}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {formatPhoneNumber(item.phone).formatted}
          </CTableDataCell>
          {authCtx.userData?.user_type == 0 && (
            <CTableDataCell className="ant-table-cell">
              {item.comment}
            </CTableDataCell>
          )}
          <CTableDataCell className="ant-table-cell">
            {item.status == 1 && "Not Submitted"}
            {item.status == 2 && "Clicked"}
            {item.status == 3 && "Submitted"}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {moment(item.created_at).utc().format("M/D/YY")}
          </CTableDataCell>
          {authCtx.userData?.user_type == 0 && (
            <CTableDataCell className="ant-table-cell">
              <button
                type="button"
                className="btn-sm"
                onClick={() => setLeadData(item)}>
                Add Note
              </button>
            </CTableDataCell>
          )}
        </CTableRow>
      );
    }
    return content;
  };

  function sort(field, currentField, sort, page) {
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        fetchLeads(
          {
            field_name: field,
            order_by: "asc",
            per_page: perPage,
          },
          page
        );
      } else {
        setSortType("desc");
        fetchLeads(
          {
            field_name: field,
            order_by: "desc",
            per_page: perPage,
          },
          page
        );
      }
    } else {
      setSortField(field);
      setSortType("desc");
      fetchLeads(
        {
          field_name: field,
          order_by: "desc",
          per_page: perPage,
        },
        page
      );
    }
  }

  const onPaginationFetch = (e) => {
    fetchLeads(
      { field_name: sortField, order_by: sortType, per_page: perPage },
      e.selected + 1
    );
  };

  const perPageRecordHandler = (e) => {
    setPerPage(e.target.value);
  };

  const onClose = () => {
    setLeadData([]);
  };

  return (
    <>
      <div className="common-table-wrapper">
        <div className="ant-table">
          <CTable className="table-layout">
            <CTableHead className="ant-table-thead">
              <CTableRow>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Name
                  <span
                    onClick={(e) =>
                      sort("name", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "name" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Email
                  <span
                    onClick={(e) =>
                      sort("email", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "email" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Phone
                  <span
                    onClick={(e) =>
                      sort("phone", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "phone" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                {authCtx.userData?.user_type == 0 && (
                  <CTableHeaderCell className="ant-table-cell" scope="col">
                    Comment
                    <span
                      onClick={(e) =>
                        sort("comment", sortField, sortType, pagination.current)
                      }
                      className={`sortIcon ${
                        sortField == "comment" ? sortType : ""
                      }`}></span>
                  </CTableHeaderCell>
                )}
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Status
                  <span
                    onClick={(e) =>
                      sort("status", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "status" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Date
                  <span
                    onClick={(e) =>
                      sort(
                        "created_at",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "created_at" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                {authCtx.userData?.user_type == 0 && (
                  <CTableHeaderCell className="ant-table-cell" scope="col">
                    Note
                  </CTableHeaderCell>
                )}
              </CTableRow>
            </CTableHead>
            <CTableBody className="ant-table-tbody">
              {isLoading && (
                <>
                  <CTableRow className="text-center">
                    <CTableDataCell className="ant-table-cell" colSpan={7}>
                      {<Spin />}
                    </CTableDataCell>
                  </CTableRow>
                </>
              )}
              {!isLoading && offers.length == 0 && (
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={7}>
                    No Data Found.
                  </CTableDataCell>
                </CTableRow>
              )}
              {getList(offers)}
            </CTableBody>
          </CTable>
        </div>
        {pagination.total_records != undefined && (
          <>
            <ul className="ant-pagination ant-table-pagination ant-table-pagination-right">
              <li className="ant-pagination-total-text">
                {pagination.total_records} items
              </li>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={onPaginationFetch}
                forcePage={parseInt(pagination?.current - 1)}
                previousClassName="ant-pagination-prev"
                nextClassName="ant-pagination-next"
                pageRangeDisplayed={10}
                pageCount={pagination.total_pages}
                pageClassName="ant-pagination-item"
                previousLabel="<"
                activeClassName="ant-pagination-item-active"
                disabledClassName="ant-pagination-item ant-pagination-disabled"
                renderOnZeroPageCount={null}
              />
              <select className="input-select" onChange={perPageRecordHandler}>
                <option value="10">10 / Page</option>
                <option value="20">20 / Page</option>
                <option value="50">50 / Page</option>
                <option value="100">100 / Page</option>
              </select>
            </ul>
          </>
        )}
      </div>
      <LeadNote leadData={leadData} onClose={onClose} />
    </>
  );
});

export default OffersTable;
