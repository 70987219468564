import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Row, Button, Modal, message } from "antd";
import TeamAddMember from "./AddMember";
import LayoutsLoggedIn from "../Layout/LoggedIn";
import "./index.scss";
import {
  CloseCircleOutlined,
  ExclamationCircleFilled,
  StarFilled,
  StarOutlined,
} from "@ant-design/icons";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import calenderIcon from "../../images/calender.svg";
import moment from "moment";
import _ from "lodash";
import { Dropdown, Menu, Spin } from "antd";
import SvgThreeDots from "../../images/three-dots";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import AuthContext from "../../store/authContext";
import api from "../../config/api";
import RefreshContext from "../../store/refreshContext";
import { formatPhoneNumber } from "../../utils/formats";
import EditBuyer from "./EditBuyer";
import downloadCsv from "download-csv";
import SendSms from "./SendSms";
import SvgClose from "../../images/close";
import BuyerInformation from "./Information";
import CopyJoinLink from "./CopyJoinLink";

const Buyer = () => {
  const { confirm } = Modal;
  const [filterSearch, setFilterSearch] = useState("");
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState("");

  const [isVisible, setVisible] = useState(false);
  const [selectedRating, setSelectedRating] = useState(0);

  const onCancel = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(filterSearch), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [filterSearch]);

  const [selectedDate, setSelectedDate] = useState(null);
  const onDealChartRangeChange = (value) => {
    setSelectedDate(value);
  };

  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [unCheckedBuyers, setUnCheckedBuyers] = useState([]);
  const [isAllBuyersSelected, setIsAllBuyersSelected] = useState(false);
  const [showAddBtn, setShowAddBtn] = useState(false);
  const [selectedBuyerDetail, setSelectedBuyerDetail] = useState([]);
  const [buyerId, setBuyerId] = useState(null);
  const [offers, setOffers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("");
  const [perPage, setPerPage] = useState("10");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 1,
    current: 1,
  });

  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);

  useEffect(() => {
    let filterData = {
      per_page: perPage,
    };
    if (debouncedValue != "") {
      filterData = {
        ...filterData,
        search: debouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    fetchLeads(filterData, 1);
  }, [refreshCtx.refresh, perPage, selectedDate, debouncedValue]);

  const onBuyerDelete = (status, id) => {
    confirm({
      title: `Are you sure want to delete this buyer?`,
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleBuyerStatus(status, id);
      },
      onCancel() {},
    });
  };

  const handleBuyerStatus = async (status, id) => {
    setLoading(true);
    if (id) {
      await api
        .post(
          `users/update-status`,
          { user_id: id, status: status },
          {
            headers: {
              Authorization: `Bearer ${authCtx.userData.token}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            refreshCtx.onRefresh();
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status == 401) {
            authCtx.logout();
          }
        });
    }
  };

  const onSmsSuccess = () => {
    setUnCheckedBuyers([]);
    setSelectedBuyers([]);
    setIsAllBuyersSelected(false);
  };

  const onAddRating = (id) => {
    setVisible(true);
    let selectbuyr = offers.filter((x) => x.id === id);
    setSelectedBuyerDetail(selectbuyr);
    setSelectedRating(selectbuyr[0]?.rating);
  };

  const updateBuyerRating = async () => {
    await api
      .post(
        `buyers/updateRating`,
        { rating: selectedRating, user_id: selectedBuyerDetail[0]?.id },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          setLoading(false);
          onCancel();
          refreshCtx.onRefresh();
          message.success(response.data.message);
        } else {
          setLoading(false);
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const onBuyerCheck = (e, index) => {
    let rawUnCheckedBuyers = unCheckedBuyers;
    let itemInde = rawUnCheckedBuyers.indexOf(index);
    if (isAllBuyersSelected) {
      if (itemInde !== -1 && e.target.checked) {
        rawUnCheckedBuyers.splice(itemInde, 1);
      } else {
        rawUnCheckedBuyers.push(index);
      }
    }
    setUnCheckedBuyers([...rawUnCheckedBuyers]);

    let rawCheckedBuyers = selectedBuyers;
    let itemIndex = rawCheckedBuyers.indexOf(index);
    if (itemIndex !== -1) {
      rawCheckedBuyers.splice(itemIndex, 1);
    } else {
      rawCheckedBuyers.push(index);
    }
    setSelectedBuyers([...rawCheckedBuyers]);
  };

  const selectAllBuyers = (e) => {
    setIsAllBuyersSelected(!isAllBuyersSelected);
    setUnCheckedBuyers([]);
    let allBuyersIds = [];
    if (e.target.checked) {
      offers.forEach((item) => {
        allBuyersIds.push(item.id);
      });
      setSelectedBuyers([...allBuyersIds]);
    } else {
      setSelectedBuyers([]);
    }
  };

  async function fetchLeads(formData, page) {
    let url = `buyers?page=${page}`;
    if (authCtx.userData.user_type == 1) {
      url = `buyers/getbuyersteam`;
      formData.user_id = authCtx.userData.id;
    }
    await api
      .post(url, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setOffers([]);
        if (response.data.status) {
          let allBuyersIds = [];
          if (isAllBuyersSelected) {
            response.data.data.user.forEach((item) => {
              if (!selectedBuyers.includes(item.id)) {
                allBuyersIds.push(item.id);
              }
            });
            setSelectedBuyers([...selectedBuyers, ...allBuyersIds]);
          }
          setOffers(response.data.data.user);
          setPagination(response.data.data.paginate);
          if (
            (authCtx.userData.user_type == 1 &&
              response.data.data?.buyersData?.is_affiliate == 1) ||
            authCtx.userData.user_type == 0
          ) {
            setShowAddBtn(true);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  const getList = (data) => {
    let content = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      content.push(
        <CTableRow key={item.id}>
          {authCtx.userData?.user_type == 0 && (
            <CTableDataCell className="ant-table-cell">
              <input
                type="checkbox"
                className="form-check-input"
                checked={selectedBuyers.includes(item?.id)}
                onChange={(e) => onBuyerCheck(e, item?.id)}
                disabled={isAllBuyersSelected}
              />
            </CTableDataCell>
          )}
          <CTableDataCell className="ant-table-cell">
            {authCtx.userData.user_type == 0 ? (
              <Link to={`/buyers/detail/${item.id}`}>{item.name}</Link>
            ) : (
              <>{item.name}</>
            )}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.email}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {formatPhoneNumber(item.mobile_no).formatted}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.totalFormSent}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.totalDeal}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.totalCompleted}
          </CTableDataCell>
          {authCtx.userData?.user_type == 0 && (
            <CTableDataCell className="ant-table-cell">
              {Array.from(Array(3), (e, i) => {
                return (
                  <div key={i} className="buyer-star-rating-sm">
                    {parseInt(item.rating) >= i + 1 ? (
                      <StarFilled className="selected" />
                    ) : (
                      <StarOutlined />
                    )}
                  </div>
                );
              })}
            </CTableDataCell>
          )}
          <CTableDataCell className="ant-table-cell">
            {item.status != 0 ? (
              <span className="user-status-active">Active</span>
            ) : (
              <span className="user-status-suspended">Inactive</span>
            )}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.created_at != null
              ? moment(item.created_at).utc().format("M/D/YY")
              : "\u2014"}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.last_login != null
              ? moment(item.last_login).utc().format("M/D/YY")
              : "\u2014"}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {authCtx.userData?.user_type == 0 ? (
              <Dropdown
                className="common-table-status"
                overlay={
                  <Menu selectable={false}>
                    <Menu.Item key="view">
                      <Link to={`/buyers/detail/${item.id}`}>View</Link>
                    </Menu.Item>
                    {authCtx?.userData?.user_type == 0 && (
                      <Menu.Item
                        key="delete"
                        onClick={(e) => onBuyerDelete(2, item.id)}>
                        Delete
                      </Menu.Item>
                    )}
                    {item.status == 1 && (
                      <Menu.Item
                        key="suspend"
                        onClick={(e) => handleBuyerStatus(0, item.id)}>
                        Suspend
                      </Menu.Item>
                    )}
                    {item.status == 0 && (
                      <Menu.Item
                        key="reactive"
                        onClick={(e) => handleBuyerStatus(1, item.id)}>
                        Reactive
                      </Menu.Item>
                    )}
                    {authCtx?.userData?.user_type == 0 && (
                      <Menu.Item
                        key="assign_company"
                        onClick={() => setBuyerId(item.id)}>
                        Assign Company
                      </Menu.Item>
                    )}
                    {authCtx?.userData?.user_type == 0 && (
                      <Menu.Item
                        key="add_review"
                        onClick={() => onAddRating(item.id)}>
                        Add Rating
                      </Menu.Item>
                    )}
                  </Menu>
                }
                placement="bottomRight"
                trigger={["click"]}>
                <SvgThreeDots />
              </Dropdown>
            ) : (
              <span
                style={{ cursor: "pointer", display: "block" }}
                onClick={() => setBuyerId(item.id)}>
                <SvgThreeDots />
              </span>
            )}
          </CTableDataCell>
        </CTableRow>
      );
    }
    return content;
  };

  function sort(field, currentField, sort, page) {
    let filterData = {
      per_page: perPage,
    };
    if (debouncedValue != "") {
      filterData = {
        ...filterData,
        search: debouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
          per_page: perPage,
        };
        fetchLeads(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
          per_page: perPage,
        };
        fetchLeads(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
        per_page: perPage,
      };
      fetchLeads(filterData, page);
    }
  }

  const perPageRecordHandler = (e) => {
    setPerPage(e.target.value);
  };

  const onPaginationFetch = (e) => {
    let filterData = {
      per_page: perPage,
    };
    if (debouncedValue != "") {
      filterData = {
        ...filterData,
        search: debouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    fetchLeads(filterData, e.selected + 1);
  };

  const onClose = () => {
    setBuyerId(null);
  };

  const currentDateTime = new Date();
  const exportHandler = async () => {
    setIsExportLoading(true);
    await api
      .post(
        "buyersexport",
        {},
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData?.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.user);
          let exportData = response.data.data?.user.map((item) => {
            return {
              name: item.name,
              email: item.email,
              mobile_no: item.mobile_no,
              totalCompleted: item.totalCompleted,
              totalDeal: item.totalDeal,
              totalFormSent: item.totalFormSent,
              commission: item.commission,
              last_login:
                item.last_login != null
                  ? moment(item.last_login).utc().format("MM-DD-YYYY")
                  : "",
              referral_id: item.referral_id,
              city: item.city,
              address: item.address,
              address2: item.address2,
              state: item.state,
              zip_code: item.zip_code,
              status: item.status ? "Active" : "Suspended",
              created_at: moment(item.created_at).utc().format("MM-DD-YYYY"),
            };
          });

          let exportColumns = {
            name: "Name",
            email: "Email",
            mobile_no: "Phone",
            totalFormSent: "Total Leads",
            totalDeal: "Total Deals",
            totalCompleted: "Total Completed",
            commission: "Commission",
            last_login: "Last Login",
            referral_id: "Referral Id",
            city: "City",
            address: "Address",
            address2: "Address 2",
            state: "State",
            zip_code: "ZIP Code",
            status: "Status",
            created_at: "Join Date",
          };
          setIsExportLoading(false);
          downloadCsv(
            exportData,
            exportColumns,
            `buyers-${moment(currentDateTime).format("MM-DD-YYYY")}`
          );
        }
      })
      .catch((error) => {
        setIsExportLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  function selectRate(count) {
    if (count === selectedRating) {
      setSelectedRating(0);
    } else {
      setSelectedRating(count);
    }
  }

  return (
    <LayoutsLoggedIn>
      <Modal
        destroyOnClose
        closeIcon={<SvgClose />}
        footer={null}
        onCancel={onCancel}
        visible={isVisible}
        width={390}>
        <h3 className="ds-title">Add Rating</h3>
        <Row className="color-base" justify="center">
          <Col className="text-center padding-bottom-1_5">
            <h4 className="d-block mb-2">
              Buyer Name: {selectedBuyerDetail[0]?.name}
            </h4>
            Please select 1 of 3 stars to rate.
          </Col>
          <Col className="text-center" xs={24}>
            {Array.from(Array(3), (e, i) => {
              return (
                <div
                  key={i}
                  className="buyer-star-rating"
                  onClick={() => selectRate(i + 1)}>
                  {selectedRating >= i + 1 ? (
                    <StarFilled className="selected" />
                  ) : (
                    <StarOutlined />
                  )}
                </div>
              );
            })}
          </Col>
        </Row>
        <div className="formbtn">
          <button
            type="submit"
            className="input-btn"
            disabled={isLoading}
            onClick={() => updateBuyerRating()}>
            Update
          </button>
        </div>
      </Modal>
      <div className="row">
        <div className="col-lg col-12">
          <h2>{authCtx.userData?.user_type == 0 ? "Buyers" : "My Team"}</h2>
        </div>
        {(authCtx.userData?.user_type == 0 ||
          authCtx.userData?.allowAll == 1 ||
          authCtx.userData?.permissions.includes("my_teams_add")) &&
          showAddBtn && (
            <div className="col-auto pe-0">
              <TeamAddMember />
            </div>
          )}
        {authCtx.userData?.user_type == 1 && (
          <div className="col-auto pe-0">
            <CopyJoinLink />
          </div>
        )}
        {authCtx.userData?.user_type == 0 && (
          <div className="col-auto ps-0">
            <Button
              type="primary"
              className="button-primary"
              onClick={() => exportHandler()}
              loading={isExportLoading}>
              Export
            </Button>
          </div>
        )}
      </div>
      <div className="row">
        <div className="col">
          {selectedBuyers.length !== 0 && (
            <SendSms
              selectedBuyers={selectedBuyers}
              isAllBuyers={isAllBuyersSelected}
              totalBuyers={pagination.total_records}
              search={debouncedValue}
              selectedDate={selectedDate}
              onSmsSuccess={onSmsSuccess}
            />
          )}
        </div>
        <div className="col-lg-auto col-12">
          <DateRangePicker
            onChange={(e) => onDealChartRangeChange(e)}
            value={selectedDate}
            format="M-d-y"
            yearPlaceholder="YYYY"
            monthPlaceholder="MM"
            dayPlaceholder="DD"
            calendarIcon={<img src={calenderIcon} style={{ height: "17px" }} />}
          />
        </div>
        <div className="col-lg-3 col-12">
          <div className="input-col deals-search-filter">
            <input
              type="text"
              placeholder="Search here"
              className="input-text"
              value={filterSearch}
              onChange={(e) => setFilterSearch(e.target.value)}
            />
            <CloseCircleOutlined
              className="deals-search-clear-btn"
              onClick={() => setFilterSearch("")}
            />
          </div>
        </div>
      </div>
      <div className="common-table-wrapper">
        <div className="ant-table">
          <CTable className="table-layout">
            <CTableHead className="ant-table-thead">
              <CTableRow>
                {authCtx.userData?.user_type == 0 && (
                  <CTableHeaderCell className="ant-table-cell" scope="col">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={isAllBuyersSelected}
                      onChange={(e) => selectAllBuyers(e)}
                    />
                  </CTableHeaderCell>
                )}
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Name
                  <span
                    onClick={(e) =>
                      sort("name", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "name" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Email
                  <span
                    onClick={(e) =>
                      sort("email", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "email" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Phone
                  <span
                    onClick={(e) =>
                      sort("mobile_no", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "mobile_no" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Total Leads
                  <span
                    onClick={(e) =>
                      sort(
                        "totalFormSent",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "totalFormSent" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Total Deals
                  <span
                    onClick={(e) =>
                      sort("totalDeal", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "totalDeal" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Total Completed
                  <span
                    onClick={(e) =>
                      sort(
                        "totalCompleted",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "totalCompleted" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                {authCtx.userData?.user_type == 0 && (
                  <CTableHeaderCell
                    className="ant-table-cell"
                    scope="col"
                    width="10%">
                    Rating
                    <span
                      onClick={(e) =>
                        sort("rating", sortField, sortType, pagination.current)
                      }
                      className={`sortIcon ${
                        sortField == "rating" ? sortType : ""
                      }`}></span>
                  </CTableHeaderCell>
                )}
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Status
                  <span
                    onClick={(e) =>
                      sort("status", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "status" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Join Date
                  <span
                    onClick={(e) =>
                      sort(
                        "created_at",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "created_at" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Last Activity Date
                  <span
                    onClick={(e) =>
                      sort(
                        "last_login",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "last_login" ? sortType : ""
                    }`}></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Action
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="ant-table-tbody">
              {isLoading && (
                <>
                  <CTableRow className="text-center">
                    <CTableDataCell className="ant-table-cell" colSpan={12}>
                      {<Spin />}
                    </CTableDataCell>
                  </CTableRow>
                </>
              )}
              {!isLoading && offers.length == 0 && (
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={12}>
                    No Data Found.
                  </CTableDataCell>
                </CTableRow>
              )}
              {getList(offers)}
            </CTableBody>
          </CTable>
        </div>
        {pagination.total_records != undefined && (
          <>
            <ul className="ant-pagination ant-table-pagination ant-table-pagination-right">
              <li className="ant-pagination-total-text">
                {pagination.total_records} items
              </li>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                forcePage={parseInt(pagination?.current - 1)}
                onPageChange={onPaginationFetch}
                previousClassName="ant-pagination-prev"
                nextClassName="ant-pagination-next"
                pageRangeDisplayed={5}
                pageCount={pagination.total_pages}
                pageClassName="ant-pagination-item"
                previousLabel="<"
                activeClassName="ant-pagination-item-active"
                disabledClassName="ant-pagination-item ant-pagination-disabled"
                renderOnZeroPageCount={null}
              />
              <select className="input-select" onChange={perPageRecordHandler}>
                <option value="10">10 / Page</option>
                <option value="20">20 / Page</option>
                <option value="50">50 / Page</option>
                <option value="100">100 / Page</option>
              </select>
            </ul>
          </>
        )}
      </div>
      {authCtx.userData.user_type == 0 && (
        <EditBuyer buyerId={buyerId} onClose={onClose} />
      )}
      {authCtx.userData.user_type == 1 && (
        <BuyerInformation buyerId={buyerId} onClose={onClose} />
      )}
    </LayoutsLoggedIn>
  );
};

export default Buyer;
