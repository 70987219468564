const routes = {
  Home: "/",
  Signup: "/signup",
  SignupThirdParty: "https://form.typeform.com/to/cL3Kg68p",
  BuyerSignup: "/signup/:id/",
  SignupTeam: "/signupteam/:id/",
  ForgotPassword: "/forgot-password",
  ResetPassword: "/reset-password/:id",
  Dashboard: "/dashboard",
  Deals: "/deals",
  MyLeads: "/my-leads",
  Profile: "/profile",
  PaymentHistory: "/payment-history",
  Buyers: "/buyers",
  BuyerAccount: "/buyer-account",
  BuyerDetail: "/buyers/detail/:id",
  HomeVerifyAccount: "/verify-email/:id",
  Inquiry: "/inquiry/:lead_id/:user_id",
  InquiryPrivacy: "/inquiry/privacy-policy",
  InquiryTerms: "/inquiry/terms-conditions",
  LandingPage: "/inquiry/:user_id",
  Company: "/company",
  Role: "/role",
  Resources: "/resources",
  Privacy: "/privacy-policy",
  Terms: "/terms-conditions",
  Payouts: "/payouts",
  Template: "/template",
  Mailbox: "/mailbox",
  SmsHistoryBuyer: "/sms-history",
  ThankYou: "/thank-you",
};

export default routes;
