import React, { useContext, useState } from "react";
import {
  Button,
  Col,
  Dropdown,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Spin,
} from "antd";
import {
  CloseCircleOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import _ from "lodash";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import { formatPhoneNumber } from "../../../utils/formats";
import AuthContext from "../../../store/authContext";
import RefreshContext from "../../../store/refreshContext";
import api from "../../../config/api";
import SvgClose from "../../../images/close";
import { useCallback } from "react";
import { Option } from "antd/lib/mentions";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import SvgSuccess from "../../../images/success";
import ReactPaginate from "react-paginate";

const states = _.map(
  {
    AL: "Alabama",
    AK: "Alaska",
    AS: "American Samoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "Marshall Islands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PW: "Palau",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  },
  (label, value) => ({ label, value })
);

const Team = ({ data, id, hashId, buyerName }) => {
  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);
  const { confirm } = Modal;
  const [buyers, setBuyers] = useState([]);
  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [unCheckedBuyers, setUnCheckedBuyers] = useState([]);
  const [isAllBuyersSelected, setIsAllBuyersSelected] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [buyerId, setBuyerId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [successText, setSuccessText] = useState();
  const [isMemberModalVisible, setMemberModalVisible] = useState(false);
  const [currentTab, setCurrentTab] = useState(1);
  const [offers, setOffers] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("");
  const [perPage, setPerPage] = useState("10");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 1,
    current: 1,
  });

  const onBuyerCheck = (e, index) => {
    let rawUnCheckedBuyers = unCheckedBuyers;
    let itemInde = rawUnCheckedBuyers.indexOf(index);
    if (isAllBuyersSelected) {
      if (itemInde !== -1 && e.target.checked) {
        rawUnCheckedBuyers.splice(itemInde, 1);
      } else {
        rawUnCheckedBuyers.push(index);
      }
    }
    setUnCheckedBuyers([...rawUnCheckedBuyers]);

    let rawCheckedBuyers = selectedBuyers;
    let itemIndex = rawCheckedBuyers.indexOf(index);
    if (itemIndex !== -1) {
      rawCheckedBuyers.splice(itemIndex, 1);
    } else {
      rawCheckedBuyers.push(index);
    }
    setSelectedBuyers([...rawCheckedBuyers]);
  };

  const selectAllBuyers = (e) => {
    setIsAllBuyersSelected(!isAllBuyersSelected);
    setUnCheckedBuyers([]);
    let allBuyersIds = [];
    if (e.target.checked) {
      if (currentTab === 2) {
        offers.forEach((item) => {
          allBuyersIds.push(item.id);
        });
      } else {
        data.forEach((item) => {
          allBuyersIds.push(item.id);
        });
      }
      console.log(allBuyersIds);
      setSelectedBuyers([...allBuyersIds]);
    } else {
      setSelectedBuyers([]);
    }
  };

  const getList = (data) => {
    let content = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      content.push(
        <CTableRow key={item.id}>
          {authCtx.userData?.user_type == 0 && (
            <CTableDataCell className="ant-table-cell">
              <input
                type="checkbox"
                className="form-check-input"
                checked={selectedBuyers.includes(item?.id)}
                onChange={(e) => onBuyerCheck(e, item?.id)}
                disabled={isAllBuyersSelected}
              />
            </CTableDataCell>
          )}
          <CTableDataCell className="ant-table-cell">
            {item.name}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.email}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.referral_id}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {formatPhoneNumber(item.mobile_no).formatted}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.completed}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.commission}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.status != 0 ? (
              <span className="user-status-active">Active</span>
            ) : (
              <span className="user-status-suspended">Inactive</span>
            )}
          </CTableDataCell>
        </CTableRow>
      );
    }
    return content;
  };

  const onBuyerDelete = () => {
    confirm({
      title: "Are you sure you want to delete selected members?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        setLoading(true);
        api
          .post(
            `buyers/delete-team-buyer`,
            { user_id: selectedBuyers },
            {
              headers: {
                Authorization: `Bearer ${authCtx.userData.token}`,
              },
            }
          )
          .then((response) => {
            setLoading(false);
            if (response.data.status) {
              refreshCtx.onRefresh();
              setSelectedBuyers([]);
              message.success(response.data.message);
            } else {
              message.error(response.data.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status == 401) {
              authCtx.logout();
            }
          });
      },
      onCancel() {},
    });
  };

  const getBuyers = async () => {
    await api
      .post(
        `buyers/buyer-list`,
        { user_id: id, user_check_id: selectedBuyers },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        if (response.data.status) {
          let buyersArray = [];
          if (response.data.data.length > 0) {
            response.data.data.map((element) => {
              buyersArray.push({
                value: element.id,
                label:
                  element.name == "" || element.name == null
                    ? element.email == "" || element.email == null
                      ? element.mobile_no
                      : element.email
                    : element.name,
              });
            });
            setBuyers(buyersArray);
          } else {
            setBuyers([]);
          }
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const onCancel = () => {
    setVisible(false);
  };

  const onRetractClick = () => {
    if (currentTab === 2) {
      confirm({
        title: `Are you sure want to add selected team members in the ${buyerName}(buyer's) account?`,
        icon: <ExclamationCircleFilled />,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        onOk() {
          setLoading(true);
          api
            .post(
              `buyers/assign-team-buyer`,
              { user_id: id, user_check_id: selectedBuyers },
              {
                headers: {
                  Authorization: `Bearer ${authCtx.userData.token}`,
                },
              }
            )
            .then((response) => {
              setLoading(false);
              if (response.data.status) {
                refreshCtx.onRefresh();
                setSelectedBuyers([]);
                setFilterSearch("");
                setCurrentTab(1);
                message.success(response.data.message);
              } else {
                message.error(response.data.message);
              }
            })
            .catch((error) => {
              setLoading(false);
              if (error.response.status == 401) {
                authCtx.logout();
              }
            });
        },
        onCancel() {},
      });
    } else {
      setVisible(true);
      getBuyers();
    }
  };

  const onConfirmRetract = async () => {
    setLoading(true);
    await api
      .post(
        `buyers/assign-team-buyer`,
        { user_id: buyerId, user_check_id: selectedBuyers },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          onCancel();
          refreshCtx.onRefresh();
          setSelectedBuyers([]);
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const mobile_no = watch("mobile_no");
  useEffect(() => {
    if (mobile_no != "" && mobile_no != undefined) {
      setValue("mobile_no", formatPhoneNumber(mobile_no).formatted);
    }
  }, [mobile_no]);

  const onSubmit = async (formData) => {
    setLoading(true);
    let rawData = {
      ...formData,
      mobile_no: formData.mobile_no.replace(/-/g, ""),
      parent_id: hashId,
    };
    await api
      .post("signupteam", rawData)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          refreshCtx.onRefresh();
          setSuccessText("Team member added successfully.");
          message.success(response.data.message);
          reset();
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const onAddMemberClick = useCallback(() => {
    setMemberModalVisible(true);
  }, []);

  const onCancelMember = useCallback(() => {
    setLoading(false);
    setMemberModalVisible(false);
    setSuccessText();
    reset();
  }, []);

  const menu = (
    <Menu>
      <Menu.Item key="0">
        <button
          type="button"
          className="bg-transparent border-0 d-block w-100 text-start"
          onClick={onRetractClick}>
          Retract
        </button>
      </Menu.Item>
      {currentTab === 1 && (
        <Menu.Item key="1">
          <button
            type="button"
            className="bg-transparent border-0 d-block w-100 text-start"
            onClick={onBuyerDelete}>
            Delete
          </button>
        </Menu.Item>
      )}
    </Menu>
  );

  const buyerActionDropdown = (
    <Dropdown overlay={menu}>
      <a
        className="input-select justify-content-start"
        onClick={(e) => e.preventDefault()}>
        Action
      </a>
    </Dropdown>
  );

  const [filterSearch, setFilterSearch] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(filterSearch), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [filterSearch]);

  useEffect(() => {
    if (currentTab === 2) {
      let filterData = {
        per_page: perPage,
      };
      if (debouncedValue != "") {
        filterData = {
          ...filterData,
          search: debouncedValue,
        };
      }
      fetchLeads(filterData, 1);
    }
  }, [refreshCtx.refresh, perPage, debouncedValue, currentTab]);

  async function fetchLeads(formData, page) {
    formData.user_id = id;
    setLoading(true);
    await api
      .post(`buyers/getotherbuyersteam?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setOffers([]);
        if (response.data.status) {
          let allBuyersIds = [];
          if (isAllBuyersSelected) {
            response.data.data.user.forEach((item) => {
              if (!selectedBuyers.includes(item.id)) {
                allBuyersIds.push(item.id);
              }
            });
            setSelectedBuyers([...selectedBuyers, ...allBuyersIds]);
          }
          setOffers(response.data.data.user);
          setPagination(response.data.data.paginate);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  function sort(field, currentField, sort, page) {
    let filterData = {
      per_page: perPage,
    };
    if (debouncedValue != "") {
      filterData = {
        ...filterData,
        search: debouncedValue,
      };
    }
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
          per_page: perPage,
        };
        fetchLeads(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
          per_page: perPage,
        };
        fetchLeads(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
        per_page: perPage,
      };
      fetchLeads(filterData, page);
    }
  }

  const perPageRecordHandler = (e) => {
    setPerPage(e.target.value);
  };

  const onPaginationFetch = (e) => {
    let filterData = {
      per_page: perPage,
    };
    if (debouncedValue != "") {
      filterData = {
        ...filterData,
        search: debouncedValue,
      };
    }
    fetchLeads(filterData, e.selected + 1);
  };

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <button
            type="button"
            className={`tab-text-btn ${currentTab === 1 ? "active" : ""}`}
            onClick={() => setCurrentTab(1)}>
            My Team
          </button>
          <button
            type="button"
            className={`tab-text-btn ${currentTab === 2 ? "active" : ""}`}
            onClick={() => setCurrentTab(2)}>
            Other Team
          </button>
        </Col>
      </Row>
      <div className="common-table-wrapper mt-0">
        {currentTab === 1 && (
          <Row justify="space-between" align="middle" className="mb-4">
            <Col>{selectedBuyers.length !== 0 && buyerActionDropdown}</Col>
            <Col>
              <Button
                block
                className="button-primary button-large-text AddTeamMemberBtn"
                htmltype="button"
                onClick={onAddMemberClick}>
                Add Team Member
              </Button>
            </Col>
          </Row>
        )}
        {currentTab === 2 && (
          <div className="row mb-4">
            <div className="col">
              {selectedBuyers.length !== 0 && buyerActionDropdown}
            </div>
            <div className="col-lg-3 col-12">
              <div className="input-col deals-search-filter">
                <input
                  type="text"
                  placeholder="Search here"
                  className="input-text"
                  value={filterSearch}
                  onChange={(e) => setFilterSearch(e.target.value)}
                />
                <CloseCircleOutlined
                  className="deals-search-clear-btn"
                  onClick={() => setFilterSearch("")}
                />
              </div>
            </div>
          </div>
        )}
        <div className="ant-table">
          <CTable className="table-layout">
            <CTableHead className="ant-table-thead">
              <CTableRow>
                {authCtx.userData?.user_type == 0 && (
                  <CTableHeaderCell className="ant-table-cell" scope="col">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      checked={isAllBuyersSelected}
                      onChange={(e) => selectAllBuyers(e)}
                    />
                  </CTableHeaderCell>
                )}
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Name{" "}
                  {currentTab === 2 && (
                    <span
                      onClick={(e) =>
                        sort("name", sortField, sortType, pagination.current)
                      }
                      className={`sortIcon ${
                        sortField == "name" ? sortType : ""
                      }`}></span>
                  )}
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Email{" "}
                  {currentTab === 2 && (
                    <span
                      onClick={(e) =>
                        sort("email", sortField, sortType, pagination.current)
                      }
                      className={`sortIcon ${
                        sortField == "email" ? sortType : ""
                      }`}></span>
                  )}
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Referral ID{" "}
                  {currentTab === 2 && (
                    <span
                      onClick={(e) =>
                        sort(
                          "referral_id",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }
                      className={`sortIcon ${
                        sortField == "referral_id" ? sortType : ""
                      }`}></span>
                  )}
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Phone Number{" "}
                  {currentTab === 2 && (
                    <span
                      onClick={(e) =>
                        sort(
                          "mobile_no",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }
                      className={`sortIcon ${
                        sortField == "mobile_no" ? sortType : ""
                      }`}></span>
                  )}
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Completed{" "}
                  {currentTab === 2 && (
                    <span
                      onClick={(e) =>
                        sort(
                          "completed",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }
                      className={`sortIcon ${
                        sortField == "completed" ? sortType : ""
                      }`}></span>
                  )}
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Commission{" "}
                  {currentTab === 2 && (
                    <span
                      onClick={(e) =>
                        sort(
                          "commission",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }
                      className={`sortIcon ${
                        sortField == "commission" ? sortType : ""
                      }`}></span>
                  )}
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Status{" "}
                  {currentTab === 2 && (
                    <span
                      onClick={(e) =>
                        sort("status", sortField, sortType, pagination.current)
                      }
                      className={`sortIcon ${
                        sortField == "status" ? sortType : ""
                      }`}></span>
                  )}
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="ant-table-tbody">
              {data && currentTab === 1 && getList(data)}
              {currentTab === 2 && getList(offers)}
              {isLoading && (
                <>
                  <CTableRow className="text-center">
                    <CTableDataCell className="ant-table-cell" colSpan={12}>
                      {<Spin />}
                    </CTableDataCell>
                  </CTableRow>
                </>
              )}
              {((currentTab === 1 && data?.length == 0) ||
                (currentTab === 2 && offers.length == 0 && !isLoading)) && (
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={8}>
                    No Data Found.
                  </CTableDataCell>
                </CTableRow>
              )}
            </CTableBody>
          </CTable>
        </div>
        {currentTab === 2 && pagination.total_records != undefined && (
          <>
            <ul className="ant-pagination ant-table-pagination ant-table-pagination-right">
              <li className="ant-pagination-total-text">
                {pagination.total_records} items
              </li>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                forcePage={parseInt(pagination?.current - 1)}
                onPageChange={onPaginationFetch}
                previousClassName="ant-pagination-prev"
                nextClassName="ant-pagination-next"
                pageRangeDisplayed={5}
                pageCount={pagination.total_pages}
                pageClassName="ant-pagination-item"
                previousLabel="<"
                activeClassName="ant-pagination-item-active"
                disabledClassName="ant-pagination-item ant-pagination-disabled"
                renderOnZeroPageCount={null}
              />
              <select className="input-select" onChange={perPageRecordHandler}>
                <option value="10">10 / Page</option>
                <option value="20">20 / Page</option>
                <option value="50">50 / Page</option>
                <option value="100">100 / Page</option>
              </select>
            </ul>
          </>
        )}
      </div>
      <Modal
        destroyOnClose
        closeIcon={<SvgClose />}
        footer={null}
        onCancel={onCancelMember}
        visible={isMemberModalVisible}
        width={successText ? 400 : 800}>
        {successText ? (
          <>
            <Row justify="center">
              <SvgSuccess />
            </Row>
            <h3 className="ds-title">{successText}</h3>
            <Row justify="center">
              <Button
                block
                className="button-primary"
                htmltype="submit"
                loading={isLoading}
                onClick={onCancelMember}
                type="primary">
                Close
              </Button>
            </Row>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="inquiryFormHeader">
                <h3 className="ds-title">Add Team Member Form</h3>
              </div>
              <h4 className="mb-4">Member Information</h4>
              <div className="row">
                <div className="col-lg-6">
                  <div className="formcol">
                    <input
                      className={`input-text ${
                        errors.name ? "is-invalid" : ""
                      }`}
                      placeholder="Full Name"
                      {...register("name", { required: true })}
                    />
                    {errors.name?.type === "required" && (
                      <span className="formError">This field is required</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="formcol">
                    <input
                      className={`input-text ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      placeholder="Email"
                      {...register("email", {
                        required: true,
                        pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      })}
                      autoComplete="off"
                    />
                    {errors.email?.type === "required" && (
                      <span className="formError">This field is required</span>
                    )}
                    {errors.email?.type === "pattern" && (
                      <span className="formError">Enter Valid Email</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="formcol">
                    <input
                      type={passwordShown ? "text" : "password"}
                      className={`input-text ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      placeholder="Password"
                      {...register("password", { required: true })}
                      autoComplete="off"
                    />
                    <i
                      className={`inputformPasswordToggle ${
                        passwordShown ? "show" : "hide"
                      }`}
                      onClick={togglePassword}></i>
                    {errors.password && (
                      <span className="formError">This field is required</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="formcol">
                    <input
                      type={passwordShown ? "text" : "password"}
                      className={`input-text ${
                        errors.confirmation_password ? "is-invalid" : ""
                      }`}
                      placeholder="Confirm Password"
                      {...register("confirmation_password", { required: true })}
                    />
                    <i
                      className={`inputformPasswordToggle ${
                        passwordShown ? "show" : "hide"
                      }`}
                      onClick={togglePassword}></i>
                    {errors.confirmPassword && (
                      <span className="formError">This field is required</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="formcol">
                    <input
                      type="text"
                      className={`input-text ${
                        errors.mobile_no ? "is-invalid" : ""
                      }`}
                      placeholder="Phone No"
                      {...register("mobile_no", { required: true })}
                      maxLength={12}
                    />
                    {errors.mobile_no && (
                      <span className="formError">This field is required</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="formcol">
                    <select
                      className={`input-select ${
                        errors.state ? "is-invalid" : ""
                      }`}
                      {...register("state", { required: true })}>
                      <option selected disabled>
                        State
                      </option>
                      {_.map(states, (state) => (
                        <option key={state.value} value={state.label}>
                          {state.label}
                        </option>
                      ))}
                    </select>
                    {errors.state && (
                      <span className="formError">This field is required</span>
                    )}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="formbtn">
                    <button
                      type="submit"
                      className="input-btn"
                      disabled={isLoading}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Modal>
      <Modal
        destroyOnClose
        closeIcon={<SvgClose />}
        footer={null}
        onCancel={onCancel}
        visible={isVisible}
        width={390}>
        <h3 className="ds-title">
          Please select buyer on below option to retract.
        </h3>
        <div>
          <div className="formcol">
            <label className="input-label">Buyers</label>
            <Select
              className="input-select"
              showSearch
              onChange={(value) => setBuyerId(value)}
              placeholder="Select Buyer"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }>
              {_.map(buyers, (buyer) => (
                <Option key={buyer.value} value={buyer.value}>
                  {buyer.label}
                </Option>
              ))}
            </Select>
          </div>
          <div className="formbtn">
            <button
              type="button"
              className="input-btn"
              onClick={onConfirmRetract}>
              Submit
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Team;
