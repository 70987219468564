import React, { useState, useCallback } from "react";

const AuthContext = React.createContext({
  userData: {},
  isLoggedIn: false,
  login: (data) => {},
  logout: () => {},
});

const retrieveStoredToken = () => {
  const storedUserData = JSON.parse(localStorage.getItem("userData"));
  return {
    storedUserData: storedUserData,
  };
};

export const AuthContextProvider = (props) => {
  const userDataOld = retrieveStoredToken();

  let initialUserData;
  if (userDataOld) {
    initialUserData = userDataOld.storedUserData;
  }

  const [userData, setUserData] = useState({
    ...initialUserData,
    permissions: [],
  });

  const userIsLoggedIn = userData?.id != undefined;

  const logoutHandler = useCallback(() => {
    setUserData();
    localStorage.removeItem("userData");
  }, []);

  const loginHandler = (data) => {
    setUserData(data);
    localStorage.setItem("userData", JSON.stringify(data));
  };

  const contextValue = {
    userData: userData,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
