import React, { useContext, useState } from "react";
import { Col, message, Row } from "antd";
import { useForm } from "react-hook-form";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";

const TeamAddMemberEmail = React.memo(({ onFinish }) => {
  const [loading, setLoading] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const authCtx = useContext(AuthContext);
  const onSubmit = async (formData) => {
    setLoading(true);
    formData.send_type = 2;
    if (authCtx.userData.user_type == 1) {
      formData.buyer_referral_id = authCtx.userData.referral_id;
    }
    await api
      .post(`buyers/add-buyer`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          onFinish(formData.email);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  return (
    <>
      <Row className="color-base" justify="center">
        <Col className="text-center padding-bottom-1_5">
          Enter an email address below to send a team invite link to your team
          member.
        </Col>
      </Row>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formcol">
          <input
            className={`input-text ${errors.email ? "is-invalid" : ""}`}
            placeholder="Email"
            {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}
          />
          {errors.email?.type === "required" && (
            <span className="formError">This field is required</span>
          )}
          {errors.email?.type === "pattern" && (
            <span className="formError">Enter Valid Email</span>
          )}
        </div>
        {authCtx.userData.user_type == 0 && (
          <div className="formcol">
            <input
              className={`input-text ${
                errors.buyer_referral_id ? "is-invalid" : ""
              }`}
              placeholder="Buyer Referral ID"
              name="text"
              {...register("buyer_referral_id", {
                required: true,
              })}
            />
            {errors.buyer_referral_id?.type === "required" && (
              <span className="formError">Buyer Referral ID is required.</span>
            )}
          </div>
        )}
        <div className="formbtn">
          <button type="submit" className="input-btn">
            Send Email Link
          </button>
        </div>
      </form>
    </>
  );
});

export default TeamAddMemberEmail;
