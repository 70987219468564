import React, { useContext, useState } from "react";
import _ from "lodash";
import { useForm } from "react-hook-form";
import { Drawer, message } from "antd";
import CommonDrawerSection from "../../Common/Drawer/Section";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";
import RefreshContext from "../../../store/refreshContext";
import moment from "moment";

const PaymentHistory = React.memo(({ id }) => {
  const [buyerId, setBuyerId] = useState();
  const [isLoading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);
  const onSubmit = async (formData) => {
    setLoading(true);
    formData.user_id = id;
    await api
      .post(`payments/add-payment`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          reset();
          onClose();
          refreshCtx.onRefresh();
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const onClose = () => {
    setBuyerId();
    reset();
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-5">
        <h2 className="mb-0">Finance Activity</h2>
        <button
          className="ant-btn ant-btn-primary ms-auto"
          onClick={(e) => setBuyerId(id)}
        >
          Add Payment
        </button>
      </div>
      <Drawer
        className="buyer-detail"
        destroyOnClose
        mask={false}
        onClose={onClose}
        placement="right"
        title="Add Payment"
        visible={!!buyerId}
        width={500}
      >
        <CommonDrawerSection>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formcol">
              <input
                className={`input-text ${
                  errors.payment_date ? "is-invalid" : ""
                }`}
                placeholder="Date"
                type="date"
                {...register("payment_date", {
                  required: true,
                })}
                max={moment().format("YYYY-MM-DD")}
              />
              {errors.payment_date?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formcol">
              <select
                className={`input-select ${
                  errors.payment_date ? "is-invalid" : ""
                }`}
                {...register("payment_type", {
                  required: true,
                })}
              >
                <option value="">Payment Method</option>
                <option value="ACH">ACH</option>
                <option value="CHECK">CHECK</option>
                <option value="WIRE">WIRE</option>
              </select>
              {errors.payment_type?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formcol">
              <input
                className={`input-text ${errors.payout ? "is-invalid" : ""}`}
                placeholder="Total Payment"
                {...register("payout", {
                  required: true,
                })}
              />
              {errors.payout?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formcol">
              <input
                className={`input-text ${
                  errors.payment_id ? "is-invalid" : ""
                }`}
                placeholder="Payment Tracking ID"
                {...register("payment_id", {
                  required: true,
                })}
              />
              {errors.payment_id?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formbtn">
              <button type="submit" className="input-btn">
                Add Payment
              </button>
            </div>
          </form>
        </CommonDrawerSection>
      </Drawer>
    </>
  );
});

export default PaymentHistory;
