import React, { useCallback, useContext, useState } from "react";
import { Button, Col, message, Modal, Row } from "antd";
import _ from "lodash";
import SvgClose from "../../../images/close";
import SvgSuccess from "../../../images/success";
import AuthContext from "../../../store/authContext";
import { useForm } from "react-hook-form";
import api from "../../../config/api";
import moment from "moment";
import axiosRetry from "axios-retry";

const SendSms = React.memo(
  ({
    selectedBuyers,
    isAllBuyers,
    totalBuyers,
    search,
    selectedDate,
    onSmsSuccess,
  }) => {
    const [loading, setLoading] = useState(false);
    const [successText, setSuccessText] = useState();
    const [isVisible, setVisible] = useState(false);
    const [templates, setTemplates] = useState([]);
    const authCtx = useContext(AuthContext);

    const onCancel = useCallback(() => {
      setVisible(false);
      reset();
    }, []);

    const onSendSmsClick = useCallback(() => {
      setSuccessText(null);
      setVisible(true);
      getTemplates();
    }, []);

    const getTemplates = async () => {
      await api
        .post(
          `smstemplate`,
          { user_id: authCtx.userData?.id, per_page: 100, is_active: 1 },
          {
            headers: {
              Authorization: `Bearer ${authCtx.userData.token}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            let templatesArray = [];
            if (response.data.data.lead.length > 0) {
              response.data.data.lead.map((element) => {
                templatesArray.push({
                  value: element.id,
                  label: element.name,
                });
              });
              setTemplates(templatesArray);
            } else {
              setTemplates([]);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status == 401) {
            authCtx.logout();
          }
        });
    };

    const {
      register,
      handleSubmit,
      reset,
      setValue,
      formState: { errors },
    } = useForm();

    const getTemplateContent = async (id) => {
      await api
        .post(
          `smstemplate/get-smstemplate`,
          {
            p_id: id,
            user_id: authCtx.userData?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${authCtx?.userData?.token}`,
            },
          }
        )
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            setValue("content", response.data.data?.content);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    };

    const onSubmit = async (formData) => {
      setLoading(true);
      let per_page = 50;
      let pages = 1;
      let buyerData = {
        buyer_id: selectedBuyers,
        content: formData.content,
        per_page: per_page,
        send_time: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      if (isAllBuyers) {
        buyerData = {
          ...buyerData,
          buyer_id: "all",
        };
        pages = Math.ceil(parseInt(totalBuyers) / per_page);
      } else if (parseInt(selectedBuyers.length) > 50) {
        pages = Math.ceil(parseInt(selectedBuyers.length) / per_page);
      }
      if (search != "" || selectedDate != null) {
        buyerData = {
          ...buyerData,
          buyer_id: "fillter",
        };
      }
      if (search != "") {
        if (isAllBuyers) {
          buyerData = {
            ...buyerData,
            search: search,
          };
        } else {
          buyerData = {
            ...buyerData,
            search: selectedBuyers,
          };
        }
      }
      if (selectedDate != null) {
        buyerData = {
          ...buyerData,
          start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
          end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
        };
      }
      axiosRetry(api, { retries: 5 });
      for (let i = 1; i <= pages; i++) {
        api
          .post(`smstemplate/sendsmsbuyers?page=${i}`, buyerData, {
            headers: {
              Authorization: `Bearer ${authCtx.userData.token}`,
            },
          })
          .then((response) => {
            if (response.data.status) {
              if (i == pages) {
                setLoading(false);
                reset();
                onCancel();
                onSmsSuccess();
                message.success(response.data.message);
              }
            } else {
              setLoading(false);
              message.error(response.data.message);
            }
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status == 401) {
              authCtx.logout();
            }
          });
      }
    };

    return (
      <>
        <Button
          className="button-primary"
          htmltype="button"
          onClick={onSendSmsClick}
          type="primary"
        >
          Send SMS
        </Button>
        <Modal
          destroyOnClose
          closeIcon={<SvgClose />}
          footer={null}
          onCancel={onCancel}
          visible={isVisible}
          width={390}
        >
          {successText ? (
            <>
              <Row justify="center">
                <SvgSuccess />
              </Row>
              <h3 className="ds-title">SMS Sended Successfully!</h3>
              <p className="text-center">
                A team invite has been sent to {successText}
              </p>
              <Row justify="center">
                <Button
                  block
                  className="button-primary"
                  htmltype="submit"
                  loading={loading}
                  onClick={onCancel}
                >
                  Close
                </Button>
              </Row>
            </>
          ) : (
            <>
              <h3 className="ds-title">Send SMS</h3>
              <Row className="color-base" justify="center">
                <Col className="text-center padding-bottom-1_5">
                  Please select sms template option for default message.
                </Col>
              </Row>

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formcol">
                  <label className="input-label">Template</label>
                  <select
                    className="input-select"
                    onChange={(e) => getTemplateContent(e.target.value)}
                  >
                    <option>Select Template</option>
                    {_.map(templates, (template) => (
                      <option key={template.value} value={template.value}>
                        {template.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="formcol">
                  <label className="input-label">Content</label>
                  <textarea
                    className="input-text"
                    placeholder="Enter content here"
                    {...register("content", { required: true })}
                  ></textarea>
                  {errors.content?.type === "required" && (
                    <span className="formError">This field is required</span>
                  )}
                </div>
                <div className="formbtn">
                  <button
                    type="submit"
                    className="input-btn"
                    disabled={loading}
                  >
                    Send
                  </button>
                </div>
              </form>
            </>
          )}
        </Modal>
      </>
    );
  }
);

export default SendSms;
