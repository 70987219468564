import React from 'react';

const SvgDropdown = props => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2929 5.33334C12.7122 5.33334 12.9453 5.81831 12.6834 6.14569L8.39035 11.512C8.19019 11.7622 7.80965 11.7622 7.60948 11.512L3.31646 6.14569C3.05456 5.81831 3.28764 5.33334 3.7069 5.33334L12.2929 5.33334Z"
      fill="#92929D"
    />
  </svg>
);

export default SvgDropdown;
