import React, { useCallback, useContext, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Layout, Menu, Row } from "antd";
import { isMobile } from "react-device-detect";
import CommonUserMenu from "../Layout/UserMenu";
import SvgDashboard from "../../images/dashboard";
import SvgMenu from "../../images/menu";
import SvgOffers from "../../images/offers";
import SvgProfile from "../../images/profile";
import SvgTeam from "../../images/team";
import routes from "../../config/routes";
import "./LoggedIn.scss";
import SvgLogo from "../../images/logo";
import AuthContext from "../../store/authContext";
import api from "../../config/api";
import SvgCompany from "../../images/company";
import SvgFilter from "../../images/filter";
import SvgRole from "../../images/role";
import SvgSms from "../../images/sms";
import SvgTemplate from "../../images/template";
import SvgPages from "../../images/pages";
import SvgPayment from "../../images/payment";

const { Content, Header, Sider } = Layout;

const LayoutsLoggedIn = ({ children }) => {
  const [isCollapsed, setCollapsed] = useState(isMobile);
  const [isBroken, setBroken] = useState("inline");

  const collapseSider = useCallback(() => {
    setCollapsed(true);
  }, []);

  const onCollapse = useCallback((collapsed) => setCollapsed(collapsed), []);

  const onBreakpoint = useCallback((broken) => {
    setBroken(broken);
    setCollapsed(true);
  }, []);

  const authCtx = useContext(AuthContext);

  let location = useLocation();
  useEffect(() => {
    api
      .get(`role/get-role-permission/${authCtx?.userData?.role_id}`, {
        headers: {
          Authorization: `Bearer ${authCtx?.userData?.token}`,
        },
      })
      .then((response) => {
        let rawRoles = [];
        if (response.data.data?.permission.length != 0) {
          response.data.data?.permission.forEach((role) => {
            role.permission.forEach((permission) => {
              rawRoles.push(permission.permission_code);
            });
          });
        }
        authCtx.login({
          ...authCtx.userData,
          permissions: rawRoles,
          allowAll: response.data.data.allow_everything,
        });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [location]);

  return (
    <Layout
      className={`layouts-logged-in ${
        isBroken && !isCollapsed ? `sider-visible` : ""
      }`}
    >
      <Header>
        <div className="row justify-content-between">
          <div className="layouts-logged-in-logo col-5 col-lg-auto order-1">
            <SvgLogo />
          </div>
          <div className="col-12 col-lg d-flex align-items-center justify-content-center order-3 order-lg-2">
            <h3 className="header-cars-text m-0">
              iLusso Affiliate Referral Program
            </h3>
          </div>
          <div className="col-5 col-lg-auto order-2 order-lg-3">
            <Row align="middle" justify="end">
              <CommonUserMenu />
            </Row>
          </div>
        </div>
      </Header>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsed={isBroken && isCollapsed}
          collapsedWidth="0"
          onBreakpoint={onBreakpoint}
          onCollapse={onCollapse}
          trigger={<SvgMenu isOpen={!isCollapsed} />}
          width={210}
        >
          <Menu mode="inline" selectable={false}>
            <Menu.ItemGroup title="Main">
              <Menu.Item key="dashboard" title="Dashboard">
                <NavLink
                  exact="true"
                  onClick={collapseSider}
                  to={routes.Dashboard}
                >
                  <SvgDashboard /> Dashboard
                </NavLink>
              </Menu.Item>
              {(authCtx.userData?.user_type == 0 ||
                authCtx.userData?.allowAll == 1 ||
                authCtx.userData?.permissions.includes("my_deals_view")) && (
                <Menu.Item key="deals" title="Deals">
                  <NavLink onClick={collapseSider} to={routes.Deals}>
                    <SvgOffers /> Deals
                  </NavLink>
                </Menu.Item>
              )}
              {(authCtx.userData?.user_type == 0 ||
                authCtx.userData?.allowAll == 1 ||
                authCtx.userData?.permissions.includes("my_leads_view")) && (
                <Menu.Item key="leads" title="My Leads">
                  <NavLink onClick={collapseSider} to={routes.MyLeads}>
                    <SvgFilter /> Leads
                  </NavLink>
                </Menu.Item>
              )}
              {((authCtx.userData?.user_type == 1 &&
                authCtx.userData?.allowAll == 1) ||
                authCtx.userData?.permissions.includes(
                  "payment_history_view"
                )) && (
                <Menu.Item key="payment_history" title="Payment History">
                  <NavLink onClick={collapseSider} to={routes.PaymentHistory}>
                    <SvgPayment />
                    Payment History
                  </NavLink>
                </Menu.Item>
              )}
              {(authCtx.userData?.user_type == 0 ||
                authCtx.userData?.allowAll == 1 ||
                authCtx.userData?.permissions.includes("my_teams_view")) && (
                <Menu.Item key="buyers" title="Buyers">
                  <NavLink onClick={collapseSider} to={routes.Buyers}>
                    <SvgTeam />
                    {authCtx.userData?.user_type == 0 ? "Buyers" : "My Team"}
                  </NavLink>
                </Menu.Item>
              )}
              {(authCtx.userData?.user_type == 0 ||
                authCtx.userData?.allowAll == 1) && (
                <Menu.Item key="company" title="Company">
                  <NavLink onClick={collapseSider} to={routes.Company}>
                    <SvgCompany /> Company
                  </NavLink>
                </Menu.Item>
              )}
              {(authCtx.userData?.user_type == 0 ||
                authCtx.userData?.allowAll == 1 ||
                authCtx.userData?.permissions.includes("role_view")) && (
                <Menu.Item key="role" title="Role">
                  <NavLink onClick={collapseSider} to={routes.Role}>
                    <SvgRole />
                    Role
                  </NavLink>
                </Menu.Item>
              )}
              {authCtx.userData?.user_type == 0 && (
                <>
                  <Menu.Item key="mailbox" title="Mail Box">
                    <NavLink onClick={collapseSider} to={routes.Mailbox}>
                      <SvgSms />
                      Mail Box
                    </NavLink>
                  </Menu.Item>
                  <Menu.Item key="template" title="Templates">
                    <NavLink onClick={collapseSider} to={routes.Template}>
                      <SvgTemplate />
                      SMS Templates
                    </NavLink>
                  </Menu.Item>
                </>
              )}
              {authCtx.userData?.user_type == 1 && (
                <Menu.Item key="SmsHistoryBuyer" title="SMS History">
                  <NavLink onClick={collapseSider} to={routes.SmsHistoryBuyer}>
                    <SvgSms /> SMS History
                  </NavLink>
                </Menu.Item>
              )}
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Pages">
              <Menu.Item key="resources" title="Resources">
                <NavLink onClick={collapseSider} to={routes.Resources}>
                  <SvgPages />
                  Resources
                </NavLink>
              </Menu.Item>
              <Menu.Item key="terms" title="Terms and Conditions">
                <NavLink onClick={collapseSider} to={routes.Terms}>
                  <SvgPages />
                  Terms
                </NavLink>
              </Menu.Item>
              <Menu.Item key="privacy" title="Privacy Policy">
                <NavLink onClick={collapseSider} to={routes.Privacy}>
                  <SvgPages />
                  Privacy Policy
                </NavLink>
              </Menu.Item>
              <Menu.Item key="payouts" title="Payouts">
                <NavLink onClick={collapseSider} to={routes.Payouts}>
                  <SvgPages />
                  Payouts
                </NavLink>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Account">
              {authCtx.userData?.user_type == 0 && (
                <Menu.Item key="profile" title="Profile">
                  <NavLink onClick={collapseSider} to={routes.Profile}>
                    <SvgProfile /> Profile
                  </NavLink>
                </Menu.Item>
              )}
              {(authCtx.userData?.user_type == 1 ||
                (authCtx.userData?.user_type == 1 &&
                  authCtx.userData?.allowAll == 1)) && (
                <Menu.Item key="BuyerAccount" title="My Profile">
                  <NavLink onClick={collapseSider} to={routes.BuyerAccount}>
                    <SvgProfile /> My Profile
                  </NavLink>
                </Menu.Item>
              )}
            </Menu.ItemGroup>
          </Menu>
        </Sider>
        <Layout>
          <Content className="layout-content">{children}</Content>
        </Layout>
      </Layout>
      <div className="mobile-sider-blocker" onClick={collapseSider} />
    </Layout>
  );
};

export default LayoutsLoggedIn;
