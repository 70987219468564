import React, { useContext, useEffect, useState } from "react";
import { Col, Drawer, Row, Spin } from "antd";
import _ from "lodash";
import CommonDrawerSection from "../../Common/Drawer/Section";
import CommonDrawerSectionHeader from "../../Common/Drawer/SectionHeader";
import CommonDrawerInfo from "../../Common/Drawer/Info";
import AuthContext from "../../../store/authContext";
import RefreshContext from "../../../store/refreshContext";
import api from "../../../config/api";

const BuyerInformation = React.memo(({ buyerId, onClose }) => {
  const [isLoading, setLoading] = useState(false);
  const [buyerDetail, setBuyerDetail] = useState({});

  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);

  useEffect(() => {
    if (buyerId) {
      fetchBuyerDetail();
    }
  }, [buyerId, refreshCtx.refresh]);

  async function fetchBuyerDetail() {
    setLoading(true);
    await api
      .post(
        `buyers/get-buyer`,
        { user_id: buyerId },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setBuyerDetail(response.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  return (
    <Drawer
      className="buyer-detail"
      destroyOnClose
      mask={false}
      onClose={onClose}
      placement="right"
      title="Buyer Information"
      visible={!!buyerId}
      width={500}>
      {isLoading ? (
        <Spin />
      ) : (
        <CommonDrawerSection>
          <div className="offers-details-data-group">
            <CommonDrawerSectionHeader title="Buyer" />
            <Row gutter={16}>
              <Col span={24}>
                <CommonDrawerInfo title="Full Name:">
                  {buyerDetail?.name}
                </CommonDrawerInfo>
              </Col>
              <Col span={24}>
                <CommonDrawerInfo title="Referral Id:">
                  {buyerDetail?.referral_id}
                </CommonDrawerInfo>
              </Col>
              <Col span={24}>
                <CommonDrawerInfo title="Email Address:">
                  {buyerDetail?.email}
                </CommonDrawerInfo>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <CommonDrawerInfo title="Address:">
                  {buyerDetail?.address}
                </CommonDrawerInfo>
              </Col>
              <Col span={24}>
                <CommonDrawerInfo title="Address 2:">
                  {buyerDetail?.address2}
                </CommonDrawerInfo>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <CommonDrawerInfo title="City:">
                  {buyerDetail.city}
                </CommonDrawerInfo>
              </Col>
              <Col span={12}>
                <CommonDrawerInfo title="State:">
                  {buyerDetail?.state}
                </CommonDrawerInfo>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <CommonDrawerInfo title="Zipcode:">
                  {buyerDetail?.zip_code}
                </CommonDrawerInfo>
              </Col>
              <Col span={12}>
                <CommonDrawerInfo title="Phone Number:">
                  <a href={`tel:${buyerDetail?.mobile_no}`}>
                    {buyerDetail?.mobile_no}
                  </a>
                </CommonDrawerInfo>
              </Col>
            </Row>
          </div>
          <div className="offers-details-data-group">
            <CommonDrawerSectionHeader title="Completed Deals" />
            <Row gutter={16}>
              <Col span={12}>
                <CommonDrawerInfo title="Total Deals">
                  {buyerDetail?.buyerStatsActivity?.totalDealsCount}
                </CommonDrawerInfo>
              </Col>
              <Col span={12}>
                <CommonDrawerInfo title="Pending Deals">
                  {buyerDetail?.buyerStatsActivity?.pendingDealsCount}
                </CommonDrawerInfo>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <CommonDrawerInfo title="Completed Deals">
                  {buyerDetail?.buyerStatsActivity?.completedDealsCount}
                </CommonDrawerInfo>
              </Col>
              <Col span={12}>
                <CommonDrawerInfo title="Rejected Deals">
                  {buyerDetail?.buyerStatsActivity?.rejectedDealsCount}
                </CommonDrawerInfo>
              </Col>
            </Row>
          </div>
          <div className="offers-details-data-group">
            <CommonDrawerSectionHeader title="Commission" />
            <Row gutter={16}>
              <Col span={12}>
                <CommonDrawerInfo title="iLusso Purchase Amount">
                  ${buyerDetail?.financeActivity?.totalDealsAmountCompleted}
                </CommonDrawerInfo>
              </Col>
              <Col span={12}>
                <CommonDrawerInfo title="Earned Commision">
                  ${buyerDetail?.financeActivity?.buyersEarnedCommission}
                </CommonDrawerInfo>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <CommonDrawerInfo title="Total Form Sent">
                  {buyerDetail?.buyerStatsActivity?.totalFormSentCount}
                </CommonDrawerInfo>
              </Col>
              <Col span={12}>
                <CommonDrawerInfo title="Total Form Clicked">
                  {buyerDetail?.buyerStatsActivity?.totalFormClicked}
                </CommonDrawerInfo>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <CommonDrawerInfo title="Total Form Submmited">
                  {buyerDetail?.buyerStatsActivity?.totalFormSubmitted}
                </CommonDrawerInfo>
              </Col>
            </Row>
          </div>
        </CommonDrawerSection>
      )}
    </Drawer>
  );
});

export default BuyerInformation;
