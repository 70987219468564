import React, { useCallback, useContext, useEffect, useState } from "react";
import moment from "moment";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
} from "@coreui/react";
import { Col, Row } from "antd";
import "./index.scss";
import _ from "lodash";
import LayoutsLoggedIn from "../Layout/LoggedIn";
import api from "../../config/api";
import AuthContext from "../../store/authContext";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import { CloseCircleOutlined } from "@ant-design/icons";
import calenderIcon from "../../images/calender.svg";
import ReactPaginate from "react-paginate";
import SvgThreeDots from "../../images/three-dots";
import RefreshContext from "../../store/refreshContext";
import { Spin } from "antd";
import { Link } from "react-router-dom";
import OffersDetails from "./Details";

const Deals = () => {
  const [filterCount, setFilterCount] = useState({});
  const [filterOption, setFilterOption] = useState("");
  const [filterSearch, setFilterSearch] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [offers, setOffers] = useState([]);
  const [selectedDeal, setSelectedDeal] = useState();
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("");
  const [perPage, setPerPage] = useState("10");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 1,
    current: 1,
  });
  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);

  async function fetchFilters() {
    await api
      .post(
        `deals/get-status-count`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setFilterCount(response.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  useEffect(() => {
    fetchFilters();
  }, [refreshCtx.refresh]);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(filterSearch), 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [filterSearch]);

  const resetSearchValue = () => {
    setFilterOption("");
    setFilterSearch("");
    setSelectedDate(null);
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const onDealRangeChange = (value) => {
    setSelectedDate(value);
  };

  useEffect(() => {
    let filterData = {
      per_page: perPage,
    };
    if (filterOption != "") {
      filterData = {
        ...filterData,
        status: filterOption,
      };
    }
    if (debouncedValue != "") {
      filterData = {
        ...filterData,
        search: debouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    fetchDeals(filterData, 1);
    setSelectedDeal();
  }, [filterOption, debouncedValue, refreshCtx.refresh, selectedDate, perPage]);

  const onClose = () => {
    setSelectedDeal();
  };

  const onUpdateLead = useCallback(() => {
    refreshCtx.onRefresh();
  });

  const getList = (data) => {
    let content = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      content.push(
        <CTableRow key={item.id} onClick={() => setSelectedDeal(item.id)}>
          {authCtx.userData?.user_type == 0 && (
            <CTableDataCell className="ant-table-cell">
              <Link to={`/buyers/detail/${item.user_id}`}>{item.userName}</Link>
            </CTableDataCell>
          )}
          <CTableDataCell className="ant-table-cell">
            {item.leadName}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.vehicle_year}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.vehicle_make}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.vehicle_model}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.vehicle_trim}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.offer_comment || "\u2014"}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.status == 0 && (
              <span className="leadStatusSelectBox offer-status-0">New</span>
            )}
            {item.status == 1 && (
              <span className="leadStatusSelectBox offer-status-1">
                Working
              </span>
            )}
            {item.status == 2 && (
              <span className="leadStatusSelectBox offer-status-2">
                Approved
              </span>
            )}
            {item.status == 3 && (
              <span className="leadStatusSelectBox offer-status-3">
                Rejected
              </span>
            )}
            {item.status == 4 && (
              <span className="leadStatusSelectBox offer-status-4">
                Completed
              </span>
            )}
            {item.status == 5 && (
              <span className="leadStatusSelectBox offer-status-5">
                Consignment
              </span>
            )}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {moment(item.created_at).utc().format("M/D/YY")}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            ${Number(item.commission != null ? item.commission : 0)}
            /$
            {Number(
              item.parent_commission != null ? item.parent_commission : 0
            )}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            <SvgThreeDots />
          </CTableDataCell>
        </CTableRow>
      );
    }
    return content;
  };

  function sort(field, currentField, sort, page) {
    let filterData = {
      per_page: perPage,
    };
    if (filterOption != "") {
      filterData = {
        ...filterData,
        status: filterOption,
      };
    }
    if (debouncedValue != "") {
      filterData = {
        ...filterData,
        search: debouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "asc",
          per_page: perPage,
        };
        fetchDeals(filterData, page);
      } else {
        setSortType("desc");
        filterData = {
          ...filterData,
          field_name: field,
          order_by: "desc",
          per_page: perPage,
        };
        fetchDeals(filterData, page);
      }
    } else {
      setSortField(field);
      setSortType("desc");
      filterData = {
        ...filterData,
        field_name: field,
        order_by: "desc",
        per_page: perPage,
      };
      fetchDeals(filterData, page);
    }
  }

  async function fetchDeals(formData, page) {
    await api
      .post(`deals?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setOffers([]);
        if (response.data.status) {
          setOffers(response.data.data.lead);
          setPagination(response.data.data.paginate);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  const perPageRecordHandler = (e) => {
    setPerPage(e.target.value);
  };

  const onPaginationFetch = (e) => {
    let filterData = {
      per_page: perPage,
    };
    if (filterOption != "") {
      filterData = {
        ...filterData,
        status: filterOption,
      };
    }
    if (debouncedValue != "") {
      filterData = {
        ...filterData,
        search: debouncedValue,
      };
    }
    if (selectedDate != null) {
      filterData = {
        ...filterData,
        start_date: moment(selectedDate[0]).format("YYYY-MM-DD"),
        end_date: moment(selectedDate[1]).format("YYYY-MM-DD"),
      };
    }
    fetchDeals(filterData, e.selected + 1);
  };

  return (
    <LayoutsLoggedIn>
      <Row align="middle" className="offers-page">
        <Col>
          <h2 className="mb-0">Deals</h2>
        </Col>
        <Col flex="auto">
          {!isLoading && (
            <div className="offers-page-filters">
              <button
                onClick={(e) => setFilterOption("0")}
                className="filter-btn new"
              >
                New ({filterCount.newLeadCount})
              </button>
              <button
                onClick={(e) => setFilterOption("1")}
                className="filter-btn working"
              >
                Working ({filterCount.workingLeadCount})
              </button>
              <button
                onClick={(e) => setFilterOption("2")}
                className="filter-btn approved"
              >
                Approved ({filterCount.approvedLeadCount})
              </button>
              <button
                onClick={(e) => setFilterOption("3")}
                className="filter-btn rejected"
              >
                Rejected ({filterCount.rejectedLeadCount})
              </button>
              <button
                onClick={(e) => setFilterOption("4")}
                className="filter-btn completed"
              >
                Completed ({filterCount.completedLeadCount})
              </button>
              <button
                onClick={(e) => setFilterOption("5")}
                className="filter-btn consignment"
              >
                Consignment ({filterCount.onConsignmentLeadCount})
              </button>
              <button
                onClick={(e) => resetSearchValue()}
                className="filter-btn reset"
              >
                Reset
              </button>
            </div>
          )}
        </Col>
        <Col lg style={{ paddingRight: "15px" }}>
          <DateRangePicker
            onChange={(e) => onDealRangeChange(e)}
            value={selectedDate}
            format="M-d-y"
            yearPlaceholder="YYYY"
            monthPlaceholder="MM"
            dayPlaceholder="DD"
            calendarIcon={<img src={calenderIcon} style={{ height: "17px" }} />}
          />
        </Col>
        <Col lg={4} xs={24}>
          <div className="input-col deals-search-filter">
            <input
              type="text"
              placeholder="Search here"
              className="input-text"
              value={filterSearch}
              onChange={(e) => setFilterSearch(e.target.value)}
            />
            <CloseCircleOutlined
              className="deals-search-clear-btn"
              onClick={() => setFilterSearch("")}
            />
          </div>
        </Col>
      </Row>
      <div className="common-table-wrapper">
        <li className="ant-pagination-total-text">
          {pagination.total_records} items
        </li>
        <div className="ant-table">
          <CTable className="table-layout">
            <CTableHead className="ant-table-thead">
              <CTableRow>
                {(authCtx.userData?.user_type == 0 ||
                  authCtx.userData?.allowAll == 1) && (
                  <CTableHeaderCell
                    width="10%"
                    className="ant-table-cell"
                    scope="col"
                  >
                    Buyer Name
                    <span
                      onClick={(e) =>
                        sort(
                          "userName",
                          sortField,
                          sortType,
                          pagination.current
                        )
                      }
                      className={`sortIcon ${
                        sortField == "userName" ? sortType : ""
                      }`}
                    ></span>
                  </CTableHeaderCell>
                )}
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col"
                >
                  Owner Name
                  <span
                    onClick={(e) =>
                      sort("leadName", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "leadName" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="7%"
                  className="ant-table-cell"
                  scope="col"
                >
                  Year
                  <span
                    onClick={(e) =>
                      sort(
                        "vehicle_year",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "vehicle_year" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col"
                >
                  Make
                  <span
                    onClick={(e) =>
                      sort(
                        "vehicle_make",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "vehicle_make" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col"
                >
                  Model
                  <span
                    onClick={(e) =>
                      sort(
                        "vehicle_model",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "vehicle_model" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col"
                >
                  Trim
                  <span
                    onClick={(e) =>
                      sort(
                        "vehicle_trim",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "vehicle_trim" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="20%"
                  className="ant-table-cell"
                  scope="col"
                >
                  Deal Comment
                  <span
                    onClick={(e) =>
                      sort(
                        "offer_comment",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "offer_comment" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col"
                >
                  Status
                  <span
                    onClick={(e) =>
                      sort("status", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "status" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col"
                >
                  Date
                  <span
                    onClick={(e) =>
                      sort(
                        "created_at",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "created_at" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="10%"
                  className="ant-table-cell"
                  scope="col"
                >
                  Commission
                </CTableHeaderCell>
                <CTableHeaderCell
                  width="5%"
                  className="ant-table-cell"
                  scope="col"
                >
                  Action
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="ant-table-tbody">
              {isLoading && (
                <>
                  <CTableRow className="text-center">
                    <CTableDataCell className="ant-table-cell" colSpan={12}>
                      {<Spin />}
                    </CTableDataCell>
                  </CTableRow>
                </>
              )}
              {!isLoading && offers.length == 0 && (
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={12}>
                    No Data Found.
                  </CTableDataCell>
                </CTableRow>
              )}
              {getList(offers)}
            </CTableBody>
          </CTable>
        </div>
        {pagination.total_records != undefined && (
          <>
            <ul className="ant-pagination ant-table-pagination ant-table-pagination-right">
              <li className="ant-pagination-total-text">
                {pagination.total_records} items
              </li>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={onPaginationFetch}
                forcePage={parseInt(pagination?.current - 1)}
                previousClassName="ant-pagination-prev"
                nextClassName="ant-pagination-next"
                pageRangeDisplayed={5}
                pageCount={pagination.total_pages}
                pageClassName="ant-pagination-item"
                previousLabel="<"
                activeClassName="ant-pagination-item-active"
                disabledClassName="ant-pagination-item ant-pagination-disabled"
                renderOnZeroPageCount={null}
              />
              <select className="input-select" onChange={perPageRecordHandler}>
                <option value="10">10 / Page</option>
                <option value="20">20 / Page</option>
                <option value="50">50 / Page</option>
                <option value="100">100 / Page</option>
              </select>
            </ul>
          </>
        )}
      </div>
      {(authCtx.userData?.user_type == 0 ||
        authCtx.userData?.allowAll == 1 ||
        authCtx.userData?.permissions.includes("my_deals_edit")) && (
        <OffersDetails
          onClose={onClose}
          id={selectedDeal}
          onUpdateLead={onUpdateLead}
        />
      )}
    </LayoutsLoggedIn>
  );
};

export default Deals;
