import React from "react";

import { CCard, CCardBody, CCol, CRow } from "@coreui/react";
import _ from "lodash";
const LeadStat = (props) => {
  return (
    <CRow>
      <CCol xs>
        <CCard className="leadActivityCard mb-4">
          <CCardBody style={{ backgroundColor: "#1C1C24" }}>
            <CRow>
              <CCol xs={12} md={6} xl={6}>
                <CRow>
                  <CCol sm={6}>
                    <div
                      className="leadActivityBox box-1 py-1"
                      style={{ backgroundColor: "#1C1C24" }}
                    >
                      <div className="leadActivityCount">Lead Activity</div>
                      <div className="leadActivityTitle fw-semibold">
                        as of {props.leadActivity?.leadActivityDate} CST
                      </div>
                    </div>
                  </CCol>
                  <CCol sm={6}>
                    <div className="leadActivityBox box-2 py-1">
                      <div
                        style={{ color: "#92929E" }}
                        className="leadActivityTitle text-medium-emphasis small"
                      >
                        New Leads
                      </div>
                      <div className="leadActivityCount fs-5 fw-semibold">
                        {props.leadActivity?.newLeads}
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
              <CCol xs={12} md={6} xl={6}>
                <CRow>
                  <CCol sm={6}>
                    <div className="leadActivityBox box-2 py-1">
                      <div
                        style={{ color: "#92929E" }}
                        className="leadActivityTitle text-medium-emphasis small"
                      >
                        Working
                      </div>
                      <div className="leadActivityCount fs-5 fw-semibold">
                        {props.leadActivity?.working}
                      </div>
                    </div>
                  </CCol>
                  <CCol sm={6}>
                    <div className="leadActivityBox box-3 py-1">
                      <div
                        style={{ color: "#92929E" }}
                        className="leadActivityTitle text-medium-emphasis small"
                      >
                        Approved
                      </div>
                      <div className="leadActivityCount fs-5 fw-semibold">
                        {props.leadActivity?.approved}
                      </div>
                    </div>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};
export default LeadStat;
