import React, { useContext, useState } from "react";
import _ from "lodash";
import api from "../../config/api";
import AuthContext from "../../store/authContext";
import { CCol, CRow } from "@coreui/react";
import { useEffect } from "react";
import parse from "html-react-parser";
import LayoutsInquiry from "../Layout/Inquiry";

const InquiryPrivacy = React.memo(() => {
  const [isLoading, setLoading] = useState(false);
  const [resourcesData, setResourcesData] = useState("");
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    api
      .post(`setting/getsettingbyname`, { name: "PRIVACY_POLICY" })
      .then((response) => {
        setLoading(false);
        if (response.data.status && response.data.data?.value != null) {
          setResourcesData(response.data.data?.value);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }, []);

  return (
    <LayoutsInquiry>
      <CRow>
        <CCol lg={12}>
          <h1 className="title text-center">Privacy Policy</h1>
        </CCol>
        <CCol lg={12}>{resourcesData != "" && parse(resourcesData)}</CCol>
      </CRow>
    </LayoutsInquiry>
  );
});

export default InquiryPrivacy;
