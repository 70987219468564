import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Col, message, Row } from "antd";
import CommonFormSplash from "../Common/Form/Splash";
import LayoutsHome from "../Layout/Home";
import routes from "../../config/routes";
import { useForm } from "react-hook-form";
import api from "../../config/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const validationSchema = yup.object().shape({
  password: yup
    .string()
    .min(8, "Password must be 8 characters long.")
    .max(255)
    .required("Password is required."),
  confirm_password: yup
    .string()
    .min(8, "Password must be 8 characters long.")
    .required("Confirm Password is required.")
    .oneOf([yup.ref("password"), null], "Passwords must match."),
});

const HomeResetPassword = React.memo(() => {
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const { id } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  const onSubmit = async (formData) => {
    setLoading(true);
    if (id) {
      formData.id = id;
      await api
        .post("user/reset-password", formData)
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            setSuccess(true);
            message.success(response.data.message);
          } else {
            message.error(response.data.message);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  let formData = null;

  if (isSuccess) {
    formData = (
      <Row className="color-alt" justify="center">
        <Col className="text-center padding-bottom-1_5" span={20}>
          Your password has been reset successfully!
        </Col>
      </Row>
    );
  } else {
    formData = (
      <>
        <Row className="color-base" justify="center">
          <Col className="text-center padding-bottom-1_5" span={20}>
            Enter your new password below.
          </Col>
        </Row>
        <div className="Innerform wow fadeInUp" data-wow-delay="0.4s">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formcol mb-4">
              <div className="position-relative">
                <input
                  className={`input-text ${
                    errors.password ? "is-invalid" : ""
                  }`}
                  type={passwordShown ? "text" : "password"}
                  placeholder="Password"
                  {...register("password")}
                />
                <i
                  className={`inputformPasswordToggle icon-white ${
                    passwordShown ? "show" : "hide"
                  }`}
                  onClick={togglePassword}
                ></i>
              </div>
              {errors.password?.message != undefined && (
                <span className="formError">{errors.password?.message}</span>
              )}
            </div>
            <div className="formcol">
              <div className="position-relative">
                <input
                  className={`input-text ${
                    errors.confirm_password ? "is-invalid" : ""
                  }`}
                  type={confirmPasswordShown ? "text" : "password"}
                  placeholder="Confirm Password"
                  {...register("confirm_password")}
                />
                <i
                  className={`inputformPasswordToggle icon-white ${
                    confirmPasswordShown ? "show" : "hide"
                  }`}
                  onClick={toggleConfirmPassword}
                ></i>
              </div>
              {errors.confirm_password?.message != undefined && (
                <span className="formError">
                  {errors.confirm_password?.message}
                </span>
              )}
            </div>
            <div className="formbtn">
              <button
                htmltype="submit"
                className="input-btn"
                disabled={isLoading}
              >
                Update Password
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }

  return (
    <LayoutsHome>
      <CommonFormSplash
        footer={
          <>
            Don't have an account? <Link to={routes.Home}>Login</Link>
          </>
        }
        title={<span>Reset Your password</span>}
      >
        {formData}
      </CommonFormSplash>
    </LayoutsHome>
  );
});

export default HomeResetPassword;
