import React from 'react';

const SvgProfile = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.76923 7.82353C7.76923 5.15957 9.88727 3 12.5 3C15.1127 3 17.2308 5.15957 17.2308 7.82353C17.2308 9.49625 16.3957 10.9701 15.1274 11.8353C17.9737 12.9209 20 15.7192 20 19V21H5V19C5 15.7192 7.02635 12.9209 9.87257 11.8353C8.6043 10.9701 7.76923 9.49625 7.76923 7.82353ZM12.5 10.5294C13.9657 10.5294 15.1538 9.31795 15.1538 7.82353C15.1538 6.32911 13.9657 5.11765 12.5 5.11765C11.0343 5.11765 9.84615 6.32911 9.84615 7.82353C9.84615 9.31795 11.0343 10.5294 12.5 10.5294ZM12.5 13.4706C9.54347 13.4706 7.13953 15.8829 7.07813 18.8824H17.9219C17.8605 15.8829 15.4565 13.4706 12.5 13.4706Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgProfile;
