import React from 'react';
import { Layout } from 'antd';

import '../../styles/HomeNewAlt.css';
import '../../styles/responsive.css';

const { Content } = Layout;
const LayoutsSignup = ({ children }) => (
  <div className="wrapper">
    <section className="register_banner">
      <div className="vehicleInfo">
        <Content>{children}</Content>
      </div>
    </section>
  </div>
);

export default LayoutsSignup;
