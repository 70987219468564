import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import routes from "../../config/routes";
import CommonDrawerSectionHeader from "../Common/Drawer/SectionHeader";
import CommonDrawerInfo from "../Common/Drawer/Info";
import { CCol, CRow } from "@coreui/react";
import LayoutsLoggedIn from "../Layout/LoggedIn";
import AppLoading from "../Common/AppLoading";
import moment from "moment";
import AuthContext from "../../store/authContext";
import api from "../../config/api";

const BuyerAccount = () => {
  const [uploadedFile, setUploadedFile] = useState("");
  const [refresh, setRefresh] = useState();
  const [buyerData, setBuyerData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    if (authCtx.userData?.user_type != 1) {
      navigate(routes.Dashboard);
    }
  }, [authCtx]);

  const uploadFiles = async (event) => {
    setUploadedFile(URL.createObjectURL(event.target.files[0]));
    const formData = new FormData();
    formData.append("image", event.target.files[0]);
    await api
      .post(`users/update-profile-image`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setRefresh((v) => v + 1);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchBuyerDetail();
  }, [refresh]);

  async function fetchBuyerDetail() {
    setLoading(true);
    await api
      .post(
        `buyers/get-buyer`,
        { user_id: authCtx.userData.id },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setBuyerData(response.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }
  return (
    <>
      {isLoading ? (
        <AppLoading />
      ) : (
        <LayoutsLoggedIn>
          {(authCtx.userData?.user_type == 0 ||
            authCtx.userData?.allowAll == 1 ||
            authCtx.userData?.permissions.includes("my_profile_view")) && (
            <div>
              <Row className="buyersLastLogin" justify="space-between">
                <Col>
                  <h2>{buyerData?.name}'s Information</h2>
                  <span className="title">
                    Last Login{" "}
                    {moment(buyerData?.created_at).utc().format("DD/MM/YYYY")}
                  </span>
                </Col>
                <Col>
                  <Link to={routes.Profile}>Edit</Link>
                </Col>
              </Row>
              <div className="offers-details-data-group buyersData">
                <CommonDrawerSectionHeader title="Buyer" />
                <CRow>
                  <CCol sm={6} lg={3}>
                    <CommonDrawerInfo title="Full Name:">
                      {buyerData?.name}
                    </CommonDrawerInfo>
                  </CCol>
                  <CCol sm={6} lg={3}>
                    <CommonDrawerInfo title="Referral Id:">
                      {buyerData?.referral_id}
                    </CommonDrawerInfo>
                  </CCol>
                  <CCol sm={6} lg={3}>
                    <CommonDrawerInfo title="Email Address:">
                      {buyerData?.email}
                    </CommonDrawerInfo>
                  </CCol>
                  <CCol sm={6} lg={6}>
                    <CommonDrawerInfo title="Phone Number:">
                      {buyerData?.mobile_no}
                    </CommonDrawerInfo>
                  </CCol>
                  <CCol sm={6} lg={3}>
                    <CommonDrawerInfo title="Address:">
                      {buyerData?.address}
                    </CommonDrawerInfo>
                  </CCol>
                  <CCol sm={6} lg={3}>
                    <CommonDrawerInfo title="Address 2:">
                      {buyerData?.address2}
                    </CommonDrawerInfo>
                  </CCol>
                  <CCol sm={6} lg={2}>
                    <CommonDrawerInfo title="City:">
                      {buyerData.city}
                    </CommonDrawerInfo>
                  </CCol>
                  <CCol sm={6} lg={2}>
                    <CommonDrawerInfo title="State:">
                      {buyerData?.state}
                    </CommonDrawerInfo>
                  </CCol>
                  <CCol sm={6} lg={2}>
                    <CommonDrawerInfo title="Zipcode:">
                      {buyerData?.zip_code}
                    </CommonDrawerInfo>
                  </CCol>
                </CRow>
                <Row gutter={16}>
                  <Col span={6}>
                    <CommonDrawerInfo title="Status">
                      {buyerData?.status == 1 ? "Active" : "Inactive"}
                    </CommonDrawerInfo>
                  </Col>
                  <Col span={6}></Col>
                </Row>
              </div>

              <div className="offers-details-data-group">
                <CommonDrawerSectionHeader title="Commission" />
                <Row gutter={8}>
                  <Col span={6}>
                    <CommonDrawerInfo title="Completed Deals Total Amount">
                      ${buyerData?.financeActivity?.totalDealsAmountCompleted}
                    </CommonDrawerInfo>
                  </Col>
                  <Col span={6}>
                    <CommonDrawerInfo title="Not Earned Commission">
                      ${buyerData?.financeActivity?.buyersEarnedCommission}
                    </CommonDrawerInfo>
                  </Col>
                </Row>
              </div>

              <div className="offers-details-data-group">
                <CommonDrawerSectionHeader title="Buyer Stats/Activity" />
                <Row gutter={8}>
                  <Col span={6}>
                    <CommonDrawerInfo title="Total Deals">
                      {buyerData?.buyerStatsActivity?.totalDealsCount}
                    </CommonDrawerInfo>
                  </Col>
                  <Col span={6}>
                    <CommonDrawerInfo title="Pending Deals">
                      {buyerData?.buyerStatsActivity?.pendingDealsCount}
                    </CommonDrawerInfo>
                  </Col>
                  <Col span={6}>
                    <CommonDrawerInfo title="Total Form Sent">
                      {buyerData?.buyerStatsActivity?.totalFormSentCount}
                    </CommonDrawerInfo>
                  </Col>
                  <Col span={6}>
                    <CommonDrawerInfo title="Total Form Submitted">
                      {buyerData?.buyerStatsActivity?.totalFormSubmitted}
                    </CommonDrawerInfo>
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col span={6}>
                    <CommonDrawerInfo title="Completed Deals">
                      {buyerData?.buyerStatsActivity?.completedDealsCount}
                    </CommonDrawerInfo>
                  </Col>
                  <Col span={6}>
                    <CommonDrawerInfo title="Rejected Deals">
                      {buyerData?.buyerStatsActivity?.rejectedDealsCount}
                    </CommonDrawerInfo>
                  </Col>
                  <Col span={6}>
                    <CommonDrawerInfo title="Total Form Clicked">
                      {buyerData?.buyerStatsActivity?.totalFormClicked}
                    </CommonDrawerInfo>
                  </Col>
                </Row>
                <Row gutter={8}></Row>
              </div>
            </div>
          )}
          {(authCtx.userData?.user_type == 0 ||
            authCtx.userData?.allowAll == 1 ||
            authCtx.userData?.permissions.includes(
              "my_profile_file_status"
            )) && (
            <div className="offers-details-data-group">
              <CommonDrawerSectionHeader title="W9 on file status" />
              <Row gutter={8}>
                <Col span={6}>
                  <input onChange={uploadFiles} type="file" name="w9_file" />
                  {uploadedFile && <img src={uploadedFile} alt="" />}
                  {buyerData?.image != "" && (
                    <>
                      <a
                        target="_blank"
                        href={buyerData?.fileFirstImage?.imageURL}
                        rel="noreferrer">
                        {buyerData?.fileFirstImage?.image}
                      </a>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          )}
        </LayoutsLoggedIn>
      )}
    </>
  );
};

export default BuyerAccount;
