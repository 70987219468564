import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import api from "../../config/api";
import routes from "../../config/routes";
import AppLoading from "../Common/AppLoading";
import CommonFormSplash from "../Common/Form/Splash";
import LayoutsHome from "../Layout/Home";

const HomeVerifyAccount = React.memo(() => {
  const [isLoading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const { id } = useParams();
  useEffect(() => {
    if (id != undefined) {
      api
        .post(`verify-email`, { email: id })
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            setIsVerified(true);
          } else {
            setIsVerified(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, []);

  const onResendVerificationClick = () => {
    let oldEmail = localStorage.getItem("email");
    if (oldEmail != null) {
      api
        .post(`resend-email`, { email: oldEmail })
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            setResendSuccess(true);
          } else {
            setResendSuccess(false);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      {isLoading ? (
        <AppLoading />
      ) : (
        <LayoutsHome>
          <CommonFormSplash title={<span>Please Verify Your Account</span>}>
            {isVerified ? (
              <Row className="color-alt" justify="center">
                <Col className="text-center padding-bottom-1_5" span={20}>
                  Thanks! your email has been verified successfully.
                </Col>
                <div className="formbtn">
                  <Link to={routes.Home} className="btn btn-red">
                    Login Now
                  </Link>
                </div>
              </Row>
            ) : resendSuccess ? (
              <>
                <Row className="color-alt" justify="center">
                  <Col className="text-center padding-bottom-1_5" span={20}>
                    Verification mail resend successfully.
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className="color-alt" justify="center">
                  <Col className="text-center padding-bottom-1_5" span={20}>
                    We’ve sent you an email, go check it!
                  </Col>
                  <Col className="text-center padding-bottom-1_5" span={20}>
                    If you do not receive an email or your code has expired,{" "}
                    <a href="#" onClick={onResendVerificationClick}>
                      click here
                    </a>{" "}
                    to send another email.
                  </Col>
                  <Col className="text-center padding-bottom-1_5" span={20}>
                    If you need to switch accounts or logout,{" "}
                    <Link to={routes.Home}>click here.</Link>
                  </Col>
                </Row>
              </>
            )}
          </CommonFormSplash>
        </LayoutsHome>
      )}
    </>
  );
});

export default HomeVerifyAccount;
