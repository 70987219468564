import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, message, Row } from "antd";
import CommonFormSplash from "../Common/Form/Splash";
import LayoutsHome from "../Layout/Home";
import routes from "../../config/routes";
import { useForm } from "react-hook-form";
import api from "../../config/api";

const HomeForgotPassword = React.memo(() => {
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData) => {
    setLoading(true);
    await api
      .post("forgotPassword", formData)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          message.success(response.data.message);
          setSuccess(true);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  let formData = null;

  if (isSuccess) {
    formData = (
      <Row className="color-alt" justify="center">
        <Col className="text-center padding-bottom-1_5" span={20}>
          You should receive an email from us shortly containing a reset link.
        </Col>
      </Row>
    );
  } else {
    formData = (
      <>
        <Row className="color-base" justify="center">
          <Col className="text-center padding-bottom-1_5" span={20}>
            Enter your account email below and we&rsquo;ll send you a password
            reset link.
          </Col>
        </Row>
        <div className="Innerform wow fadeInUp" data-wow-delay="0.4s">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formcol">
              <input
                className={`inputformField ${
                  errors.password ? "is-invalid" : ""
                }`}
                placeholder="Email"
                name="email"
                defaultValue=""
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
              {errors.email?.type === "pattern" && (
                <span className="formError">Enter Valid Email</span>
              )}
            </div>
            <div className="formbtn">
              <button
                type="submit"
                className="btn btn-red"
                disabled={isLoading}
              >
                Send Password Reset
              </button>
            </div>
          </form>
        </div>
      </>
    );
  }

  return (
    <LayoutsHome>
      <CommonFormSplash
        footer={
          <>
            Did you remember your password? <Link to={routes.Home}>Log In</Link>
          </>
        }
        title={<span>Forgot Your password?</span>}
      >
        {formData}
      </CommonFormSplash>
    </LayoutsHome>
  );
});

export default HomeForgotPassword;
