import React from "react";

import { Layout, Spin } from "antd";

const AppLoading = () => (
  <Layout className="app-loading">
    <Spin size="large" />
  </Layout>
);

export default AppLoading;
