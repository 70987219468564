import React, { useContext, useEffect, useState } from "react";
import { Dropdown, Menu, message, Spin, Tag } from "antd";
import _ from "lodash";
import moment from "moment";
import SvgThreeDots from "../../images/three-dots";
import api from "../../config/api";
import EditCompany from "./Edit";
import CommonTableWrapper from "../Layout/Wrapper";
import ReactPaginate from "react-paginate";
import {
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import RefreshContext from "../../store/refreshContext";
import AuthContext from "../../store/authContext";

const CompanyTable = React.memo(() => {
  const [offers, setOffers] = useState({});
  const [companyId, setCompanyId] = useState();
  const [isLoading, setLoading] = useState(true);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("");
  const [perPage, setPerPage] = useState("10");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 1,
    current: 1,
  });

  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);

  useEffect(() => {
    fetchDeals({ per_page: perPage }, 1);
  }, [refreshCtx.refresh, perPage]);

  const getList = (data) => {
    let content = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      content.push(
        <CTableRow key={item.id}>
          <CTableDataCell className="ant-table-cell">
            {item.title}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.domain}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {moment(item.created_at).utc().format("M/D/YY")}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.status != true ? (
              <Tag color="volcano">Inactive</Tag>
            ) : (
              <Tag color="green">Active</Tag>
            )}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            <Dropdown
              className="common-table-status"
              overlay={
                <Menu selectable={false}>
                  <Menu.Item key="edit">
                    <a onClick={(e) => setCompanyId(item.id)}>Edit</a>
                  </Menu.Item>
                  <Menu.Item key="delete">
                    <a onClick={(e) => onDelete(e, item.id)}>Delete</a>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
              trigger={["click"]}
            >
              <a onClick={(e) => e.preventDefault()}>
                <SvgThreeDots />
              </a>
            </Dropdown>
          </CTableDataCell>
        </CTableRow>
      );
    }
    return content;
  };

  function sort(field, currentField, sort, page) {
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        fetchDeals(
          {
            field_name: field,
            order_by: "asc",
            per_page: perPage,
          },
          page
        );
      } else {
        setSortType("desc");
        fetchDeals(
          {
            field_name: field,
            order_by: "desc",
            per_page: perPage,
          },
          page
        );
      }
    } else {
      setSortField(field);
      setSortType("desc");
      fetchDeals(
        {
          field_name: field,
          order_by: "desc",
          per_page: perPage,
        },
        page
      );
    }
  }

  async function fetchDeals(formData, page) {
    await api
      .post(`company?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setOffers({});
        if (response.data.status) {
          setOffers(response.data.data.company);
          setPagination(response.data.data.paginate);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  const onClose = () => {
    setCompanyId();
  };

  const onDelete = async (e, company_id) => {
    await api
      .get(`company/delete-company/${company_id}`, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        refreshCtx.onRefresh();
        if (response.data.status) {
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const perPageRecordHandler = (e) => {
    setPerPage(e.target.value);
  };

  const onPaginationFetch = (e) => {
    fetchDeals(
      { field_name: sortField, order_by: sortType, per_page: perPage },
      e.selected + 1
    );
  };

  return (
    <>
      <CommonTableWrapper>
        <div className="ant-table">
          <CTable className="table-layout">
            <CTableHead className="ant-table-thead">
              <CTableRow>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Title
                  <span
                    onClick={(e) =>
                      sort("title", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "title" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Domain
                  <span
                    onClick={(e) =>
                      sort("domain", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "domain" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Status
                  <span
                    onClick={(e) =>
                      sort("status", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "status" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Date
                  <span
                    onClick={(e) =>
                      sort(
                        "created_at",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "created_at" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Action
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="ant-table-tbody">
              {isLoading && (
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={12}>
                    <Spin />
                  </CTableDataCell>
                </CTableRow>
              )}
              {!isLoading && offers.length == 0 && (
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={7}>
                    No Data Found.
                  </CTableDataCell>
                </CTableRow>
              )}
              {getList(offers)}
            </CTableBody>
          </CTable>
        </div>
        {pagination.total_records != undefined && (
          <>
            <ul className="ant-pagination ant-table-pagination ant-table-pagination-right">
              <li className="ant-pagination-total-text">
                {pagination.total_records} items
              </li>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={onPaginationFetch}
                previousClassName="ant-pagination-prev"
                nextClassName="ant-pagination-next"
                pageRangeDisplayed={10}
                pageCount={pagination.total_pages}
                pageClassName="ant-pagination-item"
                previousLabel="<"
                activeClassName="ant-pagination-item-active"
                disabledClassName="ant-pagination-item ant-pagination-disabled"
                renderOnZeroPageCount={null}
              />
              <select className="input-select" onChange={perPageRecordHandler}>
                <option value="10">10 / Page</option>
                <option value="20">20 / Page</option>
                <option value="50">50 / Page</option>
                <option value="100">100 / Page</option>
              </select>
            </ul>
          </>
        )}
      </CommonTableWrapper>
      <EditCompany companyId={companyId} onClose={onClose} />
    </>
  );
});

export default CompanyTable;
