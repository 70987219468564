import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import CommonDrawerSection from "../../Common/Drawer/Section";
import CommonDrawerInfo from "../../Common/Drawer/Info";
import OfferDetailsEditHeader from "./EditHeader";
import { message } from "antd";
import { useForm } from "react-hook-form";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";
import { formatPhoneNumber } from "../../../utils/formats";

const OffersDetailsLead = React.memo(({ data, onUpdateLead }) => {
  const [isEditing, setEditing] = useState(false);
  const [error, setError] = useState([]);
  const [isLoading, setLoading] = useState([]);
  const {
    register,
    getValues,
    formState: { errors },
    trigger,
    setValue,
    watch,
  } = useForm();

  const phone = watch("phone");

  useEffect(() => {
    if (phone != "" && phone != undefined) {
      setValue("phone", formatPhoneNumber(phone).formatted);
    }
  }, [phone]);

  const authCtx = useContext(AuthContext);
  const onSaveClick = async () => {
    const isValidated = await trigger();
    if (isValidated) {
      let formData = getValues();
      formData.lead_id = data.lead_id;
      formData.phone = formData.phone.replace(/-/g, "");
      await api
        .post(`leads/update-lead`, formData, {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        })
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            message.success("Changes saved successfully!");
            onUpdateLead();
            setEditing(false);
          } else {
            setError(response.data.message);
          }
        })
        .catch((error) => {
          message.error(
            "Sorry we are not able to save data at this time. Please try again later."
          );
          setLoading(false);
        });
    }
  };

  if (!data) {
    return null;
  }

  return (
    <CommonDrawerSection>
      <OfferDetailsEditHeader
        isEditing={isEditing}
        onSaveClick={onSaveClick}
        isLoading={isLoading}
        setEditing={setEditing}
        title="Lead Information"
      />
      {!isEditing && (
        <>
          <CommonDrawerInfo title="Name:">{data.leadName}</CommonDrawerInfo>
          <CommonDrawerInfo title="Email:">
            <a href={`mailto:${data.email}`}>{data.leadEmail}</a>
          </CommonDrawerInfo>
          <CommonDrawerInfo title="Phone:">{data.leadPhone}</CommonDrawerInfo>
          <CommonDrawerInfo title="Buyer comment on vehicle:">
            {data.leadComment}
          </CommonDrawerInfo>
        </>
      )}
      {isEditing && (
        <form>
          <div className="formcol">
            <label className="input-label">Name</label>
            <input
              className={`input-text ${errors.name ? "is-invalid" : ""}`}
              placeholder="Name"
              defaultValue={data.leadName}
              {...register("name", {
                required: true,
              })}
            />
            {errors.name?.type === "required" && (
              <span className="formError">This field is required</span>
            )}
          </div>
          <div className="formcol">
            <label className="input-label">Email</label>
            <input
              className={`input-text ${errors.email ? "is-invalid" : ""}`}
              placeholder="Email"
              defaultValue={data.leadEmail}
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
            />
            {errors.email?.type === "required" && (
              <span className="formError">This field is required</span>
            )}
            {errors.email?.type === "pattern" && (
              <span className="formError">Enter Valid Email</span>
            )}
          </div>
          <div className="formcol">
            <label className="input-label">Comment</label>
            <textarea
              className={`input-text`}
              placeholder="Buyer comment on vehicle"
              defaultValue={data.leadComment}
              {...register("comment")}
            ></textarea>
          </div>
          <div className="formcol">
            <label className="input-label">Phone</label>
            <input
              className={`input-text ${errors.phone ? "is-invalid" : ""}`}
              placeholder="Phone"
              defaultValue={formatPhoneNumber(data.leadPhone).formatted}
              {...register("phone", {
                required: true,
              })}
              maxLength={12}
            />
            {errors.phone?.type === "required" && (
              <span className="formError">This field is required</span>
            )}
          </div>
          {!error && <span className="formError">{error}</span>}
        </form>
      )}
    </CommonDrawerSection>
  );
});

export default OffersDetailsLead;
