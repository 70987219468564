import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Buyer from "./components/Buyer";
import BuyerDetail from "./components/Buyer/Detail";
import BuyerAccount from "./components/BuyerAccount";
import Company from "./components/Company";
import Dashboard from "./components/Dashboard";
import Deals from "./components/Deals";
import HomeForgotPassword from "./components/Home/ForgotPassword";
import HomeLogin from "./components/Home/Login";
import HomeResetPassword from "./components/Home/ResetPassword";
import HomeSignup from "./components/Home/Signup";
import SignupTeam from "./components/Home/SignupTeam";
import HomeVerifyAccount from "./components/Home/VerifyAccount";
import Inquiry from "./components/Inquiry";
import InquiryPrivacy from "./components/Inquiry/privacy";
import InquiryTerms from "./components/Inquiry/terms";
import Mailbox from "./components/Mailbox";
import MyLeads from "./components/MyLeads";
import NotFound from "./components/NotFound";
import PaymentHistory from "./components/PaymentHistory";
import Profile from "./components/Profile";
import Resources from "./components/Resources";
import Privacy from "./components/Resources/Privacy";
import Terms from "./components/Resources/Terms";
import Role from "./components/Role";
import SmsHistoryBuyer from "./components/SmsHistory";
import Template from "./components/Template";
import ThankYou from "./components/ThankYou";
import routes from "./config/routes";
import PrivateRoute from "./router/PrivateRoute";
import { AuthContextProvider } from "./store/authContext";
import { RefreshContextProvider } from "./store/refreshContext";
import "./styles/app.scss";
import Payouts from "./components/Resources/Payouts";
function App() {
  return (
    <AuthContextProvider>
      <RefreshContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path={routes.Home} element={<HomeLogin />} exact />
            <Route path={routes.Signup} element={<HomeSignup />} exact />
            <Route path={routes.BuyerSignup} element={<HomeSignup exact />} />
            <Route path={routes.SignupTeam} element={<SignupTeam exact />} />
            <Route
              path={routes.HomeVerifyAccount}
              element={<HomeVerifyAccount exact />}
            />
            <Route path={routes.Inquiry} element={<Inquiry exact />} />
            <Route
              path={routes.InquiryPrivacy}
              element={<InquiryPrivacy exact />}
            />
            <Route
              path={routes.InquiryTerms}
              element={<InquiryTerms exact />}
            />
            <Route path={routes.LandingPage} element={<Inquiry exact />} />
            <Route
              path={routes.ForgotPassword}
              element={<HomeForgotPassword exact />}
            />
            <Route
              path={routes.ResetPassword}
              element={<HomeResetPassword exact />}
            />
            <Route
              path={routes.Dashboard}
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.Deals}
              element={
                <PrivateRoute>
                  <Deals />
                </PrivateRoute>
              }
              exact
            />

            <Route
              path={routes.Buyers}
              element={
                <PrivateRoute>
                  <Buyer />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.BuyerDetail}
              element={
                <PrivateRoute>
                  <BuyerDetail />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.BuyerAccount}
              element={
                <PrivateRoute>
                  <BuyerAccount />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.PaymentHistory}
              element={
                <PrivateRoute>
                  <PaymentHistory />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.Profile}
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.MyLeads}
              element={
                <PrivateRoute>
                  <MyLeads />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.Company}
              element={
                <PrivateRoute>
                  <Company />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.Role}
              element={
                <PrivateRoute>
                  <Role />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.Resources}
              element={
                <PrivateRoute>
                  <Resources />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.Privacy}
              element={
                <PrivateRoute>
                  <Privacy />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.Terms}
              element={
                <PrivateRoute>
                  <Terms />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.Payouts}
              element={
                <PrivateRoute>
                  <Payouts />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.Template}
              element={
                <PrivateRoute>
                  <Template />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.Mailbox}
              element={
                <PrivateRoute>
                  <Mailbox />
                </PrivateRoute>
              }
              exact
            />
            <Route
              path={routes.SmsHistoryBuyer}
              element={
                <PrivateRoute>
                  <SmsHistoryBuyer />
                </PrivateRoute>
              }
              exact
            />
            <Route path={routes.ThankYou} element={<ThankYou />} exact />
            <Route path="*" element={<NotFound />} replace />
          </Routes>
        </BrowserRouter>
      </RefreshContextProvider>
    </AuthContextProvider>
  );
}

export default App;
