import React from 'react';

const SvgTeam = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 7C10.3431 7 9 5.65685 9 4C9 2.34315 10.3431 1 12 1C13.6569 1 15 2.34315 15 4C15 5.65685 13.6569 7 12 7ZM12 5C12.5523 5 13 4.55228 13 4C13 3.44772 12.5523 3 12 3C11.4477 3 11 3.44772 11 4C11 4.55228 11.4477 5 12 5ZM11 7H13C14.6569 7 16 8.34315 16 10V11C16 12.6569 14.6569 14 13 14H11C9.34315 14 8 12.6569 8 11V10C8 8.34315 9.34315 7 11 7ZM11 9C10.4477 9 10 9.44771 10 10V11C10 11.5523 10.4477 12 11 12H13C13.5523 12 14 11.5523 14 11V10C14 9.44771 13.5523 9 13 9H11ZM19 16C17.3431 16 16 14.6569 16 13C16 11.3431 17.3431 10 19 10C20.6569 10 22 11.3431 22 13C22 14.6569 20.6569 16 19 16ZM19 14C19.5523 14 20 13.5523 20 13C20 12.4477 19.5523 12 19 12C18.4477 12 18 12.4477 18 13C18 13.5523 18.4477 14 19 14ZM18 16H20C21.6569 16 23 17.3431 23 19V20C23 21.6569 21.6569 23 20 23H18C16.3431 23 15 21.6569 15 20V19C15 17.3431 16.3431 16 18 16ZM18 18C17.4477 18 17 18.4477 17 19V20C17 20.5523 17.4477 21 18 21H20C20.5523 21 21 20.5523 21 20V19C21 18.4477 20.5523 18 20 18H18ZM5 16C3.34315 16 2 14.6569 2 13C2 11.3431 3.34315 10 5 10C6.65685 10 8 11.3431 8 13C8 14.6569 6.65685 16 5 16ZM5 14C5.55228 14 6 13.5523 6 13C6 12.4477 5.55228 12 5 12C4.44772 12 4 12.4477 4 13C4 13.5523 4.44772 14 5 14ZM4 16H6C7.65685 16 9 17.3431 9 19V20C9 21.6569 7.65685 23 6 23H4C2.34315 23 1 21.6569 1 20V19C1 17.3431 2.34315 16 4 16ZM4 18C3.44772 18 3 18.4477 3 19V20C3 20.5523 3.44772 21 4 21H6C6.55228 21 7 20.5523 7 20V19C7 18.4477 6.55228 18 6 18H4Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgTeam;
