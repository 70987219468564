import React from 'react';

import { Col, Row } from 'antd';

const CommonDrawerSectionHeader = React.memo(({ children, title }) => (
  <Row
    align="middle"
    className="common-drawer-section-header"
    justify="space-between"
  >
    <Col className="ds-header"><h5 className='boxTitle'>{title}</h5></Col>
    <Col className="common-drawer-section-header-buttons">
      <Row>{children}</Row>
    </Col>
  </Row>
));

export default CommonDrawerSectionHeader;
