import React from "react";
import _ from "lodash";
import {
  CTable,
  CTableHead,
  CTableRow,
  CTableDataCell,
  CTableHeaderCell,
  CTableBody,
} from "@coreui/react";

import moment from "moment";

const FilesTable = React.memo(({ data }) => {
  const getList = (data) => {
    let content = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      content.push(
        <CTableRow key={item.id}>
          <CTableDataCell className="ant-table-cell">
            <a href={item.imageURL} target="_blank">
              {item.image}
            </a>
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {moment(item.created_at).utc().format("M/D/YY")}
          </CTableDataCell>
        </CTableRow>
      );
    }
    return content;
  };

  return (
    <>
      <h2>W9 File History</h2>
      <div className="common-table-wrapper">
        <div className="ant-table">
          <CTable className="table-layout">
            <CTableHead className="ant-table-thead">
              <CTableRow>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Image
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Date
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="ant-table-tbody">
              {data && getList(data)}
              {data?.length == 0 && (
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={2}>
                    No Data Found.
                  </CTableDataCell>
                </CTableRow>
              )}
            </CTableBody>
          </CTable>
        </div>
      </div>
    </>
  );
});

export default FilesTable;
