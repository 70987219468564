import React from 'react';

import { Row } from 'antd';

import SvgLogo from '../../../images/logo';
import './Splash.scss';

const CommonFormSplash = ({ children, footer, hideLogo = false, title }) => (
  <div className={`common-form-splash ${footer ? 'has-footer' : ''}`}>
    {!!title && (
      <>
        {!hideLogo && (
          <Row justify="center">
            <SvgLogo />
          </Row>
        )}
        <h3 className="common-form-splash-title">{title}</h3>
      </>
    )}
    {children}
    {!!footer && <div className="common-form-splash-footer">{footer}</div>}
  </div>
);

export default CommonFormSplash;
