import React, { useCallback, useContext, useEffect, useState } from "react";

import { Drawer, Spin } from "antd";
import _ from "lodash";

import OffersDetailsBuyer from "./Buyer";
import OffersDetailsDeal from "./Deal";
import OffersDetailsLead from "./Lead";
import OffersDetailsVehicle from "./Vehicle";
import LeadHistory from "./History";
import api from "../../../config/api";
import AuthContext from "../../../store/authContext";
import OffersDetailsNote from "./Note";

const OffersDetail = React.memo(({ id, onClose, onUpdateLead }) => {
  const [edits, setEdits] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [dealDetail, setDealDetail] = useState([]);

  const onToggleEdit = useCallback(
    ({ isEditing, type }) => {
      let newEdits;

      if (isEditing) {
        newEdits = _.union(edits, type);
      } else {
        newEdits = _.remove(edits, type);
      }

      setEdits(newEdits);
    },
    [edits]
  );

  useEffect(() => {
    if (id) {
      getDealDetails();
    }
  }, [id, onUpdateLead]);

  const authCtx = useContext(AuthContext);

  async function getDealDetails() {
    setLoading(true);
    await api
      .post(
        `deals/get-details`,
        {
          deal_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setDealDetail(response.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  return (
    <Drawer
      className="offers-detail"
      destroyOnClose
      mask={false}
      onClose={onClose}
      placement="right"
      title="Lead Profile"
      visible={!!id}
      width={500}>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <OffersDetailsDeal
            data={dealDetail}
            onToggleEdit={onToggleEdit}
            onUpdateLead={onUpdateLead}
          />
          <OffersDetailsLead
            data={dealDetail}
            onToggleEdit={onToggleEdit}
            onUpdateLead={onUpdateLead}
          />
          {authCtx.userData?.user_type == 0 && (
            <OffersDetailsNote
              data={dealDetail}
              onToggleEdit={onToggleEdit}
              onUpdateLead={onUpdateLead}
            />
          )}
          <OffersDetailsVehicle
            data={dealDetail}
            onToggleEdit={onToggleEdit}
            onUpdateLead={onUpdateLead}
          />
          <OffersDetailsBuyer data={dealDetail} />
          <LeadHistory data={dealDetail}></LeadHistory>
        </>
      )}
    </Drawer>
  );
});

export default OffersDetail;
