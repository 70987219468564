import React, { useContext, useState } from "react";
import _ from "lodash";
import CommonDrawerSection from "../../Common/Drawer/Section";
import CommonDrawerInfo from "../../Common/Drawer/Info";
import OfferDetailsEditHeader from "./EditHeader";
import { useForm } from "react-hook-form";
import { message } from "antd";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";

const OffersDetailsNote = React.memo(({ data, onUpdateLead }) => {
  const [isEditing, setEditing] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const { register, getValues } = useForm();

  const authCtx = useContext(AuthContext);
  const onSaveClick = async () => {
    let formData = getValues();
    formData.deal_id = data.id;
    await api
      .post(`deals/addnote`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          message.success(response.data?.message);
          setEditing(false);
          onUpdateLead();
        } else {
          message.error(response.data?.message);
        }
      })
      .catch((error) => {
        message.error(
          "Sorry we are not able to save data at this time. Please try again later."
        );
        setLoading(false);
      });
  };

  if (!data) {
    return null;
  }

  return (
    <CommonDrawerSection>
      <OfferDetailsEditHeader
        isEditing={isEditing}
        onSaveClick={onSaveClick}
        setEditing={setEditing}
        title="Note"
      />
      {!isEditing && (
        <>
          <CommonDrawerInfo title="Text:">{data?.dealnote}</CommonDrawerInfo>
        </>
      )}
      {isEditing && (
        <form>
          <div className="formcol">
            <textarea
              rows={3}
              className="input-text"
              placeholder="Note"
              defaultValue={data?.dealnote}
              {...register("dealnote")}></textarea>
          </div>
        </form>
      )}
    </CommonDrawerSection>
  );
});

export default OffersDetailsNote;
