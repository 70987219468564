import React from "react";

import { Col, Row } from "antd";
import "./index.scss";
import LayoutsLoggedIn from "../Layout/LoggedIn";
import AddTemplate from "./AddTemplate";
import TemplateTable from "./Table";

const Template = () => {
  return (
    <LayoutsLoggedIn>
      <Row justify="space-between">
        <Col>
          <h2>SMS Templates</h2>
        </Col>
        <Col>
          <AddTemplate />
        </Col>
      </Row>
      <TemplateTable />
    </LayoutsLoggedIn>
  );
};

export default Template;
