import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Modal, Radio, Row, Tooltip } from "antd";
import _ from "lodash";

import SvgClose from "../../../images/close";

const TermsCondition = React.memo(({ visible, onCancel, onAccept }) => {
  return (
    <>
      <Modal
        destroyOnClose
        closeIcon={<SvgClose />}
        footer={null}
        onCancel={onCancel}
        visible={visible}
        width={1000}
      >
        <>
          <h3 className="ds-title">Terms & Condition</h3>
          <div className="cbody termsPopup">
            <p>
              This Agreement and Terms of Use (“Agreement”) constitutes a
              binding legal agreement between Hitfigure, LLC dba iLusso
              (“iLusso” or “We” or “Our”), on the one hand, and the individual
              or entity desiring to join the iLusso Buyer’s Network Program
              (“Program”) by entering into this Agreement (“Eligible Buyer” or
              “You” or “Your”), on the other hand. If You do not agree to each
              and every term outlined in this Agreement or said terms as
              modified from time to time by iLusso in its sole discretion
              (collectively “Program Terms”), You are not authorized to register
              for or otherwise participate in the Program.
            </p>

            <p>
              iLusso has developed these Program Terms to describe the terms and
              conditions that govern Your use of iLussobuyers.com, the Program
              iOs application, the Program Andriod application, and any iLusso
              branded URL, WAP site and mobile application and other content and
              services that link to the Program Terms (collectively “Buyer’s
              Network Platform”). The services iLusso provides on its Buyer’s
              Network Platform include, but are not limited to, messaging
              services, video services, RRS feeds, mobile services, text
              messaging, and any other feature, content or application offered
              from time to time by iLusso (collectively "Program Services").
              iLusso is based in the United States and its Program Services are
              hosted in the United States.
            </p>

            <p>
              By entering into this Agreement and participating in the Program
              through any aspect of the Buyer’s Network Platform, You accept and
              are bound by the Program Terms as outlined herein and consistent
              with any other terms and conditions applied to the Program from
              time to time. iLusso is the exclusive administrator of the Program
              and reserves the right in its sole discretion to modify, amend,
              suspend or terminate the Program, or any of the Program Terms, at
              any time and for any reason. iLusso reserves the right to
              disqualify You from participation in the Program at any time,
              including without limitation, if You fail to comply with any of
              the Program Terms or fail to abide by all applicable laws, rules,
              and regulations (“Applicable Laws”). In the event of any material
              breach of the Program Terms and/or any act or omission by You that
              is believed to be harmful to Our business, to the Buyer’s Network
              Platform, to any third party, and/or in violation of Applicable
              Laws, iLusso reserves the exclusive right to suspend or terminate
              Your participation in the Program, including deactivating Your
              access to the Buyer’s Network Platform, at any time, with or
              without notice.
            </p>

            <p>
              The Program Terms shall be effective as of the date posted on the
              Program’s website at iLussobuyers.com. By accessing or otherwise
              utilizing any aspect of the Buyer’s Network Platform, You agree to
              abide by the then current Program Terms. iLusso may modify the
              Program at any time, including revising, updating, correcting, or
              removing provisions to the Program Terms, and each such
              modification shall be effective upon posting to the Program’s
              website. All material modifications will apply prospectively only.
              Your continued use of the Buyer’s Network Platform, or any aspect
              thereof, following any modification of the Program Terms
              constitutes Your acceptance of and agreement to be bound by the
              Program Terms as modified. It is therefore important that You
              review the Program Terms regularly. If You do not agree to be
              bound the Program Terms and all Applicable Laws, You must
              discontinue use of the Buyer’s Network Platform immediately.
            </p>

            <p>
              Your access to and use of certain Program Services is conditioned
              upon Your compliance with the Program Terms, which incorporate by
              reference the Privacy Policy as well as any end user license
              agreements that might accompany the Buyer’s Network Platform. Your
              access to and use of certain Program Services may require You to
              accept additional terms and conditions applicable to such Program
              Services, in addition to the Program Terms, and may require You to
              download software or Content (as defined below). In the event of a
              conflict between any such additional terms and this Agreement,
              such additional terms will prevail.
            </p>

            <p>
              PLEASE NOTE THAT THE ARBITRATION AGREEMENT SECTION BELOW CONTAINS
              PROVISIONS THAT REQUIRE (i) WITH LIMITED EXCEPTIONS, ALL DISPUTES
              ARISING BETWEEN YOU AND iLusso UNDER THIS AGREEMENT TO BE RESOLVED
              IN BINDING ARBITRATION, AND NOT IN COURT, AND (ii) YOU AND iLusso
              WAIVE THE RIGHT TO BRING OR PARTICIPATE IN A CLASS OR
              REPRESENTATIVE ACTION IN CONNECTION WITH SUCH DISPUTES.
              PLEASE CLICK HERE TO REVIEW THE ARBITRATION AGREEMENT. BY USING
              THE BUYER’S NETWORK PLATFORM AND ACCEPTING THIS AGREEMENT AND THE
              PROGRAM TERMS, YOU AGREE TO BE BOUND BY THE ARBITRATION AGREEMENT.
              PLEASE READ IT CAREFULLY.
            </p>

            <p>
              By entering into this Agreement, You acknowledge, represent, and
              agree that the Program Terms have been expressly brought to Your
              attention. No part of the Program is designed for or directed to
              persons under the age of 18 – IF YOU ARE UNDER 18 YEARS OF AGE,
              YOU MAY NOT PARTICIPATE IN THE PROGRAM IN ANY RESPECT OR IN ANY
              MANNER AND MAY NOT USE OR ACCESS THE BUYER’S NETWORK PLATFORM.
            </p>

            <p>I. How the Program Works</p>

            <p>
              A. Program Participation, Generally. The Program is designed to
              give anyone who wants to become an Exotic Car buyer the chance to
              do so and earn commissions. To participate to in the Program, each
              buyer must go to Our website at iLussobuyer.com and register to
              become an Eligible Buyer, which requires providing iLusso with
              certain information, documentation, and acceptance of the Program
              Terms. Once registered, an Eligible Buyer has access to the
              Buyer’s Network Platform that allows an Eligible Buyer to approach
              anyone with an Exotic Car and get them an appraisal from iLusso.{" "}
            </p>

            <p>
              To cause iLusso to send an appraisal to an Exotic Car owner, an
              Eligible Buyer must login into the Program’s website at
              iLussobuyers.com and follow the on-screen instructions with the
              hyperlink entitled Send Appraisal Form. Once a Request for
              Appraisal of an Exotic Car has been submitted by an Eligible
              Buyer, iLusso’s appraisal team will evaluate the Exotic Car and
              make a determination, at its sole discretion, whether to cause an
              appraisal and Purchase Offer to be made directly to the owner of
              the Exotic Car (“Seller”).
            </p>

            <p>
              Through the Buyer’s Network Platform, an Eligible Buyer is able to
              view the deal status of each Exotic Car for which a Request for
              Appraisal was submitted and iLusso sent the Seller an appraisal
              and Purchase Offer. There are five (5) deal status to look at
              during the offer process, including:
            </p>

            <p>
              <strong>Submitted</strong> – identifies that a Request for
              Appraisal for a specific Exotic Car has been submitted to iLusso
              and an appraisal and Purchase Offer is being created by iLusso’s
              appraisal team for presenting to the prospective Seller
            </p>

            <p>
              <strong>Pending</strong> – identifies that iLusso has presented an
              appraisal and Purchase Offer to the prospective Seller and is
              waiting for an acceptance or rejection of the Purchase Offer
            </p>

            <p>
              <strong>Rejected</strong> – identifies that a Seller did not
              accept iLusso’s Purchase Offer and does not want to sell to iLusso
            </p>

            <p>
              <strong>Approved</strong> – identifies that a Seller has accepted
              iLusso’s Purchase Offer and iLusso is processing requisite
              paperwork to pay for and pick-up the Exotic Car
            </p>

            <p>
              <strong>Completed</strong> – identifies that a Seller has received
              payment from iLusso for the purchase of an Exotic Car, iLusso has
              legal/physical possession of the Exotic Car, and Eligible Buyer is
              entitled to receive payment of the agreed amount of commissions
            </p>

            <p>
              <strong>B. Request for Appraisal.</strong> In order to submit a
              request for iLusso to send an Exotic Car owner a Purchase Offer,
              You must register at iLussobuyers.com and provide all information
              and documentation to be considered an Eligible Buyer. Once You are
              registered with the Program, You will have access to the Buyer’s
              Network Platform and its related Program Services, including use
              of a hyperlink entitled Send Appraisal Form, which is the initial
              step for an Eligible Buyer to make a Request for Appraisal of an
              Exotic Car thereby causing iLusso’s appraisal team to consider and
              make a decision at its sole discretion whether the Exotic Car
              meets the qualifications for an iLusso acquisition and, if so,
              cause a Purchase Offer to be sent directly to the Seller.
            </p>

            <p>
              <strong>C. Eligible Buyer.</strong> To be an Eligible Buyer in the
              Program, You must: (i) be at least 18 years of age; (ii) be a
              legal resident of the United States of America; (iii) have the
              legal right to work in the USA, whether as born or naturalized
              citizen or with an immigration status that allows for working in
              the USA; (iv) be registered in the Program by providing all
              required information and documentation, including without
              limitation a completed/signed Form W-9 together with a current,
              valid copy of a driver’s license or other government-issued
              identification; and (v) agree to all current Program Terms and
              future Program Terms as modified from time to time by iLusso.
            </p>

            <p>
              An Eligible Buyer who is fully compliant with the Program Terms
              and all Applicable Laws is entitled to receive a commission for
              every Qualified Acquisition, which shall be one percent (1%) of
              the purchase price paid by iLusso to the Seller of an Exotic Car
              that was presented to iLusso through the Program.
            </p>

            <p>
              A team-building link in the Buyer’s Network Platform allows an
              Eligible Buyer to build a team of subordinate buyers. For each
              Subordinate Buyer added to an Eligible Buyer’s team, the Eligible
              Buyer who built the team will be paid twenty-five hundredths of a
              percent (.25%) for each Qualified Acquisition a Subordinate Buyer
              refers to iLusso through a Request for Appraisal. Important Notes:
            </p>

            <p>
              <ul>
                <li>
                  in order to add a Subordinate Buyer to a team, an Eligible
                  Buyer shall use the designated team-building link on the
                  Buyer’s Network Platform to introduce/refer the Subordinate
                  Buyer to iLusso. If a person or entity signs up directly
                  through the Program and is not introduced/referred to iLusso
                  through the designated team-building link, an Eligible Buyer
                  shall not be entitled to the .25% commissions, or any other
                  amount, for any Qualified Acquisition of another Eligible
                  Buyer
                </li>

                <li>
                  an Eligible Buyer who builds a team of subordinate buyers
                  shall only receive the .25% commission from each Qualified
                  Acquisition presented directly by a Subordinate Buyer and
                  shall not receive any commission from subordinate buyers who
                  are on a separate team built by a Subordinate Buyer
                </li>
              </ul>
            </p>

            <p>
              There is absolutely no need for an Eligible Buyer to be involved
              in any negotiations with a prospective Seller. An Eligible Buyer’s
              sole purpose and reasonability in the Program is to locate/source
              inventory from owners of Exotic Car by sending a Request for
              Appraisal to iLusso’s appraisal team through the Buyer’s Network
              Platform.
            </p>

            <p>
              Each Eligible Buyer participating in the Program acts solely as an
              independent contractor and is not an agent or employee of iLusso.
              Similarly, each Subordinate Buyer acts solely as an independent
              contractor and is not an agent or employee of iLusso nor an agent
              or employee of the team-building Eligible Buyer. Consequently,
              each Eligible Buyer, whether or not also a Subordinate Buyer, is
              required to submit to iLusso an IRS Request for Taxpayer
              Identification Number, Form W-9. iLusso shall not be obligated to
              tender payment of commissions without a completed and signed Form
              W-9 together with a current, valid copy of a driver’s license or
              other government-issued identification and, if applicable, proof
              of eligibility to work in the USA.
            </p>

            <p>
              <strong>D. Commissions Verification.</strong> Payment of
              commissions on a Qualified Acquisition are subject to
              verification. iLusso may delay a commission for the purposes of
              investigation. iLusso may also refuse to verify and process any
              transaction iLusso deems, in its sole discretion, to be:
              fraudulent; suspicious; in violation of the Program Terms; any act
              or omission by an Eligible Buyer (and/or Subordinate Buyer) that
              is believed to be harmful to Our business, to the Buyer’s Network
              Platform, to any third party, or in violation of Applicable Laws;
              and/or We believe will impose potential liability on iLusso, its
              subsidiaries, affiliates or any of their respective officers,
              directors, employees, representatives and agents. All of iLusso's
              decisions are final and binding, including any decisions as to
              whether a Qualified Acquisition should or shouldn’t result in
              payment of commission as contemplated by the Program Terms.
            </p>

            <p>
              <strong>E. Purchase Offers and Purchases.</strong>
              <ul>
                <li>iLusso’s Role</li>
                <li>iLusso’s Appraisal Team</li>
                <li>Offer Terms</li>
                <li>
                  Seller Obligations. - power and authority to sell and transfer
                </li>
              </ul>
            </p>

            <p>
              <strong>F. Payment of Commissions.</strong>
              <ul>
                <li>Commissions</li>
                <li>Payment Agreement</li>
                <li>Failure to Pay; Offset</li>
                <li>Electronic Check</li>
              </ul>
            </p>

            <p>
              <strong>II. Terms of Use</strong>
            </p>

            <p>
              <strong>A. Buyer’s Network Platform.</strong> You agree not to
              give anyone access to the Buyer’s Network Platform. We reserve the
              right to suspend Your access rights temporarily or permanently
              and/or the access rights of any Subordinate Buyer on a team You
              built on the Buyer’s Network Platform. You represent and warrant
              that all information that You provide to iLusso is accurate and
              complete and that You will notify us in writing of any changes to
              the information provided. You hereby authorize iLusso to share the
              information You provide to us (including financial information and
              any other Data) with iLusso affiliates and service providers
              solely to the extent such information is needed in order for
              iLusso to operate/maintain the Program, the Program Services,
              and/or in connection with an assignment made pursuant to Section
              _____.
            </p>

            <p>
              <strong>B. Access Security.</strong> Sharing or lending of
              usernames or passwords is strictly prohibited. You are responsible
              for maintaining the confidentiality and security of the usernames
              and passwords issued to You. You will be liable and responsible
              for all actions, omissions, failures to act, or transactions
              conducted with the username and password issued to You. You will
              immediately notify us in writing of any unauthorized use of a
              username or password issued to You.
            </p>

            <p>
              <strong>C. Personal Credentials and Payment Information.</strong>
              You agree to provide iLusso with, maintain with iLusso, and permit
              iLusso and its authorized agents and affiliates to retain records
              of, valid, updated personal credentials and payment information
              (e.g., banking or ACH information) for the purpose of paying
              commissions based on a Qualified Acquisition. iLusso will store
              Your credentials and payment information for future use. By
              providing information and documentation to iLusso, You consent to
              the storage and the use by iLusso of Your personal credentials for
              verification of authenticity and any payment obligations that
              iLusso owes under this Agreement. iLusso will notify You if there
              are any changes to its storage and future use of Your personal
              credentials and payment information. This Agreement and Your
              consent shall remain in effect until all payment obligations under
              any agreement between You and iLusso are satisfied in full and for
              a period of seven (7) years following the final payment paid to
              You by iLusso.
            </p>

            <p>
              <strong>D. Permitted Use.</strong>
              You represent, warrant and agree that You will use the Buyer’s
              Network Platform solely for the purposes of serving as an Eligible
              Buyer in for the Program, which shall be limited to submitting
              appraisal requests to iLusso, viewing deal status, and monitoring
              commissions earned for a Qualified Acquisition related to You and
              each Subordinate Buyer on Your team. You further represent,
              warrant and agree that You will not: (i) license, sublicense,
              sell, resell, rent, lease, transfer, assign (except as permitted
              by Section _____, distribute, time share or otherwise commercially
              exploit or make the Buyer’s Network Platform available to any
              third party without the express involvement of and access granted
              through iLusso; (ii) send spam or otherwise duplicative or
              unsolicited messages in violation of Applicable Laws; (iii) send
              or store infringing, obscene, threatening, libelous or otherwise
              unlawful or tortious material, including material that violates
              third- party privacy rights; (iv) send or store malicious code or
              viruses; or (v) interfere with or disrupt the integrity or
              performance of the Buyer’s Network Platform or the Data contained
              therein.
            </p>

            <p>
              <strong>E. Compliance.</strong>
              You represent and warrant to iLusso that: (i) You are in
              compliance with and shall comply with all laws and regulations
              that apply to You and Program; and (ii) You have secured and will
              maintain all permits, licenses and governmental approvals required
              to operate as an independent contractor, including as required to
              access and use the Buyer’s Network Platform.
            </p>

            <p>
              <strong>F. Eligible Buyer’s Responsibilities.</strong>
              It is Your responsibility to acquire, provide and maintain at Your
              own expense all hardware, equipment, connections, software
              licenses, facilities, services, and other supplies necessary for
              You to access and use the Buyer’s Network Platform. In addition,
              You shall communicate and cooperate with iLusso, execute and
              deliver such additional documents, instruments, conveyances and
              assurances, and take such further actions as may be reasonably
              required by iLusso to carry out the provisions hereof, to give
              effect to the transactions contemplated by this Agreement, and to
              mitigate iLusso’s costs and losses with respect thereto. Without
              limitation, You shall respond promptly to iLusso requests for
              information and documentation.
            </p>

            <p>
              <strong>III. Limited Content License</strong>
            </p>

            <p>
              <strong>A. Company Content.</strong> The Program Services are
              offered for Your use as an Eligible Buyer only and shall not be
              used for any other personal or commercial purposes. The Program
              Services contain Company Content as well as personal and private
              information provided by users or other third parties. Company
              Content contained in the Program Services is protected by
              copyright, trademark, patent, trade secret and other laws and, as
              between You and iLusso, including its licensors or its assignors,
              iLusso owns and retains all rights in Company Content. iLusso
              hereby grants You a limited, revocable, nonsublicensable license
              to access and use Company Content (excluding any software code)
              solely for Your use as an Eligible Buyer in connection with using
              the Program Services. Except as provided in this Agreement or as
              explicitly allowed within the Program Services, You may not copy,
              download, stream, capture, reproduce, duplicate, archive, upload,
              modify, translate, publish, broadcast, transmit, retransmit,
              distribute, perform, display, sell, frame or deep-link, make
              available, or otherwise use any Company Content contained in the
              Program Services or otherwise made available through Your access
              to the Buyer’s Network Platform and participation in the Program.
            </p>

            <p>
              Except as explicitly and expressly permitted by iLusso or by the
              limited license set forth above, You are strictly prohibited from
              creating works or materials (including but not limited to fonts,
              icons, link buttons, wallpaper, desktop themes, on-line postcards,
              montages, mash-ups and similar videos, greeting cards and
              unlicensed merchandise) that derive from or are based on Company
              Content. This prohibition applies regardless of whether such
              derivative works or materials are sold, bartered or given away.
              Also, You may not either directly or through the use of any
              device, software, internet site, web-based service or other means,
              remove, alter, bypass, avoid, interfere with, or circumvent any
              copyright, trademark, or other proprietary notice marked on
              Company Content contained in the Program Services or any digital
              rights management mechanism, device, or other content protection,
              copy control or access control measure associated with Company
              Content contained in the Program Services, including geo-filtering
              mechanisms. Except as necessary in order to make reference to
              iLusso, its products and services in a purely descriptive
              capacity, You are expressly prohibited from using any Company
              Content in any manner not authorized in this Agreement.
            </p>

            <p>
              You may not, without iLusso's written permission, "mirror" any
              Company Content contained on the Buyer’s Network Platform or
              within any of the Program Services. You may not use the Buyer’s
              Network Platform or Program for any purpose that is unlawful or
              prohibited by this Agreement or Applicable Laws. You may not use
              the Buyer’s Network Platform or participate in the Program as an
              Eligible Buyer in any manner that could damage, disable,
              overburden, or impair the Buyer’s Network Platform or Program
              Services, or interfere with any other party's use of same. You may
              not attempt to gain unauthorized access to the Buyer’s Network
              Platform or Program Services through hacking, password mining or
              any other means. iLusso reserves the right, in its sole
              discretion, to terminate Your participation in the Program and
              access to the Buyer’s Network Platform and Program Services, or
              any portion thereof, at any time, for any reason or for no reason
              at all, without prior notice or any notice.
            </p>

            <p>
              <strong>B. Restrictions on Use of Program Services.</strong> You
              agree not to use the Buyer’s Network Platform or Program Services
              to:
              <ul>
                <li>
                  Post, upload or otherwise transmit any information, including
                  without limitation the name, email address and telephone
                  number, of another person without that person's consent;
                </li>

                <li>
                  Post, upload or otherwise transmit or link to any content that
                  is: unlawful; threatening; abusive; obscene; vulgar; sexually
                  explicit; pornographic or inclusive of nudity; offensive;
                  excessively violent; invasive of another's privacy, publicity,
                  contract or other rights; tortious; false or misleading;
                  defamatory; libelous; hateful; or discriminatory;
                </li>

                <li>
                  Violate the rights of others including patent, trademark,
                  trade secret, copyright, privacy, publicity or other
                  proprietary rights;
                </li>

                <li>
                  Harass or harm another person, including but not limited to
                  exploit or endanger a minor;
                </li>

                <li>
                  Impersonate or attempt to impersonate any person or entity
                </li>

                <li>
                  Introduce or engage in activity that involves the use of
                  viruses, bots, worms, or any other computer code, files or
                  programs that interrupt, destroy, or limit the functionality
                  of any computer software or hardware or telecommunications
                  equipment, or otherwise permit the unauthorized use of or
                  access to a computer or a computer network;
                </li>

                <li>
                  Attempt to decipher, decompile, disassemble or reverse
                  engineer any of the software comprising the Buyer’s Network
                  Platform and Program Services;
                </li>

                <li>
                  Interfere with, damage, disable, disrupt, impair, create an
                  undue burden on, or gain unauthorized access to the Buyer’s
                  Network Platform and Program Services, including iLusso's
                  servers, networks or accounts;
                </li>

                <li>
                  Cover, remove, disable, block or obscure advertisements or
                  other portions of the Buyer’s Network Platform and Program
                  Services;
                </li>

                <li>
                  Delete or revise any information provided by or pertaining to
                  any other user of the Program, including without limitation
                  Eligible Buyers, Subordinate Buyers, and Sellers;
                </li>

                <li>
                  Use technology or any automated system such as scripts,
                  spiders, offline readers or bots in order to collect or
                  disseminate usernames, passwords, email addresses or other
                  data from the Buyer’s Network Platform or Program Services
                  Program Services, or to circumvent or modify any security
                  technology or software that is part of same;
                </li>

                <li>
                  Send or cause to send (directly or indirectly) unsolicited
                  bulk messages or other unsolicited bulk communications of any
                  kind through the Buyer’s Network Platform or Program Services.
                  If You do so, You acknowledge You will have caused substantial
                  harm to iLusso, but that the amount of harm would be extremely
                  difficult to measure. As a reasonable estimation of such harm,
                  and by way of liquidated damages and not as a penalty, You
                  agree to pay iLusso fifty dollars and zero cents ($50.00) for
                  each actual or intended recipient of such communication;
                </li>

                <li>
                  Solicit, collect or request any personal information for
                  commercial or unlawful purposes;
                </li>

                <li>
                  Engage in commercial activity (including but not limited to
                  advertisements or solicitations of business; sales; contests;
                  sweepstakes; creating, recreating, distributing or advertising
                  an index of any significant portion of Company Content; or
                  building a business using Company Content) without iLusso’s
                  prior written consent;
                </li>

                <li>
                  Using technology or other means to access, index, frame, or
                  link to The Company Sites (including the Company Content) that
                  is not authorized by the Buyer’s Network Platform or Program
                  Services, including by removing disabling, bypassing, or
                  circumventing any content protection or access control
                  mechanisms intended to prevent the unauthorized download,
                  stream capture, linking, framing, reproduction, access to, or
                  distribution of Company Content;
                </li>

                <li>
                  Use the Program Services in a manner inconsistent with any and
                  all Applicable Law;
                </li>

                <li>
                  Attempt, facilitate, induce, aid and abet, or encourage others
                  to do any of the foregoing.
                </li>
              </ul>
            </p>

            <p>
              iLusso reserves the right, but disclaims any obligation or
              responsibility, to remove user content that violates this
              Agreement, as determined by iLusso, or for any other reason, in
              iLusso's sole discretion and without notice to You. You
              acknowledge iLusso reserves the right to investigate and take
              appropriate legal action against anyone who, iLusso's sole
              discretion, violates this Agreement, including but not limited to,
              terminating their user account and/or reporting such user content,
              conduct, or activity to law enforcement authorities.
            </p>

            <p>
              You acknowledge, consent and agree that iLusso may access,
              preserve or disclose information You provide through the Buyer’s
              Network Platform or Program Services, including user content and
              Your account registration information, including when iLusso has a
              good faith belief that such access, preservation or disclosure is
              necessary in order to: (i) protect, enforce, or defend the legal
              rights, privacy, safety, or property of iLusso, its Company
              Affiliates, and third parties, including but not limited to
              Eligible Buyers, Subordinate Buyers, and Sellers; (ii) protect the
              safety, privacy, and security of users of the Buyer’s Network
              Platform or Program Services and/or members of the public; (iii)
              protect against fraud or for risk management purposes; (iv) comply
              with the law or legal process; or (v) respond to requests from
              public and government authorities. If iLusso sells all or part of
              its business or makes a sale or transfer of its assets or is
              otherwise involved in a merger or transfer of all or a material
              part of its business, iLusso may transfer Your information to the
              party or parties involved in the transaction as part of that
              transaction. iLusso reserves the right to limit the storage
              capacity of Your user content, including information and
              documentation You provided to iLusso through the Buyer’s Network
              Platform or Program Services. You assume full responsibility for
              maintaining backup copies of Your user content.
            </p>

            <p>
              <strong>IV. Arbitration Agreement and Dispute Resolution</strong>
            </p>

            <p>V. General Provisions</p>

            <p>
              <strong>Electronic Signatures.</strong> You will permit iLusso to
              capture Your signature in electronic or digital form. You hereby
              authorize iLusso and its iLusso’s service partners to apply Your
              electronic signature to documents necessary or incidental to Your
              use of Buyer’s Network Platform. You agree that Your electronic
              signature is intended to authenticate the document to which it is
              applied and shall have the same force and effect as a manual
              signature.
            </p>

            <p>
              <strong>Entire Agreement; Conflict.</strong> This Agreement,
              together with any separate agreement or agreements entered into
              separately between iLusso and You constitute the entire agreement
              between You and iLusso with respect to the subject matter of this
              Agreement and supersedes and replaces any other prior or
              contemporaneous agreements, or terms and conditions applicable to
              the subject matter of this Agreement. In the event of any conflict
              between this Agreement or any other separate agreement, which must
              be signed by iLusso to be binding, this Agreement shall be
              controlling unless otherwise expressly provided therein.
            </p>

            <p>
              <strong>Waiver.</strong> iLusso’s failure to enforce a provision
              in this Agreement is not a waiver of its right to do so later.
            </p>

            <p>
              <strong>Severability.</strong> The provisions of this Agreement
              shall be deemed severable and the invalidity or unenforceability
              of any provision shall not affect the validity or enforceability
              of the other provisions hereof. If any provision of this
              Agreement, or the application thereof to any person or entity or
              any circumstance, is invalid or unenforceable, (i) a suitable and
              equitable provision shall be substituted therefor in order to
              carry out, so far as may be valid and enforceable, the intent and
              purpose of such invalid or unenforceable provision, and (ii) the
              remainder of this Agreement and the application of such provision
              to other persons, entities or circumstances shall not be affected
              by such invalidity or unenforceability, nor shall such invalidity
              or unenforceability affect the validity or enforceability of such
              provision, or the application thereof, in any other jurisdiction.
            </p>

            <p>
              <strong>Assignment.</strong> (see above II A & D). You may not
              assign any of Your rights under this Agreement without iLusso’s
              prior written consent, and any such attempt will be void. iLusso
              may assign its rights, with or without Your consent, to any of its
              Company Affiliate, or to any successor in interest of any business
              associated with iLusso or the Program.
            </p>

            <p>
              <strong>
                Governing Law, Jurisdiction; Waiver of Jury Trial.
              </strong>
              THESE TERMS, AND ANY AND ALL AGREEMENTS OR AUTHORIZATIONS EXECUTED
              BY YOU OR iLusso IN CONNECTION HEREWITH SHALL BE GOVERNED BY AND
              CONSTRUED IN ACCORDANCE WITH THE LAWS OF THE STATE OF CALIFORNIA,
              WITHOUT GIVING EFFECT TO ITS CONFLICTS OF LAW PRINCIPLES THAT
              COULD CAUSE THE LAW OF ANOTHER STATE TO APPLY. THE PARTIES
              IRREVOCABLY SUBMIT TO THE EXCLUSIVE JURISDICTION OF ANY STATE OR
              FEDERAL COURT SITTING IN THE COUNTY OF ORANGE, CALIFORNIA IN ANY
              ACTION OR PROCEEDING ARISING OUT OF OR RELATING TO THESE TERMS OR
              ANY SUCH AGREEMENT. THE PARTIES EACH HEREBY WAIVE, TO THE FULLEST
              EXTENT PERMITTED BY LAW, ANY RIGHT TO TRIAL BY JURY OF ANY CLAIM,
              DEMAND, ACTION OR CAUSE OF ACTION ARISING UNDER THIS AGREEMENT.
            </p>

            <p>
              <strong>Notices.</strong>
              All notices under this Agreement shall be in writing and shall be
              delivered personally, mailed by overnight delivery, registered or
              certified mail, postage prepaid, mailed by express mail service or
              delivered by facsimile or e-mail to the addresses, facsimile or
              e-mail (i) for a You, based upon the most recent information
              provided by You to iLusso and currently maintained by iLusso and
              (ii) for iLusso, to iLusso _______________, facsimile __________,
              and e-mail ____________. Notices shall be effective upon receipt
              if personally delivered, on the third business day following the
              date of mailing if sent by certified or registered mail, and on
              the first business day following the date sent via overnight
              delivery or express mail. Notices sent by facsimile or e-mail are
              deemed delivered upon confirmation of transmission, except if the
              transmission is during the normal non-business hours of recipient,
              receipt shall be deemed effective upon the resumption of the
              normal business hours of recipient. You may change Your address or
              other notice information by notice to iLusso pursuant to this
              section. iLusso may update its notice address by amending this
              Agreement and/or the Program Terms.
            </p>

            <p>
              <strong>Construction.</strong>
              Construction. The following rules of construction apply to this
              Agreement: (i) any rule of construction to the effect that
              ambiguities are to be resolved against the drafting party shall
              not be applied in the construction or interpretation of this
              Agreement; (ii) the words “include” and “including,” and
              variations thereof, shall not be deemed to be terms of limitation,
              but rather shall be deemed to be followed by the words “without
              limitation” unless already followed by words of similar import;
              (iii) the term “or” is not exclusive; (iv) except as otherwise
              indicated, all references in this Agreement to “Sections” are
              intended to refer to a Section of this Agreement; and (v) the
              descriptive headings contained in this Agreement are included for
              convenience of reference only and shall not affect in any way the
              meaning or interpretation of this Agreement.
            </p>

            <p>
              <strong>Force Majeure</strong> In no event will either Party be
              liable or responsible to the other Party, or be deemed to have
              defaulted under or breached this Agreement, for any failure or
              delay in fulfilling or performing any term of this Agreement, when
              and to the extent such failure or delay is caused by any
              circumstances beyond such Party's reasonable control (a “Force
              Majeure Event”), including acts of God, flood, fire, earthquake or
              explosion, war, terrorism, invasion, riot or other civil unrest,
              embargoes or blockades, national or regional emergency, national
              or regional natural or economic disaster, extreme market
              disruptions, widespread industry shutdown, pandemic, extreme
              technology disruptions, strikes, labor stoppages or slowdowns or
              other industrial disturbances, passage of law or any action taken
              by a governmental or public authority, including imposing business
              closure or cessation or other restriction or prohibition or any
              complete or partial government shutdown, or national or regional
              shortage of adequate power or telecommunications or
              transportation. Either Party may terminate the Enrollment
              Agreement if a Force Majeure Event affecting the other Party’s
              performance under the Enrollment Agreement continues substantially
              uninterrupted for a period of 60 days or more. In the event of any
              failure or delay caused by a Force Majeure Event, the affected
              Party will give prompt written notice to the other Party stating
              the period of time the occurrence is expected to continue and use
              commercially reasonable efforts to end the failure or delay and
              minimize the effects of such Force Majeure Event.
            </p>

            <p>
              <strong>Index of Defined Agreement.</strong> The following is an
              index of all terms defined in this Agreement.
            </p>

            <p>
              <table width="100%" style={{ border: "1px solid" }}>
                <tbody>
                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Agreement
                    </td>
                    <td>
                      iLusso Buyer’s Network Program Agreement and Terms of Use
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Applicable Laws
                    </td>
                    <td>All applicable laws, rules, and regulations</td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Buyer’s Network Platform
                    </td>
                    <td>
                      the iLusso Buyer’s Network Program, which includes
                      iLussobuyers.com, the Program iOs application, the Program
                      Andriod application, and any iLusso branded URL, WAP site
                      and mobile application and other content and services that
                      link to the Program Terms
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Company Affiliates
                    </td>
                    <td>
                      the members, owners, employees, subsidiaries, agents,
                      insurance carriers, attorneys, and affiliates of
                      Hitfigure, LLC dba iLusso
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Company Content
                    </td>
                    <td>
                      any and all materials and content located on or utilized
                      through the iLusso Buyer’s Network Program, including, but
                      not limited to, information, text, files, images, videos,
                      sounds, works of authorship, software, applications,
                      product names, company names, trade names, logos, designs,
                      and any other materials or content related in any manner
                      to the Program
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Data
                    </td>
                    <td>
                      all information and records, whether oral, written,
                      visual, electronic, digital, or tangible transmitted,
                      received or stored on the Buyer’s Network Platform,
                      including, but not limited to, information and records
                      related to any Eligible Buyer, Exotic Car, Request for
                      Appraisal, Seller, Purchase Offer, Qualified Acquisition,
                      Subordinate Buyer, and commission payment.
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Eligible Buyer
                    </td>
                    <td>
                      an individual or entity who: (i) enters into the iLusso
                      Buyer’s Network Program Agreement and Terms of Use; (ii)
                      meets the qualifications for and becomes an Eligible
                      Buyer; and (iii) serves as an intermediary or facilitator
                      for referring to iLusso one or more prospective sellers of
                      Exotic Cars. To be an Eligible Buyer in the Program, You
                      must: (i) be at least 18 years of age; (ii) be a legal
                      resident of the United States of America; (iii) have the
                      legal right to work in the USA, whether as born or
                      naturalized citizen or with an immigration status that
                      allows for working in the USA; (iv) be registered in the
                      Program by providing all required information and
                      documentation, including without limitation a
                      completed/signed Form W-9 together with a current, valid
                      copy of a driver’s license or other government-issued
                      identification; and (v) agree to all current Program Terms
                      and future Program Terms as modified from time to time by
                      iLusso.
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Exotic Car
                    </td>
                    <td>
                      a car that qualifies under the Program including, but are
                      not limited to, Alfa Romeo, Audi R8, Bentley, Bugatti
                      Chiron, Ferrari, McLaren, Mercedes Benz (AMG models),
                      Lamborghini, Porsche 911(GT2, GT3, Turbo and Carrera
                      models), and Rolls Royce
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      iLusso
                    </td>
                    <td>Hitfigure, LLC dba iLusso</td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Our
                    </td>
                    <td>Hitfigure, LLC dba iLusso</td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Program
                    </td>
                    <td>iLusso Buyer’s Network Program</td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Program Services
                    </td>
                    <td>
                      services iLusso provides on its Buyer’s Network Platform
                      including, but are not limited to, messaging services,
                      video services, RRS feeds, mobile services, text
                      messaging, and any other feature, content or application
                      offered from time to time by iLusso
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Program Terms
                    </td>
                    <td>
                      Each and every term outlined in the Agreement or said
                      terms as modified from time to time by iLusso in its sole
                      discretion
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Purchase Offer
                    </td>
                    <td>
                      a written appraisal together with an offer to purchase
                      from iLusso to buy an Exotic Car, which sets out the terms
                      under which iLusso agrees to buy the Exotic Car including
                      an offered price, that is delivered to a prospective
                      Seller
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Qualified Acquisition
                    </td>
                    <td>
                      a Qualified Acquisition refers to an Exotic Car purchased
                      by iLusso due to an Eligible Buyer (or Subordinate Buyer)
                      submitting a Request for Appraisal through the Program
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Request for Appraisal
                    </td>
                    <td>
                      a request made by an Eligible Buyer for iLusso to send an
                      Exotic Car owner an appraisal and a Purchase Offer
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Seller
                    </td>
                    <td>
                      the owner of an Exotic Car who iLusso sends a Purchase
                      Offer to following a Request for Appraisal submitted by an
                      Eligible Buyer
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Subordinate Buyer
                    </td>
                    <td>
                      the owner of an Exotic Car who iLusso sends a Purchase
                      Offer to following a Request for Appraisal submitted by an
                      Eligible Buyer
                    </td>
                  </tr>

                  <tr>
                    <td>User Content</td>
                    <td></td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      We
                    </td>
                    <td>Hitfigure, LLC dba iLusso</td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      You
                    </td>
                    <td>a Buyer or a Subordinate Buyer</td>
                  </tr>

                  <tr>
                    <td
                      width="30%"
                      style={{ borderRight: "1px solid", height: "40px" }}
                    >
                      Your
                    </td>
                    <td>a Buyer or a Subordinate Buyer</td>
                  </tr>
                </tbody>
              </table>
            </p>

            <Row>
              <Col>
                <Button
                  onClick={onAccept}
                  className="button-primary button-large-text"
                >
                  Accept
                </Button>
              </Col>
            </Row>
          </div>
        </>
      </Modal>
    </>
  );
});

export default TermsCondition;
