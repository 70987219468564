import React from "react";

import { Col, Row } from "antd";
import "./index.scss";
import CompanyTable from "./Table";
import AddCompany from "./AddCompany";
import LayoutsLoggedIn from "../Layout/LoggedIn";

const Company = () => {
  return (
    <LayoutsLoggedIn>
      <Row justify="space-between">
        <Col>
          <h2>Company</h2>
        </Col>
        <Col>
          <AddCompany />
        </Col>
      </Row>
      <CompanyTable />
    </LayoutsLoggedIn>
  );
};

export default Company;
