import React, { useContext, useState } from "react";
import _ from "lodash";
import LayoutsLoggedIn from "../Layout/LoggedIn";
import api from "../../config/api";
import AuthContext from "../../store/authContext";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { CCol, CRow } from "@coreui/react";
import { useEffect } from "react";
import parse from "html-react-parser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Terms = React.memo(() => {
  const [isLoading, setLoading] = useState(true);
  const [resourcesData, setResourcesData] = useState("");

  const authCtx = useContext(AuthContext);

  useEffect(() => {
    api
      .post(
        `setting/getsettingbyname`,
        { name: "TERMS_CONDITION" },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status && response.data.data?.value != null) {
          setResourcesData(response.data.data?.value);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }, []);

  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    await api
      .post(
        `setting/addsetting`,
        { name: "TERMS_CONDITION", value: resourcesData },
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  return (
    <LayoutsLoggedIn>
      <CRow>
        {authCtx?.userData?.user_type == 0 ? (
          <CCol lg={10}>
            <form onSubmit={(e) => onSubmit(e)}>
              <div className="formcol">
                <label className="input-label">Terms and Conditions Text</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={resourcesData}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    setResourcesData(data);
                  }}
                />
              </div>
              <div className="formbtn">
                <button
                  type="submit"
                  className="input-btn display-inline-block w-auto"
                >
                  Update
                </button>
              </div>
            </form>
          </CCol>
        ) : (
          <CCol lg={12}>{resourcesData != "" && parse(resourcesData)}</CCol>
        )}
      </CRow>
    </LayoutsLoggedIn>
  );
});

export default Terms;
