import React from 'react';

const SvgPlusCircle = props => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.649 10.8946H13.1054V7.35097H10.8946V10.8946H7.35097V13.1054H10.8946V16.649H13.1054V13.1054H16.649V10.8946ZM24 12C24 10.3798 23.6826 8.80853 23.0572 7.32886C22.4524 5.89973 21.587 4.61587 20.4864 3.5152C19.3841 2.41295 18.1019 1.54757 16.6727 0.944337C15.1931 0.318991 13.6202 0.00158181 12.0016 0.00158181C10.3829 0.00158181 8.81011 0.318991 7.33044 0.944337C5.9013 1.54915 4.61745 2.41453 3.51678 3.5152C2.41453 4.61745 1.54915 5.89973 0.945914 7.32886C0.31741 8.80853 0 10.3798 0 12C0 13.6202 0.31741 15.1915 0.942756 16.6711C1.54757 18.1003 2.41295 19.3841 3.51362 20.4848C4.61587 21.5871 5.89814 22.4524 7.32728 23.0557C8.80853 23.6826 10.3814 24 12 24C13.6186 24 15.1915 23.6826 16.6711 23.0572C18.1003 22.4524 19.3841 21.5871 20.4848 20.4864C21.587 19.3841 22.4524 18.1019 23.0557 16.6727C23.6826 15.1915 24 13.6186 24 12ZM21.7892 12C21.7892 17.3976 17.3976 21.7892 12 21.7892C6.60245 21.7892 2.21082 17.3976 2.21082 12C2.21082 6.60245 6.60245 2.21082 12 2.21082C17.3976 2.21082 21.7892 6.60245 21.7892 12Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgPlusCircle;
