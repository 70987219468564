import React, { useContext, useEffect, useState } from "react";
import { CChartLine } from "@coreui/react-chartjs";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CProgress,
  CRow,
  CWidgetStatsA,
} from "@coreui/react";
import RefreshContext from "../../../store/refreshContext";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";
import moment from "moment";
import calenderIcon from "../../../images/calender.svg";

const DashboardChartWidget = React.memo(() => {
  const [dashboardData, setDashboardData] = useState({});
  const [isLoading, setLoading] = useState(true);
  const refreshCtx = useContext(RefreshContext);
  const authCtx = useContext(AuthContext);

  useEffect(() => {
    fetchDashboardCount();
  }, [refreshCtx.refresh]);

  async function fetchDashboardCount(formData = {}) {
    await api
      .post(`dashboard`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setDashboardData(response.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const leadActivitiyOnChange = (e) => {
    fetchDashboardCount({ duration: e.target.value });
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const onDealChartRangeChange = (value) => {
    setSelectedDate(value);
    if (value != null) {
      fetchDashboardCount({
        duration: "custom_date_deal",
        start_date_deal: moment(value[0]).format("YYYY-MM-DD"),
        end_date_deal: moment(value[1]).format("YYYY-MM-DD"),
      });
    } else {
      fetchDashboardCount();
    }
  };

  return (
    <>
      <CRow className="justify-content-between align-items-center my-3">
        <CCol lg xs={6} className="order-lg-1">
          <h2 className="mb-0">Dashboard</h2>
        </CCol>
        <CCol xs={8} lg="auto" className="d-none d-lg-block order-lg-2 order-last">
          <DateRangePicker
            onChange={(e) => onDealChartRangeChange(e)}
            value={selectedDate}
            format="M-d-y"
            yearPlaceholder="YYYY"
            monthPlaceholder="MM"
            dayPlaceholder="DD"
            calendarIcon={<img src={calenderIcon} style={{ height: "17px" }} />}
          />
        </CCol>
        <CCol xs={5} lg="auto" className="order-lg-3">
          <select
            className="input-select"
            onChange={(e) => leadActivitiyOnChange(e)}>
            <option value="7">Last 7 Days</option>
            <option value="30">Last 30 Days</option>
            <option value="75">Last 75 Days</option>
            <option value="365">Last 1 Year</option>
          </select>
        </CCol>
      </CRow>
      <CRow>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="chartWidgetCard bg-primary-gradient mb-4"
            color="primary-gradient"
            value={
              <>
                <span className="chartWidgetCount">
                  {dashboardData?.dashboard?.leadFormsSentCount}
                </span>
              </>
            }
            title="Leads Forms Sent"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels:
                    dashboardData?.chartDealActivity?.chartSentCount !=
                    undefined
                      ? Object.keys(
                          dashboardData?.chartDealActivity?.chartSentCount
                        )
                      : 0,
                  datasets: [
                    {
                      label: "Leads Forms Sent",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data:
                        dashboardData?.chartDealActivity?.chartSentCount !=
                        undefined
                          ? Object.values(
                              dashboardData?.chartDealActivity?.chartSentCount
                            )
                          : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </CCol>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="chartWidgetCard bg-warning-gradient mb-4"
            color="warning-gradient"
            value={
              <>
                <span className="chartWidgetCount">
                  {dashboardData?.dashboard?.leadFormsReceivedCount}
                </span>
              </>
            }
            title="Deals"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels:
                    dashboardData?.chartDealActivity?.chartReceivedCount !=
                    undefined
                      ? Object.keys(
                          dashboardData?.chartDealActivity?.chartReceivedCount
                        )
                      : 0,
                  datasets: [
                    {
                      label: "Deals",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data:
                        dashboardData?.chartDealActivity?.chartReceivedCount !=
                        undefined
                          ? Object.values(
                              dashboardData?.chartDealActivity
                                ?.chartReceivedCount
                            )
                          : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </CCol>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="chartWidgetCard bg-danger-gradient mb-4"
            color="danger-gradient"
            value={
              <>
                <span className="chartWidgetCount">
                  {dashboardData?.dashboard?.rejectedDeals}
                </span>
              </>
            }
            title="Rejected Deals"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels:
                    dashboardData?.chartDealActivity?.chartRejectedDeals !=
                    undefined
                      ? Object.keys(
                          dashboardData?.chartDealActivity?.chartRejectedDeals
                        )
                      : 0,
                  datasets: [
                    {
                      label: "Rejected Deals",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data:
                        dashboardData?.chartDealActivity?.chartRejectedDeals !=
                        undefined
                          ? Object.values(
                              dashboardData?.chartDealActivity
                                ?.chartRejectedDeals
                            )
                          : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </CCol>
        <CCol sm={6} lg={3}>
          <CWidgetStatsA
            className="chartWidgetCard bg-success-gradient mb-4"
            color="success-gradient"
            value={
              <>
                <span className="chartWidgetCount">
                  {dashboardData?.dashboard?.completedDeals}
                </span>
              </>
            }
            title="Completed Deals"
            chart={
              <CChartLine
                className="mt-3"
                style={{ height: "70px" }}
                data={{
                  labels: dashboardData?.chartDealActivity?.chartCompletedDeals
                    ? Object.keys(
                        dashboardData?.chartDealActivity?.chartCompletedDeals
                      )
                    : 0,
                  datasets: [
                    {
                      label: "Completed Deals",
                      backgroundColor: "transparent",
                      borderColor: "rgba(255,255,255,.55)",
                      data: dashboardData?.chartDealActivity
                        ?.chartCompletedDeals
                        ? Object.values(
                            dashboardData?.chartDealActivity
                              ?.chartCompletedDeals
                          )
                        : 0,
                    },
                  ],
                }}
                options={{
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  maintainAspectRatio: false,
                  scales: {
                    x: {
                      display: false,
                    },
                    y: {
                      display: false,
                    },
                  },
                  elements: {
                    line: {
                      borderWidth: 2,
                      tension: 0.4,
                    },
                    point: {
                      radius: 0,
                      hitRadius: 10,
                      hoverRadius: 4,
                    },
                  },
                }}
              />
            }
          />
        </CCol>
      </CRow>
      <CCard className="mb-4" style={{ background: "#25252F" }}>
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                Deal Activity
              </h4>
              <div className="small text-medium-emphasis"></div>
            </CCol>
          </CRow>
          <CChartLine
            style={{
              height: "300px",
              marginTop: "40px",
              background: "#25252f",
            }}
            data={{
              labels: dashboardData?.chartDealActivity?.chartCompletedDeals
                ? Object.keys(
                    dashboardData?.chartDealActivity?.chartCompletedDeals
                  )
                : 0,
              datasets: [
                {
                  label: "Lead Form Sent",
                  backgroundColor: "transparent",
                  borderColor: "#0f6bff",
                  pointHoverBackgroundColor: "#0f6bff",
                  borderWidth: 3,
                  data: dashboardData?.chartDealActivity?.chartSentCount
                    ? Object.values(
                        dashboardData?.chartDealActivity?.chartSentCount
                      )
                    : 0,
                },
                {
                  label: "Lead Form Received",
                  backgroundColor: "transparent",
                  borderColor: "#ff984a",
                  pointHoverBackgroundColor: "#ff984a",
                  borderWidth: 3,
                  data: dashboardData?.chartDealActivity?.chartReceivedCount
                    ? Object.values(
                        dashboardData?.chartDealActivity?.chartReceivedCount
                      )
                    : 0,
                },
                {
                  label: "Rejected Deals",
                  backgroundColor: "transparent",
                  borderColor: "#db2738",
                  pointHoverBackgroundColor: "#db2738",
                  borderWidth: 3,
                  borderDash: [10, 6],
                  data: dashboardData?.chartDealActivity?.chartRejectedDeals
                    ? Object.values(
                        dashboardData?.chartDealActivity?.chartRejectedDeals
                      )
                    : 0,
                },
                {
                  label: "Completed Deals",
                  backgroundColor: "transparent",
                  borderColor: "#00ac67",
                  pointHoverBackgroundColor: "#00ac67",
                  borderWidth: 3,
                  data: dashboardData?.chartDealActivity?.chartCompletedDeals
                    ? Object.values(
                        dashboardData?.chartDealActivity?.chartCompletedDeals
                      )
                    : 0,
                },
              ],
            }}
            options={{
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  display: false,
                },
              },
              scales: {
                x: {
                  grid: {
                    drawOnChartArea: false,
                  },
                },
                y: {
                  ticks: {
                    beginAtZero: true,
                    maxTicksLimit: 5,
                    stepSize: Math.ceil(250 / 5),
                    max: 250,
                  },
                },
              },
              elements: {
                line: {
                  tension: 0.4,
                },
                point: {
                  radius: 0,
                  hitRadius: 10,
                  hoverRadius: 4,
                  hoverBorderWidth: 3,
                },
              },
            }}
          />
        </CCardBody>
        <CCardFooter>
          <CRow xs={{ cols: 2 }} md={{ cols: 4 }} className="text-center">
            <CCol className="mb-sm-3 mb-0">
              <div className="text-medium-emphasis">Lead Forms Sent</div>
              <strong className="fs-5">
                {dashboardData?.dashboard?.leadFormsSentCount}
              </strong>
              <CProgress
                thin
                className="mt-3"
                color={`primary-gradient`}
                value={100}
              />
            </CCol>
            <CCol className="mb-sm-3 mb-0">
              <div className="text-medium-emphasis">Lead Form Received</div>
              <strong className="fs-5">
                {dashboardData?.dashboard?.leadFormsReceivedCount}
              </strong>
              <CProgress
                thin
                className="mt-3"
                color={`warning-gradient`}
                value={100}
              />
            </CCol>
            <CCol className="mb-sm-3 mb-0">
              <div className="text-medium-emphasis">Rejected Deals</div>
              <strong className="fs-5">
                {dashboardData?.dashboard?.rejectedDeals}
              </strong>
              <CProgress
                thin
                className="mt-3"
                color={`danger-gradient`}
                value={100}
              />
            </CCol>
            <CCol className="mb-sm-3 mb-0">
              <div className="text-medium-emphasis">Completed Deals</div>
              <strong className="fs-5">
                {dashboardData?.dashboard?.completedDeals}
              </strong>
              <CProgress
                thin
                className="mt-3"
                color={`success-gradient`}
                value={100}
              />
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </>
  );
});
export default DashboardChartWidget;
