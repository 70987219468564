import React, { useContext, useEffect, useState } from "react";
import _ from "lodash";
import CommonDrawerSection from "../../Common/Drawer/Section";
import CommonDrawerInfo from "../../Common/Drawer/Info";
import OfferDetailsEditHeader from "./EditHeader";
import { useForm } from "react-hook-form";
import { message } from "antd";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";

const OffersDetailsDeal = React.memo(({ data, onUpdateLead }) => {
  const [isEditing, setEditing] = useState(false);
  const [isLoading, setLoading] = useState([]);
  const [isOfferVisible, setOfferVisible] = useState(false);
  const [isOfferPercentageVisible, setOfferPercentageVisible] = useState(false);

  const {
    register,
    getValues,
    formState: { errors },
    trigger,
    watch,
  } = useForm();

  const dealStatus = watch("status");
  const handleCommission = watch("offer_type");

  useEffect(() => {
    if (dealStatus != 0 && dealStatus != undefined) {
      setOfferVisible(true);
    } else {
      setOfferVisible(false);
    }
  }, [dealStatus]);

  useEffect(() => {
    if (handleCommission == "percentage-commission") {
      setOfferPercentageVisible(true);
    } else {
      setOfferPercentageVisible(false);
    }
  }, [handleCommission]);

  const authCtx = useContext(AuthContext);
  const onSaveClick = async () => {
    const isValidated = await trigger();
    if (isValidated) {
      let formData = getValues();
      formData.deal_id = data.id;
      if (authCtx.userData.user_type == 1) {
        formData.asking_price = data?.asking_price;
        formData.status = data?.status;
        if (data?.status != 0) {
          formData.offer_type = data?.offer_type;
          if (data?.offer_type == "percentage-commission") {
            formData.offer_percentage = data?.offer_percentage;
          }
        }
      }
      await api
        .post(`deals/update-status`, formData, {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        })
        .then((response) => {
          setLoading(false);
          if (response.data.status) {
            message.success("Changes saved successfully!");
            onUpdateLead();
            setEditing(false);
          }
        })
        .catch((error) => {
          message.error(
            "Sorry we are not able to save data at this time. Please try again later."
          );
          setLoading(false);
        });
    }
  };

  if (!data) {
    return null;
  }

  return (
    <CommonDrawerSection>
      <OfferDetailsEditHeader
        isEditing={isEditing}
        onSaveClick={onSaveClick}
        setEditing={setEditing}
        title="Deal"
      />
      {!isEditing && (
        <>
          <CommonDrawerInfo title="Status:">
            {data.status == 0 && (
              <span className="leadStatusSelectBox offer-status-0">New</span>
            )}
            {data.status == 1 && (
              <span className="leadStatusSelectBox offer-status-1">
                Working
              </span>
            )}
            {data.status == 2 && (
              <span className="leadStatusSelectBox offer-status-2">
                Approved
              </span>
            )}
            {data.status == 3 && (
              <span className="leadStatusSelectBox offer-status-3">
                Rejected
              </span>
            )}
            {data.status == 4 && (
              <span className="leadStatusSelectBox offer-status-4">
                Completed
              </span>
            )}
            {data.status == 5 && (
              <span className="leadStatusSelectBox offer-status-5">
                Consignment
              </span>
            )}
          </CommonDrawerInfo>
          <CommonDrawerInfo title="Offer Amount:">
            ${Number(data.offer_amount != null ? data.offer_amount : 0)}
          </CommonDrawerInfo>
          <CommonDrawerInfo title="Asking Price:">
            {data?.asking_price}
          </CommonDrawerInfo>
          <CommonDrawerInfo title="Stock Number:">
            {data.stock_number}
          </CommonDrawerInfo>
          <CommonDrawerInfo title="Comment:">
            {data?.offer_comment}
          </CommonDrawerInfo>
        </>
      )}
      {isEditing && (
        <form>
          {authCtx.userData.user_type == 0 && (
            <div className="formcol">
              <label className="input-label">Status</label>
              <select
                className={`input-select ${errors.status ? "is-invalid" : ""}`}
                name="status"
                defaultValue={data.status}
                {...register("status", {
                  required: true,
                })}>
                <option value="0">New</option>
                <option value="1">Working</option>
                <option value="2">Approved</option>
                <option value="3">Rejected</option>
                <option value="4">Completed</option>
                <option value="5">Consignment</option>
              </select>
              {errors.status?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
            </div>
          )}
          {isOfferVisible || data.status != 0 ? (
            <>
              {authCtx.userData.user_type == 0 && (
                <div className="formcol">
                  <label className="input-label">Commission Type</label>
                  <select
                    className={`input-select ${
                      errors.status ? "is-invalid" : ""
                    }`}
                    defaultValue={data.offer_type}
                    {...register("offer_type", {
                      required: true,
                    })}>
                    <option value="fixed-commission">Auto Commission</option>
                    <option value="percentage-commission">
                      Manual Commission
                    </option>
                  </select>
                  {errors.offer_type?.type === "required" && (
                    <span className="formError">This field is required</span>
                  )}
                </div>
              )}
              {(isOfferPercentageVisible ||
                data.offer_type == "percentage-commission") &&
              authCtx.userData.user_type == 0 ? (
                <div className="formcol">
                  <label className="input-label">Commission</label>
                  <input
                    className={`input-text ${
                      errors.offer_percentage ? "is-invalid" : ""
                    }`}
                    placeholder="Commission (%)"
                    name="offer_percentage"
                    type="number"
                    defaultValue={data.offer_percentage}
                    {...register("offer_percentage", {
                      required: true,
                      min: 1,
                      max: 100,
                      valueAsNumber: true,
                    })}
                    min={1}
                    max={100}
                  />
                  {errors.offer_percentage?.type === "required" && (
                    <span className="formError">This field is required</span>
                  )}
                  {errors.offer_percentage?.type === "min" && (
                    <span className="formError">
                      Commission cannot be less then 1
                    </span>
                  )}
                  {errors.offer_percentage?.type === "max" && (
                    <span className="formError">
                      Commission cannot be greater then 100
                    </span>
                  )}
                </div>
              ) : (
                ""
              )}
              <div className="formcol">
                <label className="input-label">Offer Amount</label>
                <input
                  className={`input-text ${
                    errors.offer_amount ? "is-invalid" : ""
                  }`}
                  placeholder="Offer Amount ($)"
                  name="offer_amount"
                  type="number"
                  defaultValue={data.offer_amount != 0 ? data.offer_amount : ""}
                  {...register("offer_amount", {
                    valueAsNumber: true,
                  })}
                />
              </div>
              {authCtx.userData.user_type == 0 && (
                <div className="formcol">
                  <label className="input-label">Asking price</label>
                  <input
                    className={`input-text ${
                      errors.asking_price ? "is-invalid" : ""
                    }`}
                    placeholder="Asking price ($)"
                    name="asking_price"
                    type="number"
                    defaultValue={
                      data?.asking_price != undefined ? data?.asking_price : ""
                    }
                    {...register("asking_price", {
                      valueAsNumber: true,
                    })}
                  />
                </div>
              )}
              <div className="formcol">
                <label className="input-label">Comments</label>
                <textarea
                  className={`input-text`}
                  placeholder="Comments"
                  defaultValue={data?.offer_comment}
                  {...register("offer_comment")}></textarea>
              </div>
            </>
          ) : (
            ""
          )}
          <div className="formcol">
            <label className="input-label">Stock Number</label>
            <input
              className={`input-text ${
                errors.stock_number ? "is-invalid" : ""
              }`}
              placeholder="Stock Number"
              name="stock_number"
              defaultValue={data.stock_number}
              {...register("stock_number")}
            />
          </div>
        </form>
      )}
    </CommonDrawerSection>
  );
});

export default OffersDetailsDeal;
