import React from "react";
import { Link } from "react-router-dom";
import routes from "../../config/routes";
import logo from "../../images/logo.svg";
import thankYouBg from "../../images/thank-you-bg.jpg";

const ThankYou = React.memo(() => {
  return (
    <div
      className="thank-you-wrap"
      style={{ backgroundImage: `url(${thankYouBg})` }}>
      <div className="thank-you-con">
        <img src={logo} alt="logo" />
        <h1>Thank you!</h1>
        <p>Your submission has been sent.</p>
        <Link to={routes.Home} className="btn btn-red">
          BACK TO HOME
        </Link>
      </div>
    </div>
  );
});

export default ThankYou;
