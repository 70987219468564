import React from "react";

const SvgFilter = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M10.52,22.53a.78.78,0,0,1-.46-.81c0-1.1,0-2.2,0-3.31a.42.42,0,0,0-.15-.35c-1.51-1.51-3-3-4.52-4.53a.77.77,0,0,0-.62-.26,1.87,1.87,0,0,1-1.69-.91,1.81,1.81,0,0,1,0-1.94,1.82,1.82,0,0,1,1.65-1c1.59,0,3.19,0,4.79,0H19.2a2,2,0,0,1,2,1.48,1.92,1.92,0,0,1-1.81,2.38,1.13,1.13,0,0,0-.85.38C17,15.12,15.57,16.58,14.1,18a.47.47,0,0,0-.16.4c0,.68,0,1.36,0,2a.7.7,0,0,1-.43.71l-2.61,1.3-.05,0Zm.78-1.62,1.25-.62a.23.23,0,0,0,.16-.25c0-.64,0-1.29,0-1.93a.91.91,0,0,1,.3-.71c1.32-1.31,2.63-2.63,3.94-3.94l.16-.17H6.91l.17.18,3.95,4a.83.83,0,0,1,.27.66v2.83ZM12,10.64H4.71a.69.69,0,0,0-.68.64.7.7,0,0,0,.55.74,1.08,1.08,0,0,0,.31,0H19.33a.7.7,0,1,0-.14-1.4Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M18.62,6.67a2.6,2.6,0,1,1,2.59-2.61A2.61,2.61,0,0,1,18.62,6.67Zm0-1.24A1.36,1.36,0,0,0,20,4.07a1.37,1.37,0,1,0-2.73,0A1.36,1.36,0,0,0,18.62,5.43Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M12,8A2.6,2.6,0,1,1,14.6,5.32,2.6,2.6,0,0,1,12,8ZM13.37,5.4A1.37,1.37,0,1,0,12,6.76,1.37,1.37,0,0,0,13.37,5.4Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M8,4.09A2.6,2.6,0,1,1,5.46,1.47,2.6,2.6,0,0,1,8,4.09ZM5.39,5.43a1.37,1.37,0,0,0,0-2.73A1.38,1.38,0,0,0,4,4.07,1.36,1.36,0,0,0,5.39,5.43Z"
    />
  </svg>
);

export default SvgFilter;
