import React, { useContext, useEffect } from "react";

import { Col, Row } from "antd";

import "./index.scss";
import PaymentHistoryTable from "./Table";
import LayoutsLoggedIn from "../Layout/LoggedIn";
import AuthContext from "../../store/authContext";
import routes from "../../config/routes";
import { useNavigate } from "react-router-dom";

const PaymentHistory = () => {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  useEffect(() => {
    if (authCtx.userData?.user_type != 1) {
      navigate(routes.Dashboard);
    }
  }, [authCtx]);

  return (
    <>
      <LayoutsLoggedIn>
        <Row justify="space-between">
          <Col>
            <h2>Payment History</h2>
          </Col>
          <Col></Col>
        </Row>
        <PaymentHistoryTable />
      </LayoutsLoggedIn>
    </>
  );
};

export default PaymentHistory;
