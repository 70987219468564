import React, { useCallback, useEffect, useRef, useState } from "react";
import _ from "lodash";
import "./index.scss";
import LayoutsInquiry from "../Layout/Inquiry";
import CommonReferralUser from "../Common/ReferralUser";
import { Button, Col, message, Row, Spin } from "antd";
import SvgSuccess from "../../images/success";
import "./index.scss";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { formatPhoneNumber } from "../../utils/formats";
import api from "../../config/api";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import routes from "../../config/routes";
import AppLoading from "../Common/AppLoading";

const years = _.map(
  new Array(new Date().getFullYear() + 1 - 1990),
  (_value, idx) => ({
    label: new Date().getFullYear() + 1 - idx,
    value: new Date().getFullYear() + 1 - idx,
  })
);

const states = _.map(
  {
    AL: "Alabama",
    AK: "Alaska",
    AS: "American Samoa",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    DC: "District Of Columbia",
    FL: "Florida",
    GA: "Georgia",
    GU: "Guam",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MH: "Marshall Islands",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PW: "Palau",
    PA: "Pennsylvania",
    PR: "Puerto Rico",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VI: "Virgin Islands",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  },
  (label, value) => ({ label, value })
);

const Inquiry = React.memo(() => {
  const [isLoading, setLoading] = useState(false);
  const [isVisible, setVisible] = useState();
  const [successText, setSuccessText] = useState();
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [trims, setTrims] = useState([]);
  const [buyerData, setBuyerData] = useState([]);
  const [fieldLoading, setfieldLoading] = useState(false);
  const [autoFill, setAutoFill] = useState(false);
  const [autoFillMake, setAutoFillMake] = useState(false);
  const [listedForSale, setListedForSale] = useState(false);
  const countRef = useRef();
  const makeRef = useRef();
  const modelRef = useRef();
  const trimRef = useRef();

  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("This field is required"),
    email: Yup.string().email().required("This field is required"),
    phone: Yup.string().required("This field is required"),
    vehicleVin: Yup.string().max(17, "Please Enter Maximum 17 Characters."),
    vehicleYear: Yup.string().required("This field is required"),
    vehicleMake: Yup.string().required("This field is required"),
    vehicleModel: Yup.string().required("This field is required"),
    vehicleTrim: Yup.string().required("This field is required"),
    vehicleMileage: Yup.string().required("This field is required"),
    vehicleExteriorColor: Yup.string().required("This field is required"),
    vehicleInteriorColor: Yup.string().required("This field is required"),
    state: Yup.string().required("This field is required"),
    listed_for_sale: Yup.boolean(),
    listed_url: Yup.string().when("listed_for_sale", {
      is: true,
      then: Yup.string().required("This field is required"),
    }),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm(formOptions);

  const phone = watch("phone");

  useEffect(() => {
    if (phone != "") {
      setValue("phone", formatPhoneNumber(phone).formatted);
    }
  }, [phone]);

  const { lead_id, user_id } = useParams();
  useEffect(() => {
    getBuyerDetail();
  }, []);

  const getBuyerDetail = async () => {
    setLoading(true);
    await api
      .post(`${lead_id != undefined ? "leads/get-lead" : "leads/get-buyer"}`, {
        lead_id: lead_id,
        user_id: user_id,
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          if (response.data.data?.status == 0) {
            setTimeout(() => {
              navigate(routes.Home);
            }, 10000);
          }
          setBuyerData(response.data.data);
          if (lead_id != undefined) {
            setValue("name", response.data.data?.name);
            setValue("email", response.data.data?.email);
            setValue(
              "phone",
              formatPhoneNumber(response.data.data?.phone).formatted
            );
          }
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          "Sorry we are not able to complete your request at this time. Please try again later."
        );
      });
  };
  const onSubmit = async (values) => {
    setLoading(true);
    let data = {
      name: values.name,
      email: values.email,
      phone: values.phone.replace(/-/g, ""),
      vehicle_year: values.vehicleYear,
      vehicle_make: countRef.current
        ? _.toString(values.vehicleMake)
        : _.toString(makeRef.current),
      vehicle_model: countRef.current
        ? _.toString(values.vehicleModel)
        : _.toString(modelRef.current),
      vehicle_trim: countRef.current
        ? _.toString(values.vehicleTrim)
        : _.toString(trimRef.current),
      vehicle_mileage: values.vehicleMileage,
      vehicle_vin: values.vehicleVin,
      vehicle_exterior_color: values.vehicleExteriorColor,
      vehicle_interior_color: values.vehicleInteriorColor,
      state: values.state,
      note: values.note,
      listed_for_sale: values.listed_for_sale,
      listed_url: values.listed_url,
    };
    if (lead_id != undefined) {
      data = {
        ...data,
        lead_id: buyerData.id,
        user_id: buyerData.user_id,
      };
    } else {
      data = {
        ...data,
        user_id: user_id,
      };
    }

    await api
      .post(`deals/add-deal`, data)
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setSuccessText("Offer Request Form submitted successfully");
          api
            .post(`deals/add-deal-hubspot`, data)
            .then((response) => {})
            .catch((error) => {});
          reset();
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          "Sorry we are not able to complete your request at this time. Please try again later."
        );
      });
  };

  const formData = watch();

  useEffect(() => {
    countRef.current = autoFill;
  }, [autoFill]);

  useEffect(() => {
    if (
      formData.listed_for_sale != 0 &&
      formData.listed_for_sale != undefined
    ) {
      setListedForSale(true);
    } else {
      setListedForSale(false);
    }
  }, [formData.listed_for_sale]);

  useEffect(() => {
    if (formData.vehicleVin) {
      if (formData.vehicleVin.length == 17) {
        setfieldLoading(true);
        api
          .get(`deals/chrome-data?vin=${formData.vehicleVin}`)
          .then((response) => {
            setfieldLoading(false);
            if (response.data.status) {
              if (response.data.data.year != "0") {
                if (response.data.data.year) {
                  setAutoFill(true);
                  setValue("vehicleYear", response.data.data.year);
                }
                if (response.data.data.model != "") {
                  setValue("vehicleModel", response.data.data.model);
                }
                if (response.data.data.trim != "") {
                  setValue("vehicleTrim", response.data.data.trim);
                }
                if (response.data.data.make != "") {
                  setAutoFillMake(true);
                  setValue("vehicleMake", response.data.data.make);
                } else {
                  setAutoFill(false);
                }
              } else {
                setAutoFill(false);
                setAutoFillMake(false);
              }
            } else {
              setAutoFill(false);
              setAutoFillMake(false);
            }
          })
          .catch((error) => {
            setfieldLoading(false);
          });
      } else {
        setAutoFill(false);
        setAutoFillMake(false);
      }
    }
  }, [formData.vehicleVin]);

  const yearChange = async (value, key) => {
    setfieldLoading(true);
    setValue("vehicleYear", value);
    await api
      .get(`deals/chrome-data?getMake=true&modelYear=${value}`)
      .then((response) => {
        setfieldLoading(false);
        setModels([]);
        setTrims([]);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let makeArray = [];
            Object.entries(response.data.data).forEach((element) => {
              makeArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setMakes(makeArray);
          } else {
            setMakes([]);
          }
        } else {
          setMakes([]);
        }
      })
      .catch((error) => {
        setfieldLoading(false);
      });
  };

  const makeChange = async (value, key) => {
    setfieldLoading(true);
    makeRef.current = key;
    await api
      .get(
        `deals/chrome-data?getModel=true&modelYear=${formData.vehicleYear}&modelMake=${value}`
      )
      .then((response) => {
        setfieldLoading(false);
        setTrims([]);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let modelArray = [];
            Object.entries(response.data.data).forEach((element) => {
              modelArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setModels(modelArray);
          } else {
            setModels([]);
          }
        } else {
          setModels([]);
        }
      })
      .catch((error) => {
        setfieldLoading(false);
        setModels([]);
      });
  };

  const modelChange = async (value, key) => {
    setfieldLoading(true);
    modelRef.current = key;
    await api
      .get(`deals/chrome-data?getTrim=true&modelId=${value}`)
      .then((response) => {
        setfieldLoading(false);
        if (response.data.status) {
          if (Object.keys(response.data.data).length > 0) {
            let trimArray = [];
            Object.entries(response.data.data).forEach((element) => {
              trimArray.push({
                value: element[0],
                label: element[1],
              });
            });
            setTrims(trimArray);
          } else {
            setTrims([]);
          }
        } else {
          setTrims([]);
        }
      })
      .catch((error) => {
        setfieldLoading(false);
        setTrims([]);
      });
  };

  const trimChange = (value, key) => {
    trimRef.current = key;
  };

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <LayoutsInquiry>
      {successText ? (
        <>
          <div className="common-form-splash">
            <Row className="padding-bottom-1_5" justify="center">
              <SvgSuccess />
            </Row>
            <h3 className="ds-title">All Done!</h3>
            <Row className="color-base" justify="center">
              <Col className="text-center" span={20}>
                Your form has been submitted and our appraisal team will be
                reaching out shortly with the value of your vehicle.
              </Col>
            </Row>
            <Row className="common-referral-user-form" justify="center">
              <Col>
                <CommonReferralUser buyerData={buyerData} />
              </Col>
            </Row>
            <Row justify="center">
              <Button
                block
                className="button-primary"
                href="https://ilusso.com/"
                htmlType="link"
                type="primary">
                Visit iLusso.com
              </Button>
            </Row>
          </div>
        </>
      ) : (
        <>
          <div className="inquiryForm">
            {fieldLoading ? <Spin className="field-loader" /> : ""}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div
                className={`${
                  buyerData?.status != 0 ? "inquiryFormHeader" : "text-center"
                } `}>
                <h3 className="ds-title">Offer Request Form</h3>
                {buyerData?.status == 0 ? (
                  <>
                    <p>
                      You cannot submit leads as your account is inactive.
                      Please get in touch with your administrator to reactivate
                      your account.
                    </p>
                    <div className="formbtn">
                      <Link
                        className="input-btn d-inline-block w-auto"
                        to={routes.Home}>
                        Back to Home
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <p>
                      {lead_id != undefined
                        ? "Tell us about your vehicle and we'll tell you what it's worth."
                        : "Tell us about your vehicle and receive a cash offer now."}
                    </p>
                    <p>
                      Form sent by:{" "}
                      {buyerData?.userName != undefined
                        ? buyerData?.userName
                        : buyerData?.name}
                      , Thank You
                    </p>
                  </>
                )}
              </div>
              {buyerData?.status != 0 && (
                <>
                  <h4 className="mb-4">Contact Information</h4>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="formcol">
                        <label className="input-label">Name</label>
                        <input
                          className={`input-text ${
                            errors.name ? "is-invalid" : ""
                          }`}
                          placeholder="Name"
                          {...register("name")}
                        />
                        {errors.name && (
                          <span className="formError">
                            {errors.name?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="formcol">
                        <label className="input-label">Email</label>
                        <input
                          className={`input-text ${
                            errors.email ? "is-invalid" : ""
                          }`}
                          placeholder="Email"
                          {...register("email")}
                        />
                        {errors.email && (
                          <span className="formError">
                            {errors.email?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="formcol">
                        <label className="input-label">Phone</label>
                        <input
                          className={`input-text ${
                            errors.phone ? "is-invalid" : ""
                          }`}
                          placeholder="Phone"
                          {...register("phone")}
                          maxLength={12}
                        />
                        {errors.phone && (
                          <span className="formError">
                            {errors.phone?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="formcol">
                        <label className="input-label">State</label>
                        <select
                          className={`input-select ${
                            errors.state ? "is-invalid" : ""
                          }`}
                          {...register("state")}>
                          <option>State vehicle is located in</option>
                          {_.map(states, (state) => (
                            <option key={state.value} value={state.label}>
                              {state.label}
                            </option>
                          ))}
                        </select>
                        {errors.state && (
                          <span className="formError">
                            {errors.state?.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <h4 className="mb-4">Vehicle Information</h4>
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="input-label">
                        Is this vehicle listed for sale anywhere?
                      </label>
                      <select
                        className={`input-select ${
                          errors.listed_for_sale ? "is-invalid" : ""
                        }`}
                        {...register("listed_for_sale")}>
                        <option value="0">No</option>
                        <option value="1">Yes</option>
                      </select>
                    </div>
                    {listedForSale == true && (
                      <div className="col-lg-6">
                        <div className="formcol">
                          <label className="input-label">Listed Source</label>
                          <input
                            className={`input-text ${
                              errors.listed_url ? "is-invalid" : ""
                            }`}
                            placeholder="Listed Source"
                            {...register("listed_url")}
                          />
                          {errors.listed_url && (
                            <span className="formError">
                              {errors.listed_url?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="col-lg-6">
                      <div className="formcol">
                        <label className="input-label">VIN</label>
                        <input
                          className={`input-text ${
                            errors.vehicleVin ? "is-invalid" : ""
                          }`}
                          placeholder="VIN"
                          {...register("vehicleVin")}
                          maxLength={17}
                        />
                        {errors.vehicleVin && (
                          <span className="formError">
                            {errors.vehicleVin?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="formcol">
                        <label className="input-label">Year</label>
                        <select
                          className={`input-select ${
                            errors.vehicleYear ? "is-invalid" : ""
                          }`}
                          name="vehicleYear"
                          {...register("vehicleYear")}
                          disabled={autoFill}
                          onChange={(e) =>
                            yearChange(
                              e.target.value,
                              e.target.options[e.target.selectedIndex].text
                            )
                          }>
                          <option value="" selected={!autoFill}>
                            Select Year
                          </option>
                          {_.map(years, (option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                        {errors.vehicleYear && (
                          <span className="formError">
                            {errors.vehicleYear?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="formcol">
                        <label className="input-label">Make</label>
                        {!autoFillMake && (
                          <select
                            className={`input-select ${
                              errors.vehicleMake ? "is-invalid" : ""
                            }`}
                            {...register("vehicleMake")}
                            onChange={(e) =>
                              makeChange(
                                e.target.value,
                                e.target.options[e.target.selectedIndex].text
                              )
                            }>
                            <option value="" selected={!autoFill}>
                              Select Make
                            </option>
                            {_.map(makes, (option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {autoFillMake && (
                          <input
                            className={`input-text ${
                              errors.vehicleMake ? "is-invalid" : ""
                            }`}
                            placeholder="Make"
                            {...register("vehicleMake")}
                            disabled={autoFillMake}
                          />
                        )}
                        {errors.vehicleMake && (
                          <span className="formError">
                            {errors.vehicleMake?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="formcol">
                        <label className="input-label">Model</label>
                        {!autoFillMake && (
                          <select
                            className={`input-select ${
                              errors.vehicleModel ? "is-invalid" : ""
                            }`}
                            {...register("vehicleModel")}
                            onChange={(e) =>
                              modelChange(
                                e.target.value,
                                e.target.options[e.target.selectedIndex].text
                              )
                            }>
                            <option value="" selected={!autoFill}>
                              Select Model
                            </option>
                            {_.map(models, (option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {autoFillMake && (
                          <input
                            className={`input-text ${
                              errors.vehicleModel ? "is-invalid" : ""
                            }`}
                            placeholder="Make"
                            {...register("vehicleModel")}
                          />
                        )}
                        {errors.vehicleModel && (
                          <span className="formError">
                            {errors.vehicleModel?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="formcol">
                        <label className="input-label">Trim</label>
                        {!autoFillMake && (
                          <select
                            className={`input-select ${
                              errors.vehicleTrim ? "is-invalid" : ""
                            }`}
                            {...register("vehicleTrim")}
                            onChange={(e) =>
                              trimChange(
                                e.target.value,
                                e.target.options[e.target.selectedIndex].text
                              )
                            }>
                            <option value="" selected={!autoFill}>
                              Select Trim
                            </option>
                            {_.map(trims, (option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        )}
                        {autoFillMake && (
                          <input
                            className={`input-text ${
                              errors.vehicleTrim ? "is-invalid" : ""
                            }`}
                            placeholder="Make"
                            {...register("vehicleTrim")}
                          />
                        )}
                        {errors.vehicleTrim && (
                          <span className="formError">
                            {errors.vehicleTrim?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="formcol">
                        <label className="input-label">Mileage</label>
                        <input
                          className={`input-text ${
                            errors.vehicleMileage ? "is-invalid" : ""
                          }`}
                          placeholder="Mileage"
                          {...register("vehicleMileage")}
                        />
                        {errors.vehicleMileage && (
                          <span className="formError">
                            {errors.vehicleMileage?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="formcol">
                        <label className="input-label">Exterior Color</label>
                        <input
                          className={`input-text ${
                            errors.vehicleExteriorColor ? "is-invalid" : ""
                          }`}
                          placeholder="Exterior Color"
                          {...register("vehicleExteriorColor")}
                        />
                        {errors.vehicleExteriorColor && (
                          <span className="formError">
                            {errors.vehicleExteriorColor?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="formcol">
                        <label className="input-label">Interior Color</label>
                        <input
                          className={`input-text ${
                            errors.vehicleInteriorColor ? "is-invalid" : ""
                          }`}
                          placeholder="Interior Color"
                          {...register("vehicleInteriorColor")}
                        />
                        {errors.vehicleInteriorColor && (
                          <span className="formError">
                            {errors.vehicleInteriorColor?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="formcol">
                        <label className="input-label">Note</label>
                        <textarea
                          rows={3}
                          className={`input-text ${
                            errors.note ? "is-invalid" : ""
                          }`}
                          placeholder="Note"
                          {...register("note")}></textarea>
                        {errors.note && (
                          <span className="formError">
                            {errors.note?.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="text-center color-base inquiryFormFooter">
                        By submitting an offer request, you confirm that you've{" "}
                        <br />
                        read and accepted our{" "}
                        <Link to={routes.InquiryTerms}>
                          Terms of Service
                        </Link>{" "}
                        and{" "}
                        <Link to={routes.InquiryPrivacy}>Privacy Policy</Link>.
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="formbtn">
                        <button
                          type="submit"
                          className="input-btn"
                          disabled={isLoading ?? buyerData?.status !== 0}>
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </form>
          </div>
        </>
      )}
    </LayoutsInquiry>
  );
});

export default Inquiry;
