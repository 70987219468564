import React from 'react';

const SvgOffers = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 5V19.1818C22 20.736 20.7487 22 19.2 22H4.8C3.25134 22 2 20.736 2 19.1818V5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5ZM20 17.8293C19.6872 17.9398 19.3506 18 19 18H5C4.64936 18 4.31278 17.9398 4 17.8293V19.1818C4 19.6359 4.36043 20 4.8 20H19.2C19.6396 20 20 19.6359 20 19.1818V17.8293ZM20 15V5C20 4.44772 19.5523 4 19 4H5C4.44772 4 4 4.44772 4 5V15C4 15.5523 4.44772 16 5 16H19C19.5523 16 20 15.5523 20 15ZM13 6H13.9531C14.5054 6 14.9531 6.44772 14.9531 7V7.45425C14.9531 8.00653 14.5054 8.45425 13.9531 8.45425C13.6022 8.45425 13.2935 8.27349 13.115 8H11.0139V9.00091H13C14.1046 9.00091 15 9.89634 15 11.0009V12C15 13.1046 14.1046 14 13 14C12.9953 14.5483 12.5494 14.9912 12 14.9912C11.4506 14.9912 11.0047 14.5483 11 14H10C9.4378 14 8.98608 13.5367 9.00033 12.9747L9.01427 12.4246C9.02826 11.8725 9.48718 11.4363 10.0393 11.4503C10.4206 11.4599 10.7466 11.6818 10.9072 12H13V11.0009H11.0139C9.90938 11.0009 9.01395 10.1055 9.01395 9.00091V8C9.01395 6.90008 9.90186 6.00754 11 6.00005C11 5.44772 11.4477 5 12 5C12.5523 5 13 5.44772 13 6Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgOffers;
