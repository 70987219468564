import { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../store/authContext";

const PrivateRoute = ({ children }) => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  return isLoggedIn ? children : <Navigate to="/" replace />;
};

export default PrivateRoute;
