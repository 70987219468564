import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { useForm } from "react-hook-form";
import { formatPhoneNumber } from "../../../utils/formats";
import api from "../../../config/api";
import AuthContext from "../../../store/authContext";

const TeamAddMemberText = React.memo(({ onFinish }) => {
  const [loading, setLoading] = useState();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const mobile_no = watch("mobile_no");

  useEffect(() => {
    setValue("mobile_no", formatPhoneNumber(mobile_no).formatted);
  }, [mobile_no]);

  const authCtx = useContext(AuthContext);
  const onSubmit = async (formData) => {
    setLoading(true);
    formData.send_type = 1;
    formData.mobile_no = formData.mobile_no.replace(/-/g, "");
    if (authCtx.userData.user_type == 1) {
      formData.buyer_referral_id = authCtx.userData.referral_id;
    }
    await api
      .post(`buyers/add-buyer`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          onFinish(formData.mobile_no);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  return (
    <>
      <Row className="color-base" justify="center">
        <Col className="text-center padding-bottom-1_5">
          Enter a phone number below to send a team invite link to your team
          member.
        </Col>
      </Row>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="formcol">
          <input
            className={`input-text ${errors.mobile_no ? "is-invalid" : ""}`}
            placeholder="Phone"
            type="tel"
            {...register("mobile_no", {
              required: true,
            })}
            maxLength={12}
          />
          {errors.mobile_no?.type === "required" && (
            <span className="formError">Phone Number is required.</span>
          )}
        </div>
        {authCtx.userData.user_type == 0 && (
          <div className="formcol">
            <input
              className={`input-text ${
                errors.buyer_referral_id ? "is-invalid" : ""
              }`}
              placeholder="Buyer Referral ID"
              name="text"
              {...register("buyer_referral_id", {
                required: true,
              })}
            />
            {errors.buyer_referral_id?.type === "required" && (
              <span className="formError">Buyer Referral ID is required.</span>
            )}
          </div>
        )}
        <div className="formbtn">
          <button htmltype="submit" className="input-btn">
            Send Text Link
          </button>
        </div>
      </form>
    </>
  );
});

export default TeamAddMemberText;
