import React, { useContext } from "react";

import { Col, Row } from "antd";
import "./index.scss";

import RoleTable from "./Table";
import AddRole from "./AddRole";
import LayoutsLoggedIn from "../Layout/LoggedIn";
import AuthContext from "../../store/authContext";

const Role = () => {
  const authCtx = useContext(AuthContext);
  return (
    <LayoutsLoggedIn>
      <Row justify="space-between">
        <Col>
          <h2>Role</h2>
        </Col>
        <Col>
          {(authCtx.userData?.user_type == 0 ||
            authCtx.userData?.allowAll == 1 ||
            authCtx.userData?.permissions.includes("role_add")) && <AddRole />}
        </Col>
      </Row>
      <RoleTable />
    </LayoutsLoggedIn>
  );
};

export default Role;
