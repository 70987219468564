import React, { useContext, useState } from "react";
import _ from "lodash";

import { Button, Drawer, message } from "antd";
import SvgPlus from "../../../images/plus";
import CommonDrawerSection from "../../Common/Drawer/Section";
import RefreshContext from "../../../store/refreshContext";
import { useForm } from "react-hook-form";
import api from "../../../config/api";
import AuthContext from "../../../store/authContext";

const AddCompany = React.memo(() => {
  const [visible, setVisible] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({});

  const showDrawer = () => {
    setVisible(true);
  };

  const [isLoading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);
  const onSubmit = async (formValues) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("title", formValues.title);
    formData.append("domain", formValues.domain);
    formData.append("status", formValues.status);
    formData.append("logo", uploadedFile?.file);

    await api
      .post(`company/add-company`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          reset();
          onClose();
          refreshCtx.onRefresh();
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const onFileUploadHandler = (event) => {
    setUploadedFile({
      image: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    });
  };

  const onClose = () => {
    setVisible(false);
    reset();
  };

  return (
    <>
      <Button
        block
        className="button-primary button-large-text"
        htmlType="button"
        type="primary"
        onClick={showDrawer}
      >
        <SvgPlus /> Add Company
      </Button>
      <Drawer
        className="add-company"
        destroyOnClose
        mask={false}
        onClose={onClose}
        placement="right"
        title="Add Company"
        visible={visible}
        width={500}
      >
        <CommonDrawerSection>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formcol">
              <label className="input-label">Title</label>
              <input
                className={`input-text ${errors.title ? "is-invalid" : ""}`}
                placeholder="Title"
                {...register("title", {
                  required: true,
                })}
              />
              {errors.title?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">Domain</label>
              <input
                className={`input-text ${errors.domain ? "is-invalid" : ""}`}
                placeholder="Domain"
                {...register("domain", {
                  required: true,
                })}
              />
              {errors.domain?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">Status</label>
              <select
                className={`input-select ${errors.status ? "is-invalid" : ""}`}
                {...register("status", {
                  required: true,
                })}
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
              {errors.status?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">Logo</label>
              <input
                className={`input-text ${errors.logo ? "is-invalid" : ""}`}
                placeholder="Logo"
                type="file"
                {...register("logo", {
                  required: true,
                })}
                onChange={(e) => onFileUploadHandler(e)}
              />
              {uploadedFile?.image != undefined && (
                <img
                  src={uploadedFile?.image}
                  alt=""
                  className="img-fluid"
                  style={{ maxHeight: "200px", objectFit: "cover" }}
                />
              )}
              {errors.logo?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formbtn">
              <button type="submit" className="input-btn">
                Add Company
              </button>
            </div>
          </form>
        </CommonDrawerSection>
      </Drawer>
    </>
  );
});

export default AddCompany;
