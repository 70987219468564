import React from 'react';

import { Layout, Row } from 'antd';

import SvgLogo from '../../images/logo';
import './Inquiry.scss';

const { Content, Footer, Header } = Layout;

const LayoutsInquiry = ({ children }) => (
  <Layout id="inquiry" style={{ minHeight: '100vh' }}>
    <Header>
      <Row align="middle" justify="center">
        <SvgLogo />
      </Row>
    </Header>
    <Content>
      <div className="inquiry-container">{children}</div>
    </Content>
    <Footer>Copyright &copy; iLusso. All Rights Reserved.</Footer>
  </Layout>
);

export default LayoutsInquiry;
