import React, { useState } from "react";
import _ from "lodash";

import { Drawer, message, Switch, Tree } from "antd";
import {
  CloseOutlined,
  CaretRightOutlined,
  CheckOutlined,
} from "@ant-design/icons";

import api from "../../../config/api";
import AuthContext from "../../../store/authContext";
import { useContext } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import RefreshContext from "../../../store/refreshContext";

const EditRole = React.memo(({ roleId, onClose }) => {
  const [showLine, setShowLine] = useState({ showLeafIcon: false });
  const [treeData, setModuleData] = useState([]);
  const [checkedModule, checkModuleData] = useState([]);
  const [selectAll, selectAllModule] = useState(false);
  const [status, changeStatus] = useState(true);
  const [SelectedModule, setSelectedModule] = useState([]);

  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);

  const [isLoading, setLoading] = useState(true);
  let tempSelectedModule = [];
  let treeArray = [];

  useEffect(() => {
    if (roleId != null) {
      api
        .get(`role/get-role/${roleId}`, {
          headers: { Authorization: `Bearer ${authCtx.userData.token}` },
        })
        .then((response) => {
          changeStatus(response.data.data.status);
          selectAllModule(response.data.data.allow_everything);
          setValue("role_name", response.data.data?.role_name);
          tempSelectedModule = response.data.data.permission;
          api
            .post(
              `modules`,
              {},
              {
                headers: { Authorization: `Bearer ${authCtx.userData.token}` },
              }
            )
            .then((response) => {
              setModuleData(response.data.data);
              treeArray = response.data.data;
              setSelectedPermission();
            })
            .catch((error) => {
              if (error.response.status == 401) {
                authCtx.logout();
              }
            });
        })
        .catch((error) => {
          if (error.response.status == 401) {
            authCtx.logout();
          }
        });
    }
  }, [roleId]);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  function setSelectedPermission() {
    const dataArray = [];
    const arrayTemp = [];
    tempSelectedModule.forEach((item) => {
      _.find(treeArray, (temp) => {
        if (temp.id == item.module_id) {
          _.find(temp.children, (tempClone) => {
            if (item.permission_id == tempClone.id) {
              dataArray.push(`${item.module_id}-${item.permission_id}`);
              arrayTemp.push(tempClone.key);
            }
          });
        }
      });
    });
    setSelectedModule(arrayTemp);
    checkModuleData(dataArray);
  }

  const onCheck = (checkedKeys, info) => {
    setSelectedModule(checkedKeys);
    const dataArray = [];
    const checkNodeAarray = info.checkedNodes;
    checkNodeAarray.forEach((item) => {
      if (item.module_id) {
        dataArray.push(`${item.module_id}-${item.id}`);
      }
    });
    checkModuleData(dataArray);
  };

  function onAllowAll(checked) {
    selectAllModule(checked);
  }

  function statusToggle(checked) {
    changeStatus(checked);
  }

  const onSubmit = async (formValues) => {
    const allowEverything = selectAll ? 1 : 0;
    const statusData = status ? 1 : 0;
    const formData = new FormData();
    formData.append("role_name", formValues.role_name);
    formData.append("company_id", authCtx?.userData?.company_id);
    formData.append("status", statusData);
    formData.append("allow_everything", allowEverything);
    checkedModule.forEach((item) => {
      formData.append("permission[]", item);
    });

    await api
      .post(`role/update-role/${roleId}`, formData, {
        headers: { Authorization: `Bearer ${authCtx.userData.token}` },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          refreshCtx.onRefresh();
          onClose();
          reset();
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  return (
    <>
      <Drawer
        className="add-role"
        destroyOnClose
        mask={false}
        onClose={onClose}
        placement="right"
        title="Edit Role"
        visible={!!roleId}
        width={500}
      >
        <form id="add-role-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="formcol">
            <label className="input-label">Role Name</label>
            <input
              className={`input-text ${errors.role_name ? "is-invalid" : ""}`}
              placeholder="Role Name"
              {...register("role_name", {
                required: true,
              })}
            />
            {errors.role_name?.type === "required" && (
              <span className="formError">This field is required</span>
            )}
          </div>
          <div className="d-flex align-items-center mb-4">
            <span className="roleStatusLabel">Status</span>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={statusToggle}
              defaultChecked
            />
          </div>
          <div className="d-flex justify-content-between">
            <span className="rolePermissionsLabel">Permissions</span>
            <div className="d-flex align-items-center">
              <span className="roleStatusLabel">Allow All</span>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={onAllowAll}
                checked={selectAll}
              />
            </div>
          </div>
          <Tree
            showLine={showLine}
            switcherIcon={<CaretRightOutlined />}
            checkable={!selectAll}
            onCheck={onCheck}
            treeData={treeData}
            checkedKeys={SelectedModule}
          />
          <div className="formbtn">
            <button type="submit" className="input-btn">
              Update Role
            </button>
          </div>
        </form>
      </Drawer>
    </>
  );
});

export default EditRole;
