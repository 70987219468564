import React from 'react';

const SvgClose = React.memo(props => (
  <svg width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.17581 6.23011L11.5743 10.6326C11.8996 10.9582 11.8994 11.4858 11.5738 11.8111C11.2482 12.1364 10.7206 12.1362 10.3953 11.8106L5.99642 7.40774L1.58878 11.8088C1.2631 12.134 0.735461 12.1336 0.410266 11.808C0.0850709 11.4823 0.0854631 10.9546 0.411142 10.6295L4.81844 6.2287L0.413513 1.81978C0.0882237 1.4942 0.0884624 0.966562 0.414046 0.641272C0.73963 0.315982 1.26727 0.316221 1.59256 0.641805L5.99783 5.05106L10.3949 0.660519C10.7206 0.335324 11.2482 0.335716 11.5734 0.661395C11.8986 0.987074 11.8982 1.51471 11.5725 1.83991L7.17581 6.23011Z"
      fill="currentColor"
    />
  </svg>
));

export default SvgClose;
