import React from "react";

const SvgCompany = () => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M14.68,10.21h4.37a.91.91,0,0,1,1,1V20a.9.9,0,0,1-1,1H4.94a.9.9,0,0,1-1-1V4.05a.9.9,0,0,1,1-1H13.7a.89.89,0,0,1,1,1v6.16ZM5.76,4.85v14.3H8.43v-.24c0-.49,0-1,0-1.47a1.23,1.23,0,0,1,0-.33.9.9,0,0,1,1-.63.87.87,0,0,1,.75.85c0,.54,0,1.07,0,1.6,0,.07,0,.14,0,.21h2.66V4.85ZM14.7,12v7.13h3.54V12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M9.31,12H8.43a.9.9,0,0,1-.9-.87.87.87,0,0,1,.8-.91c.66,0,1.32,0,2,0a.87.87,0,0,1,.81.91.9.9,0,0,1-.9.87Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M9.31,8.43H8.45a.9.9,0,1,1,0-1.79h1.7a.9.9,0,1,1,0,1.79Z"
    />
  </svg>
);

export default SvgCompany;
