import React, { useState } from "react";
import _ from "lodash";

import { Button, Drawer, message, Switch, Tree } from "antd";
import {
  CloseOutlined,
  CaretRightOutlined,
  CheckOutlined,
} from "@ant-design/icons";

import SvgPlus from "../../../images/plus";
import api from "../../../config/api";
import AuthContext from "../../../store/authContext";
import { useContext } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import RefreshContext from "../../../store/refreshContext";

const AddRole = React.memo(() => {
  const [visible, setVisible] = useState(false);
  const [showLine, setShowLine] = useState({ showLeafIcon: false });
  const [companyId, setCompanyId] = useState(null);
  const [treeData, setModuleData] = useState([]);
  const [checkedModule, checkModuleData] = useState([]);
  const [selectAll, selectAllModule] = useState(false);
  const [status, changeStatus] = useState(true);

  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);

  const showDrawer = () => {
    setVisible(true);
  };
  const onClose = () => {
    setVisible(false);
  };

  const [isLoading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onCheck = (checkedKeys, info) => {
    const dataArray = [];
    const checkNodeAarray = info.checkedNodes;
    checkNodeAarray.forEach((item) => {
      if (item.module_id) {
        dataArray.push(`${item.module_id}-${item.id}`);
      }
    });
    checkModuleData(dataArray);
  };

  useEffect(() => {
    getModules();
  }, []);

  const getModules = async () => {
    await api
      .post(
        `modules`,
        {},
        {
          headers: {
            Authorization: `Bearer ${authCtx.userData.token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          setModuleData(response.data.data);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  function onAllowAll(checked) {
    selectAllModule(checked);
  }

  function statusToggle(checked) {
    changeStatus(checked);
  }

  const onSubmit = async (formValues) => {
    const allowEverything = selectAll ? 1 : 0;
    const statusData = status ? 1 : 0;
    const formData = new FormData();
    formData.append("role_name", formValues.role_name);
    formData.append("company_id", authCtx?.userData?.company_id);
    formData.append("status", statusData);
    formData.append("allow_everything", allowEverything);
    checkedModule.forEach((item) => {
      formData.append("permission[]", item);
    });

    await api
      .post(`role/add-role`, formData, {
        headers: { Authorization: `Bearer ${authCtx.userData.token}` },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          refreshCtx.onRefresh();
          onClose();
          reset();
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  return (
    <>
      <Button
        block
        className="button-primary button-large-text"
        htmlType="button"
        type="primary"
        onClick={showDrawer}
      >
        <SvgPlus /> Add Role
      </Button>
      <Drawer
        className="add-role"
        destroyOnClose
        mask={false}
        onClose={onClose}
        placement="right"
        title="Add Role"
        visible={visible}
        width={500}
      >
        <form id="add-role-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="formcol">
            <label className="input-label">Role Name</label>
            <input
              className={`input-text ${errors.role_name ? "is-invalid" : ""}`}
              placeholder="Role Name"
              {...register("role_name", {
                required: true,
              })}
            />
            {errors.role_name?.type === "required" && (
              <span className="formError">This field is required</span>
            )}
          </div>
          <div className="d-flex align-items-center mb-4">
            <span className="roleStatusLabel">Status</span>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={statusToggle}
              defaultChecked
            />
          </div>
          <div className="d-flex justify-content-between">
            <span className="rolePermissionsLabel">Permissions</span>
            <div className="d-flex align-items-center">
              <span className="roleStatusLabel">Allow All</span>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={onAllowAll}
              />
            </div>
          </div>
          <Tree
            showLine={showLine}
            switcherIcon={<CaretRightOutlined />}
            checkable={!selectAll}
            onCheck={onCheck}
            treeData={treeData}
          />
          <div className="formbtn">
            <button type="submit" className="input-btn">
              Add Role
            </button>
          </div>
        </form>
      </Drawer>
    </>
  );
});

export default AddRole;
