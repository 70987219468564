import React, { useContext, useEffect, useState } from "react";
import {
  CTable,
  CTableHead,
  CTableHeaderCell,
  CTableRow,
  CTableDataCell,
  CTableBody,
  CRow,
  CCol,
} from "@coreui/react";
import moment from "moment";
import CommonTableWrapper from "../Layout/Wrapper";
import AuthContext from "../../store/authContext";
import { Spin } from "antd";
import ReactPaginate from "react-paginate";
import api from "../../config/api";
import { Link } from "react-router-dom";

const DashboardTopBuyers = React.memo(() => {
  const [topBuyers, setTopBuyers] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [sortField, setSortField] = useState("");
  const [sortType, setSortType] = useState("");
  const [perPage, setPerPage] = useState("10");
  const [pagination, setPagination] = useState({
    total_records: 0,
    total_pages: 1,
    current: 1,
  });

  useEffect(() => {
    fetchTopBuyers({ per_page: perPage }, 1);
  }, [perPage]);

  const authCtx = useContext(AuthContext);
  async function fetchTopBuyers(formData, page) {
    await api
      .post(`dashboard/top-performing-buyers?page=${page}`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        setTopBuyers([]);
        if (response.data.status) {
          setTopBuyers(response.data.data.topPerformingBuyers);
          setPagination(response.data.data.paginate);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  }

  function sort(field, currentField, sort, page) {
    if (field == currentField) {
      if (sort == "desc") {
        setSortType("asc");
        fetchTopBuyers(
          {
            field_name: field,
            order_by: "asc",
            per_page: perPage,
          },
          page
        );
      } else {
        setSortType("desc");
        fetchTopBuyers(
          {
            field_name: field,
            order_by: "desc",
            per_page: perPage,
          },
          page
        );
      }
    } else {
      setSortField(field);
      setSortType("desc");
      fetchTopBuyers(
        {
          field_name: field,
          order_by: "desc",
          per_page: perPage,
        },
        page
      );
    }
  }

  const topPerformingBuyersOnChange = (e) => {
    fetchTopBuyers(
      { duration_top_per_buyer: e.target.value, per_page: perPage },
      1
    );
  };

  const perPageRecordHandler = (e) => {
    setPerPage(e.target.value);
  };

  const onPaginationFetch = (e) => {
    fetchTopBuyers(
      { field_name: sortField, order_by: sortType, per_page: perPage },
      e.selected + 1
    );
  };

  const getList = (data) => {
    let content = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      content.push(
        <CTableRow key={item.id}>
          <CTableDataCell className="ant-table-cell">
            <Link to={`/buyers/detail/${item.id}`}>{item.name}</Link>
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.email}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.referral_id}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.totalFormSent}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {item.totalCompleted}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            ${item.commission}
          </CTableDataCell>
          <CTableDataCell className="ant-table-cell">
            {moment(item.created_at).utc().format("M/D/YY")}
          </CTableDataCell>
        </CTableRow>
      );
    }
    return content;
  };

  return (
    <div>
      <CRow className="justify-content-between align-items-center my-3">
        <CCol>
          <h5 className="mb-0">Top Performing Buyers</h5>
        </CCol>
        <CCol xs="auto">
          <select
            className="input-select"
            onChange={topPerformingBuyersOnChange}
          >
            <option value="7">Last 7 Days</option>
            <option value="30">Last 30 Days</option>
            <option value="75">Last 75 Days</option>
            <option value="365">Last 1 Year</option>
          </select>
        </CCol>
      </CRow>
      <CommonTableWrapper className="mt-0">
        <div className="ant-table">
          <CTable className="table-layout">
            <CTableHead className="ant-table-thead">
              <CTableRow>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Buyer
                  <span
                    onClick={(e) =>
                      sort("name", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "name" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Email
                  <span
                    onClick={(e) =>
                      sort("email", sortField, sortType, pagination.current)
                    }
                    className={`sortIcon ${
                      sortField == "email" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Referral Id
                  <span
                    onClick={(e) =>
                      sort(
                        "referral_id",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "referral_id" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Total Form Sent
                  <span
                    onClick={(e) =>
                      sort(
                        "totalFormSent",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "totalFormSent" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Total Completed{" "}
                  <span
                    onClick={(e) =>
                      sort(
                        "totalCompleted",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "totalCompleted" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Commission
                  <span
                    onClick={(e) =>
                      sort(
                        "commission",
                        sortField,
                        sortType,
                        pagination.current
                      )
                    }
                    className={`sortIcon ${
                      sortField == "commission" ? sortType : ""
                    }`}
                  ></span>
                </CTableHeaderCell>
                <CTableHeaderCell className="ant-table-cell" scope="col">
                  Date
                </CTableHeaderCell>
              </CTableRow>
            </CTableHead>
            <CTableBody className="ant-table-tbody">
              {isLoading && (
                <>
                  <CTableRow className="text-center">
                    <CTableDataCell className="ant-table-cell" colSpan={7}>
                      {<Spin />}
                    </CTableDataCell>
                  </CTableRow>
                </>
              )}
              {!isLoading && topBuyers.length == 0 && (
                <CTableRow className="text-center">
                  <CTableDataCell className="ant-table-cell" colSpan={7}>
                    No Data Found.
                  </CTableDataCell>
                </CTableRow>
              )}
              {getList(topBuyers)}
            </CTableBody>
          </CTable>
        </div>
        {pagination.total_records != undefined && (
          <>
            <ul className="ant-pagination ant-table-pagination ant-table-pagination-right">
              <li className="ant-pagination-total-text">
                {pagination.total_records} items
              </li>
              <ReactPaginate
                breakLabel="..."
                nextLabel=">"
                onPageChange={onPaginationFetch}
                previousClassName="ant-pagination-prev"
                nextClassName="ant-pagination-next"
                pageRangeDisplayed={10}
                pageCount={pagination.total_pages}
                pageClassName="ant-pagination-item"
                previousLabel="<"
                activeClassName="ant-pagination-item-active"
                disabledClassName="ant-pagination-item ant-pagination-disabled"
                renderOnZeroPageCount={null}
              />
              <select className="input-select" onChange={perPageRecordHandler}>
                <option value="10">10 / Page</option>
                <option value="20">20 / Page</option>
                <option value="50">50 / Page</option>
                <option value="100">100 / Page</option>
              </select>
            </ul>
          </>
        )}
      </CommonTableWrapper>
    </div>
  );
});

export default DashboardTopBuyers;
