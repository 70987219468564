import React, { useContext, useEffect, useState } from "react";

import { Drawer, message, Spin } from "antd";
import { useForm } from "react-hook-form";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";
import RefreshContext from "../../../store/refreshContext";
import CommonDrawerSection from "../../Common/Drawer/Section";

const EditCompany = React.memo(({ companyId, onClose }) => {
  const [isLoading, setLoading] = useState(true);
  const [uploadedFile, setUploadedFile] = useState({});
  const [companyDetail, setCompanyDetail] = useState({});

  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (companyId != undefined) {
      api
        .get(`company/get-company/${companyId}`, {
          headers: {
            Authorization: `Bearer ${authCtx?.userData?.token}`,
          },
        })
        .then((response) => {
          setLoading(false);
          setCompanyDetail({});
          if (response.data.status) {
            setCompanyDetail(response.data.data);
            setValue("title", response.data.data?.title);
            setValue("domain", response.data.data?.domain);
            setValue("status", response.data.data?.status);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [companyId]);

  const onSubmit = async (formValues) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("title", formValues.title);
    formData.append("domain", formValues.domain);
    formData.append("status", formValues.status);
    formData.append("logo", uploadedFile?.file);

    await api
      .post(`company/update-company/${companyId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          reset();
          onClose();
          refreshCtx.onRefresh();
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const onFileUploadHandler = (event) => {
    setUploadedFile({
      image: URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    });
  };

  return (
    <Drawer
      className="company-detail"
      destroyOnClose
      mask={false}
      onClose={onClose}
      placement="right"
      title="Company Details"
      width={500}
      visible={!!companyId}
    >
      <CommonDrawerSection>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formcol">
            <label className="input-label">Title</label>
            <input
              className={`input-text ${errors.title ? "is-invalid" : ""}`}
              placeholder="Title"
              {...register("title", {
                required: true,
              })}
            />
            {errors.title?.type === "required" && (
              <span className="formError">This field is required</span>
            )}
          </div>
          <div className="formcol">
            <label className="input-label">Domain</label>
            <input
              className={`input-text ${errors.domain ? "is-invalid" : ""}`}
              placeholder="Domain"
              {...register("domain", {
                required: true,
              })}
            />
            {errors.domain?.type === "required" && (
              <span className="formError">This field is required</span>
            )}
          </div>
          <div className="formcol">
            <label className="input-label">Status</label>
            <select
              className={`input-select ${errors.status ? "is-invalid" : ""}`}
              {...register("status", {
                required: true,
              })}
            >
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
            {errors.status?.type === "required" && (
              <span className="formError">This field is required</span>
            )}
          </div>
          <div className="formcol">
            <label className="input-label">Logo</label>
            <input
              className={`input-text ${errors.logo ? "is-invalid" : ""}`}
              placeholder="Logo"
              type="file"
              {...register("logo")}
              onChange={(e) => onFileUploadHandler(e)}
            />
            {uploadedFile?.image != undefined && (
              <img
                src={uploadedFile?.image}
                alt=""
                className="img-fluid"
                style={{ maxHeight: "200px", objectFit: "cover" }}
              />
            )}
            {companyDetail?.logo != undefined && (
              <img
                src={companyDetail?.logo}
                alt=""
                className="img-fluid"
                style={{ maxHeight: "200px", objectFit: "cover" }}
              />
            )}
          </div>
          <div className="formbtn">
            <button type="submit" className="input-btn">
              Update Company
            </button>
          </div>
        </form>
      </CommonDrawerSection>
    </Drawer>
  );
});

export default EditCompany;
