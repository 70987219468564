import React from 'react';

import './menu.scss';

const SvgMenu = ({ isOpen }) => (
  <div id="svg-menu" className={isOpen ? 'open' : ''}>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
);

export default SvgMenu;
