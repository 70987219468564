import React from "react";
import _ from "lodash";

const CommonReferralUser = React.memo(({ buyerData }) => (
  <div className="commonDrawerGroup">
    <div className="common-drawer-info-value">
      <a
        href={`/buyers/detail/${
          buyerData?.user_id != undefined ? buyerData?.user_id : buyerData?.id
        }`}
      >
        {buyerData?.userName != undefined
          ? buyerData?.userName
          : buyerData?.name}
      </a>
      <span className="ms-2">Thank You</span>
    </div>
  </div>
));
export default CommonReferralUser;
