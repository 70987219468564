import React, { useContext, useEffect, useState } from "react";

import { Drawer, message } from "antd";
import { useForm } from "react-hook-form";
import AuthContext from "../../../store/authContext";
import api from "../../../config/api";
import CommonDrawerSection from "../../Common/Drawer/Section";
import RefreshContext from "../../../store/refreshContext";

const LeadNote = React.memo(({ leadData, onClose }) => {
  const [isLoading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("note", leadData?.note);
  }, [leadData]);

  const onSubmit = async (formValues) => {
    setLoading(true);
    formValues.lead_id = leadData?.id;
    await api
      .post(`leads/addnote`, formValues, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          onClose();
          refreshCtx.onRefresh();
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  return (
    <Drawer
      className="lead-note"
      destroyOnClose
      mask={false}
      onClose={onClose}
      placement="right"
      title="Lead Note"
      width={500}
      visible={!!leadData?.id}>
      <CommonDrawerSection>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="formcol">
            <textarea
              rows={3}
              className="input-text"
              placeholder="Note"
              {...register("note")}></textarea>
          </div>
          <div className="formbtn">
            <button type="submit" className="input-btn" disabled={isLoading}>
              Save
            </button>
          </div>
        </form>
      </CommonDrawerSection>
    </Drawer>
  );
});

export default LeadNote;
