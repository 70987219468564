import React, { useContext, useState } from "react";

import { Col, message, Row } from "antd";
import CommonFormSplash from "../Common/Form/Splash";
import LayoutsLoggedIn from "../Layout/LoggedIn";
import { useForm } from "react-hook-form";
import _ from "lodash";
import AuthContext from "../../store/authContext";
import api from "../../config/api";

const Profile = () => {
  const states = _.map(
    {
      AL: "Alabama",
      AK: "Alaska",
      AS: "American Samoa",
      AZ: "Arizona",
      AR: "Arkansas",
      CA: "California",
      CO: "Colorado",
      CT: "Connecticut",
      DE: "Delaware",
      DC: "District Of Columbia",
      FL: "Florida",
      GA: "Georgia",
      GU: "Guam",
      HI: "Hawaii",
      ID: "Idaho",
      IL: "Illinois",
      IN: "Indiana",
      IA: "Iowa",
      KS: "Kansas",
      KY: "Kentucky",
      LA: "Louisiana",
      ME: "Maine",
      MH: "Marshall Islands",
      MD: "Maryland",
      MA: "Massachusetts",
      MI: "Michigan",
      MN: "Minnesota",
      MS: "Mississippi",
      MO: "Missouri",
      MT: "Montana",
      NE: "Nebraska",
      NV: "Nevada",
      NH: "New Hampshire",
      NJ: "New Jersey",
      NM: "New Mexico",
      NY: "New York",
      NC: "North Carolina",
      ND: "North Dakota",
      OH: "Ohio",
      OK: "Oklahoma",
      OR: "Oregon",
      PW: "Palau",
      PA: "Pennsylvania",
      PR: "Puerto Rico",
      RI: "Rhode Island",
      SC: "South Carolina",
      SD: "South Dakota",
      TN: "Tennessee",
      TX: "Texas",
      UT: "Utah",
      VT: "Vermont",
      VI: "Virgin Islands",
      VA: "Virginia",
      WA: "Washington",
      WV: "West Virginia",
      WI: "Wisconsin",
      WY: "Wyoming",
    },
    (label, value) => ({ label, value })
  );

  const [loading, setLoading] = useState(false);
  const authCtx = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (formData) => {
    setLoading(true);
    api
      .post(`users/update-profile`, formData, {
        headers: {
          Authorization: `Bearer ${authCtx.userData.token}`,
        },
      })
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          const token = {
            ...authCtx.userData,
            ...response.data.data,
          };
          authCtx.login(token);
          message.success("Your profile was updated!");
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          "Sorry we are not able to update your profile at this time."
        );
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  return (
    <LayoutsLoggedIn>
      <Row justify="center">
        <Col flex="400px">
          <CommonFormSplash>
            <h1 className="update-profile-title">Update Profile</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="input-col">
                <label className="input-label">Name</label>
                <input
                  className="input-text"
                  placeholder="Please enter your name"
                  name="name"
                  defaultValue={authCtx.userData?.name}
                  {...register("name", {
                    required: true,
                  })}
                />
                {errors.name?.type === "required" && (
                  <span className="formError">Name is required.</span>
                )}
              </div>
              <div className="input-col">
                <label className="input-label">Phone Number</label>
                <input
                  className="input-text"
                  placeholder="Please enter your phone number"
                  name="mobile_no"
                  defaultValue={authCtx.userData?.mobile_no}
                  {...register("mobile_no", {
                    required: true,
                  })}
                />
                {errors.mobile_no?.type === "required" && (
                  <span className="formError">Mobile Number is required.</span>
                )}
              </div>
              <div className="input-col">
                <label className="input-label">Address</label>
                <input
                  className="input-text"
                  placeholder="Please enter your primary address"
                  name="address"
                  defaultValue={authCtx.userData?.address}
                  {...register("address")}
                />
              </div>
              <div className="input-col">
                <label className="input-label">Address 2</label>
                <input
                  className="input-text"
                  placeholder="Please enter your Address 2"
                  name="address2"
                  defaultValue={authCtx.userData?.address2}
                  {...register("address2")}
                />
              </div>
              <div className="input-col">
                <label className="input-label">City</label>
                <input
                  className="input-text"
                  placeholder="Please enter your city"
                  name="city"
                  defaultValue={authCtx.userData?.city}
                  {...register("city")}
                />
              </div>
              <div className="input-col">
                <label className="input-label">State</label>
                <select
                  className="input-select"
                  {...register("state")}
                  defaultValue={authCtx.userData?.state}
                >
                  <option disabled>Please enter your state</option>
                  {_.map(states, (state) => (
                    <option key={state.value} value={state.label}>
                      {state.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-col">
                <label className="input-label">Zip</label>
                <input
                  className="input-text"
                  placeholder="Please enter your zip"
                  defaultValue={authCtx.userData?.zip_code}
                  {...register("zip_code")}
                />
              </div>
              <div>
                <button
                  className="input-btn"
                  htmltype="submit"
                  disabled={loading}
                >
                  Update Profile
                </button>
              </div>
            </form>
          </CommonFormSplash>
        </Col>
      </Row>
    </LayoutsLoggedIn>
  );
};

export default Profile;
