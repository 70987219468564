import React, { useContext, useState } from "react";
import _ from "lodash";

import { Button, Drawer, message } from "antd";
import SvgPlus from "../../../images/plus";
import CommonDrawerSection from "../../Common/Drawer/Section";
import RefreshContext from "../../../store/refreshContext";
import { useForm } from "react-hook-form";
import api from "../../../config/api";
import AuthContext from "../../../store/authContext";

const AddTemplate = React.memo(() => {
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const [isLoading, setLoading] = useState(true);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const authCtx = useContext(AuthContext);
  const refreshCtx = useContext(RefreshContext);
  const onSubmit = async (formValues) => {
    setLoading(true);
    await api
      .post(
        `smstemplate/add-smstemplate`,
        { ...formValues, user_id: authCtx.userData?.id },
        {
          headers: { Authorization: `Bearer ${authCtx.userData?.token}` },
        }
      )
      .then((response) => {
        setLoading(false);
        if (response.data.status) {
          reset();
          onClose();
          refreshCtx.onRefresh();
          message.success(response.data.message);
        } else {
          message.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          authCtx.logout();
        }
      });
  };

  const onClose = () => {
    setVisible(false);
    reset();
  };

  return (
    <>
      <Button
        block
        className="button-primary button-large-text"
        htmlType="button"
        type="primary"
        onClick={showDrawer}
      >
        <SvgPlus /> Add Template
      </Button>
      <Drawer
        className="add-company"
        destroyOnClose
        mask={false}
        onClose={onClose}
        placement="right"
        title="Add Template"
        visible={visible}
        width={500}
      >
        <CommonDrawerSection>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formcol">
              <label className="input-label">Name</label>
              <input
                className={`input-text ${errors.title ? "is-invalid" : ""}`}
                placeholder="Name"
                {...register("name", {
                  required: true,
                })}
              />
              {errors.name?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">Status</label>
              <select
                className={`input-select ${errors.status ? "is-invalid" : ""}`}
                {...register("status", {
                  required: true,
                })}
              >
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
              {errors.status?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formcol">
              <label className="input-label">Content</label>
              <textarea
                className="input-text"
                placeholder="Enter content here"
                {...register("content", { required: true })}
              ></textarea>
              {errors.content?.type === "required" && (
                <span className="formError">This field is required</span>
              )}
            </div>
            <div className="formbtn">
              <button type="submit" className="input-btn">
                Add Template
              </button>
            </div>
          </form>
        </CommonDrawerSection>
      </Drawer>
    </>
  );
});

export default AddTemplate;
